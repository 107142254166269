import { decodeTobitAccessToken } from './convert';
import { setCookie } from './helper';

const DAY = (1000 * 60 * 60 * 24);

export const AT_COOKIE_NAME = 'chayns_at_378';
export const RT_COOKIE_NAME = 'chayns_rt_378';
export const GUARDIAN_COOKIE_NAME = 'chayns_guarded_at';

let atRenewInterval = null;
let rtRenewInterval = null;

export const getToken = (cookieName: string): { token: string, exp: number } => {
    const cookieData = document.cookie.split(';');
    const token = cookieData.find((d) => d.indexOf(`${cookieName}=`) > -1);
    if (token) {
        const tokenData = token.replace(`${cookieName}=`, '').trim();
        const data = decodeTobitAccessToken(tokenData);
        if (data) {
            return {
                token: tokenData,
                exp: data.exp,
            };
        }
    }
    return {
        token: null,
        exp: null,
    };
};

export const invalidateToken = async (token: string): Promise<boolean> => {
    if (token) {
        try {
            const res = await fetch('https://auth.chayns.net/v2/invalidToken', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token,
                }),
            });
            if (res.status === 401 && (await res.json()).message === 'Invalid token.') {
                return true;
            }
            return !!(res && res.ok);
        } catch (error) {
            return false;
        }
    }
    return true;
};

export const renewRT = async (): Promise<string> => {
    const { token } = getToken(RT_COOKIE_NAME);
    const accessToken = getToken(AT_COOKIE_NAME);

    if (!token && accessToken) {
        return null;
    } if (!token) {
        if (!accessToken) chayns.logout();
        return null;
    }
    const response = await fetch('https://auth.tobit.com/v2/token/renew', {
        headers: {
            Authorization: `bearer ${token}`,
        },
    });
    if (response.status === 200) {
        const json = await response.json();
        if (json.token) {
            setCookie(RT_COOKIE_NAME, json.token, new Date(json.expires).getTime() * 1000);
            return json.token;
        }
    } else {
        setCookie(RT_COOKIE_NAME, '', -1);
        chayns.logout();
    }
    await invalidateToken(token);
    return null;
};

export const renewAT = async (): Promise<string> => {
    const { token } = getToken(RT_COOKIE_NAME);
    const { token: oldToken } = getToken(AT_COOKIE_NAME);
    const { token: guardedToken } = getToken(GUARDIAN_COOKIE_NAME);

    const response = await fetch('https://auth.tobit.com/v2/token', {
        method: 'POST',
        headers: {
            authorization: `bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
    if (response.status === 200) {
        await invalidateToken(oldToken);
        const res = await response.json();
        const newToken = res.token;
        const { exp } = decodeTobitAccessToken(newToken);
        if (newToken) {
            setCookie(AT_COOKIE_NAME, newToken, new Date(exp).getTime());
            if (guardedToken) {
                const { PersonID: personId } = decodeTobitAccessToken(guardedToken);
                const res2 = await fetch('https://auth.tobit.com/v2/token/switchUser', {
                    method: 'POST',
                    headers: {
                        authorization: `bearer ${newToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ personId }),
                });
                if (res2.status === 200) {
                    const data = await res2.json();
                    const { exp: exp2 } = decodeTobitAccessToken(data.token);
                    setCookie('chayns_guarded_at', data.token, new Date(exp2).getTime());
                }
            }
            if (typeof window !== 'undefined') {
                window.refreshTobitAt(!!guardedToken);
            }
        }
        return newToken;
    }
    return null;
};

const renewAtHandler = async () => {
    const { exp: tokenExpTime } = getToken(AT_COOKIE_NAME);
    if (tokenExpTime) {
        const timeTillExpiration = new Date(tokenExpTime).getTime() - Date.now();
        const { token } = getToken(RT_COOKIE_NAME);
        if (timeTillExpiration - DAY <= 0 && token) {
            await renewAT();
        } else if (timeTillExpiration <= 15 * 60 * 1000 && !token) {
            setTimeout(() => {
                chayns.logout();
            }, timeTillExpiration - 60 * 1000);
        }
    }
};

const setRenewAT = () => {
    renewAtHandler();
    // Set interval to renewToken 24hours before it expires
    atRenewInterval = setInterval(async () => {
        renewAtHandler();
    }, 15 * 1000 * 60);
};

const renewRtHandler = async () => {
    const { exp: tokenExpTime, token } = getToken(RT_COOKIE_NAME);
    if (token) {
        const timeTillExpiration = tokenExpTime * 1000 - Date.now();
        if (timeTillExpiration - (DAY * 3) <= 0) {
            await renewRT();
            const { exp } = getToken(RT_COOKIE_NAME);
            const newTokenExp = new Date(exp).getTime() * 1000;
            if (newTokenExp - Date.now() < DAY) {
                const accessToken = getToken(AT_COOKIE_NAME);
                if (!accessToken) chayns.logout();
            }
        }
    }
};

const setRenewRT = () => {
    renewRtHandler();
    // Set interval to renew rt 72 hours before it expires
    rtRenewInterval = setInterval(async () => {
        renewRtHandler();
    }, 15 * 1000 * 60);
};

export const renewAccessToken = (): void => {
    clearInterval(atRenewInterval);
    clearInterval(rtRenewInterval);

    setRenewAT();
    setRenewRT();
};
