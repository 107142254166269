import React, { FC, useEffect } from 'react';
import { SmallWaitCursor } from '@chayns-components/core';

type SmartClientLoadingProps = {
    setIsLoading?: (value: boolean) => void;
};

const LoadingComponent: FC<SmartClientLoadingProps> = ({ setIsLoading }) => {
    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(true);

            return () => {
                setIsLoading(false);
            };
        }

        return undefined;
    }, [setIsLoading]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
            <SmallWaitCursor/>
        </div>
    );
};

export default LoadingComponent;
