import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isSTAGING } from '../../constants/environments';
import TAPPIDS from '../../constants/tapp-ids';
import { getAppBottomBarHeight, getAppState } from '../../redux-modules/app/selector';
import { getEnvState } from '../../redux-modules/env/selector';
import {BAR_HEIGHT, MOBILE_VIEW_BREAKPOINT} from "../../constants/size";

const VersionHint: React.FC = () => {
    const app = useSelector(getAppState);
    const {
        activeTapp,
        showTransitioningOverlay,
        width,
    } = app;

    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
        isDavidClient,
    } = env;

    const appBottomBarHeight = useSelector(getAppBottomBarHeight);

    const setCookie = useCallback(() => {
        void chayns.invokeCall({
            action: 292,
            value: {
                show: true,
            },
        });
        chayns.selectTapp({ id: TAPPIDS.CHAYNS_ID, params: 'accordion=betamode' });
    }, []);

    const bottom = useMemo(() => {
        if (isDavidClient || isMyChaynsApp) {
            return 10 + appBottomBarHeight;
        }
        if (width < MOBILE_VIEW_BREAKPOINT) {
            return 75;
        }
        return 10;
    }, [isDavidClient, isMyChaynsApp, width, appBottomBarHeight]);

    if (isSTAGING && activeTapp !== TAPPIDS.CALENDAR && activeTapp !== TAPPIDS.SMART_CLIENT && activeTapp !== TAPPIDS.LANDING_PAGE && !showTransitioningOverlay) {
        return (
            <>
                <div
                    onClick={setCookie}
                    className="version-hint"
                    style={{
                        bottom,
                    }}
                >
                    Beta
                </div>
                <style jsx>
                    {`
                        .version-hint {
                            color: #000000;
                            font-size: 14px;
                            position: fixed;
                            left: 10px;
                            padding: 3px 9px;
                            background-color: grey;
                            user-select: none;
                            border-radius: 3px;
                            z-index: 110;
                            cursor: pointer;
                            backdrop-filter: blur(10px);
                            transition: all .3s;
                        }
                    `}
                </style>
            </>
        );
    }

    return null;
};

VersionHint.displayName = 'VersionHint';

export default memo(VersionHint);
