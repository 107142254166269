import React from 'react';
import { useSelector } from 'react-redux';
import { getAppState } from '../../redux-modules/app/selector';
import { getEnvState } from '../../redux-modules/env/selector';
import TobitTeamByDavidLogo from '../util-components/TobitTeamByDavidLogo';
import { HEADER_HEIGHT } from '../../constants/size';
import ManagedAccountsWrapper from "../header-bar/managed-accounts/ManagedAccountsWrapper";

const TopBar = () => {
    const app = useSelector(getAppState);
    const env = useSelector(getEnvState);
    const {
        colorMode,
        userAgent,
    } = app;

    const {
        isElectronClient,
    } = env;

    if (isElectronClient) {
        const version = parseInt(userAgent.match(/electron-d3sc\/(\d+\.\d+.\d+)/i)?.[1]?.replaceAll('.', '') || 0);
        if (version < 260) return false;
    }

    return (
        <>
            <div className="top-bar-wrapper">
                <div className="tobit-team-logo-wrapper">
                    <TobitTeamByDavidLogo margin={8} height={40}/>
                </div>
                <div className="top-bar-wrapper__managed-accounts">
                    <ManagedAccountsWrapper/>
                </div>
            </div>

            <style jsx>
                {`
                .top-bar-wrapper {
                    height: ${HEADER_HEIGHT}px;
                    width: 100%;
                    flex-shrink: 0;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    
                    &__managed-accounts {
                        margin-right: 15px;
                        display: flex;
                        align-items: center;
                    }
                }

                .tobit-team-logo-wrapper {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    margin-left: 15px;
                    transform: scale(1.1);
                }

                .tobit-software-logo-wrapper {
                    height: 100%;
                    width: fit-content;
                    display: flex;
                    align-items: center;
                    transform: scale(.85);
                }
            `}
            </style>
        </>
    );
};

export default TopBar;
