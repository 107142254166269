import { TextStrings } from '../redux-modules/text-strings/actions';
import TAPPIDS from '../constants/tapp-ids';

const getMetaDataTitle = (tappId: number, textStrings: TextStrings): string => {
    switch (tappId) {
        case TAPPIDS.INTERCOM:
            return textStrings?.['txt_chayns_de_intercom_title'] || 'chayns Chat';
        case TAPPIDS.MONEY:
            return textStrings?.['txt_chayns_de_money_title'] || 'chayns Money';
        case TAPPIDS.WALLET:
            return textStrings?.['txt_chayns_de_wallet_title'] || 'chayns Cards';
        case TAPPIDS.CHAYNS_ID:
            return textStrings?.['txt_chayns_de_id_title'] || 'chaynsID';
        case TAPPIDS.SPACE:
            return textStrings?.['txt_chayns_de_space_title'] || 'chayns Space';
        default:
            return 'chayns';
    }
};

const getMetaDataDescription = (tappId: number, textStrings: TextStrings): string => {
    switch (tappId) {
        case TAPPIDS.INTERCOM:
            return textStrings?.['txt_chayns_de_intercom_description'] || 'Dein persönlicher Messenger.';
        case TAPPIDS.MONEY:
            return textStrings?.['txt_chayns_de_money_description'] || 'Deine Buchungen und Belege.';
        case TAPPIDS.WALLET:
            return textStrings?.['txt_chayns_de_wallet_description'] || 'Deine Tickets, Gutscheine und aktuelle Dokumente';
        case TAPPIDS.CHAYNS_ID:
            return textStrings?.['txt_chayns_de_id_description'] || 'Deine digitale Identität: Eine für alle chayns Sites.';
        case TAPPIDS.SPACE:
            return textStrings?.['txt_chayns_de_space_description'] || 'Deine persönliche, verschlüsselte Cloud.';
        default:
            return 'Entdecke die digitale Seite Deiner Nachbarschaft mit ihren fantastischen neuen Möglichkeiten. Mit chayns verbindest Du Dich mit allem und allen, was Dir wichtig ist.';
    }
};

export {
    getMetaDataTitle,
    getMetaDataDescription,
};
