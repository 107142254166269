/* eslint-disable max-len */
import React, { memo, useMemo, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import TypeIcon from './type-icon/TypeIcon';
import { getAppState } from '../../../../../../redux-modules/app/selector';

type UserImageProps = {
    endpoint?: string,
    fromId?: string,
    tokenJti?: string,
    url?: string,
    isSquare?: boolean,
    color?: string,
    type?: string,
};

const UserImage: React.FC<UserImageProps> = ({
    endpoint,
    fromId,
    tokenJti,
    url,
    isSquare,
    color,
    type,
}) => {
    const app = useSelector(getAppState);
    const {
        colorMode,
    } = app;

    const [isImageAvailable, setIsImageAvailable] = useState(true);

    const src = useMemo(() => {
        if (url) {
            return url;
        }
        if (endpoint && fromId && tokenJti) {
            return `${endpoint}v1.0/Users/${fromId}/Photo/64?jti=${tokenJti}`;
        }
        return '/unknown_user.png';
    }, [fromId, endpoint, tokenJti, url]);

    const isDarkMode = colorMode === 'dark';

    return (
        <>
            <div
                className="user-image-wrapper"
            >
                <img
                    className={classnames('user-image', {
                        'no-image': (!isImageAvailable || src === '/unknown_user.png') && !isDarkMode,
                        'is-square': isSquare,
                    })}
                    src={src}
                    style={{ backgroundColor: src === '/unknown_user.png' || isImageAvailable ? 'transparent' : `#${color}` }}
                    onError={(event) => {
                        setIsImageAvailable(false);
                        if (!isSquare) {
                            // eslint-disable-next-line no-return-assign, no-param-reassign
                            return (event.target as HTMLImageElement).src = '/unknown_user.png';
                        }
                        return null;
                    }}
                    alt=""
                />
                {type && (
                    <TypeIcon
                        type={type}
                    />
                )}
            </div>
            <style jsx>
                {`
                    .user-image-wrapper {
                        position: relative;

                        .user-image {
                            pointer-events: none;
                            display: block;
                            height: 40px;
                            min-width: 40px;
                            margin-right: 10px;
                            background-position: 50%;
                            background-size: cover;
                            border-radius: 50%;
                            box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .1);
                            object-fit: scale-down;
                            max-width: 40px;

                            &.is-square {
                                border-radius: 0;
                                outline: 0.05px solid rgba(0, 0, 0, 0.1);
                                outline-offset: -1px;
                            }

                            &.no-image {
                                background-color: white !important;
                                box-shadow: 0 0 0 1px rgba(var(--chayns-color-rgb--009), 0.08) inset;
                            }
                        }
                    }
                `}
            </style>
        </>
    );
};

UserImage.propTypes = {
    tokenJti: PropTypes.string,
    endpoint: PropTypes.string,
    fromId: PropTypes.string,
    url: PropTypes.string,
    isSquare: PropTypes.bool,
    color: PropTypes.string,
    type: PropTypes.string,
};

UserImage.defaultProps = {
    tokenJti: null,
    endpoint: null,
    fromId: null,
    url: null,
    isSquare: false,
    color: null,
    type: null,
};

UserImage.displayName = 'UserImage';

export default memo(UserImage);
