import React, { useMemo, memo, useEffect, useRef } from 'react';
import Accordion from '@chayns-components/Accordion';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import SitePlaceholder from '../../site/SitePlaceholder';
import Site from '../../site/Site';
import PageSelection from '../page-selection/PageSelection';
import { getCoords } from '../../../../../../utils/geoLocationHelper';
import { loadSearchSites } from '../../../../../../api/search/sites';
import { getAppState } from '../../../../../../redux-modules/app/selector';
import { getSitesState } from '../../../../../../redux-modules/sites/selector';
import { getEnvState } from '../../../../../../redux-modules/env/selector';
import { getIsSearchingSitesState, getSearchSitesState, getSearchStringState } from '../../../../../../redux-modules/search/selector';
import { setIsSearchingSites, setSitesActivePage, setSitesPageResult } from '../../../../../../redux-modules/search/actions';

type SiteWraperTypes = {
    elementWidth: string,
};

const SiteWrapper: React.FC<SiteWraperTypes> = ({
    elementWidth,
}) => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        isClient,
        geoPositionAvailable,
        geoCoordinates,
    } = app;

    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
    } = env;

    const allSites = useSelector(getSitesState);

    const searchString = useSelector(getSearchStringState);

    const isSearchingSites = useSelector(getIsSearchingSitesState);
    const sitesSearch = useSelector(getSearchSitesState);
    const {
        values,
        activePage,
        total,
    } = sitesSearch;

    const abortControllerRef = useRef(null);

    const elementsPerPage = Math.round(100 / parseFloat(elementWidth)) * 2;

    const sitePlaceholder = useMemo(() => {
        const tempPlaceholder = [];
        for (let i = 0; i < elementsPerPage; i += 1) {
            tempPlaceholder.push(<SitePlaceholder key={i}/>);
        }
        return tempPlaceholder;
    }, [elementsPerPage]);

    const shownValues = useMemo(() => {
        const startIndex = (activePage - 1) * elementsPerPage;
        const endIndex = startIndex + elementsPerPage;
        const sliceValues = values?.slice(startIndex, endIndex) || [];
        const shownAmount = total > elementsPerPage / 2 ? elementsPerPage : elementsPerPage / 2;
        if (sliceValues.length < shownAmount) {
            const missingAmount = shownAmount - sliceValues.length;
            for (let i = 0; i < missingAmount; i += 1) {
                if (total - endIndex < 0) {
                    sliceValues.push({
                        type: 'empty',
                        key: i,
                    });
                } else {
                    sliceValues.push({
                        type: 'placeholder',
                        key: i,
                    });
                }
            }
        }
        return sliceValues;
    }, [activePage, elementsPerPage, total, values]);

    const loadSites = async () => {
        dispatch(setIsSearchingSites(true));
        let latitude = 52.06692;
        let longitude = 7.01652;
        let geoPivot = 300000;

        const isComplexSearch = searchString.indexOf('UND') > -1 && searchString.indexOf(':') > -1;

        try {
            if (geoPositionAvailable === true || geoCoordinates || isComplexSearch) {
                if (geoCoordinates && geoCoordinates.longitude && geoCoordinates.latitude && geoCoordinates.geoPivot) {
                    latitude = geoCoordinates.latitude;
                    longitude = geoCoordinates.longitude;
                    geoPivot = geoCoordinates.geoPivot;
                } else {
                    const geoPosition = chayns.env.isApp ? await chayns.getGeoLocation() : await getCoords();
                    if (geoPosition && geoPosition.longitude && geoPosition.latitude) {
                        latitude = geoPosition.latitude;
                        longitude = geoPosition.longitude;
                        geoPivot = 30;
                    }
                }
            }
        } catch (e) {
            // ignore
        }

        abortControllerRef.current = new AbortController();
        const startIndex = (activePage - 1) * elementsPerPage;
        loadSearchSites(searchString, elementsPerPage, startIndex, {
            longitude,
            latitude,
            geoPivot,
        }, activePage, abortControllerRef.current.signal).then((value) => {
            if (value.status === 200 && value.result) {
                dispatch(setSitesPageResult({
                    value: {
                        values: value.result.values,
                        startIndex,
                    },
                }));
            } else {
                dispatch(setIsSearchingSites(false));
            }
        }).catch(() => {
            dispatch(setIsSearchingSites(false));
        });
    };

    useEffect(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        if (!values) {
            return;
        }

        if (activePage > 1 && (activePage - 1) * elementsPerPage > total) {
            dispatch(setSitesActivePage(activePage - 1));
            return;
        }

        const startIndex = (activePage - 1) * elementsPerPage;
        const endIndex = startIndex + elementsPerPage;
        let areValuesLoaded = true;
        for (let index = startIndex; index < endIndex && index < total; index += 1) {
            const element = values[index];
            if (!element) {
                areValuesLoaded = false;
            }
        }
        if (!areValuesLoaded) {
            loadSites();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePage, elementsPerPage]);

    if (!total && !isSearchingSites) {
        return null;
    }

    return (
        <>
            <Accordion
                head="Sites"
                defaultOpened
            >
                <div
                    className="site_wrapper"
                    style={{
                        gridTemplateColumns: `repeat(auto-fill, minmax(${elementWidth}, 1fr))`,
                    }}
                >
                    {(shownValues.length === 0 || isSearchingSites) && sitePlaceholder}
                    {shownValues.length > 0 && !isSearchingSites && shownValues.map((site, index) => {
                        if (site.type === 'placeholder' || site.type === 'empty') {
                            return <SitePlaceholder key={site.key} isHidden={site.type === 'empty'}/>;
                        }
                        const isKnown = !!allSites?.values?.find((currentSite) => currentSite?.siteId === site.siteId);
                        const openDialog = !isMyChaynsApp && allSites?.values?.findIndex((s) => s?.isHidden && s?.siteId === site.siteId) > -1;
                        return (
                            <Site
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${site.siteId}_${index}`}
                                name={site.siteName}
                                siteId={site.siteId}
                                color={site.locationMetadata?.color}
                                isKnown={isKnown}
                                tappId={site.tappId}
                                openDialog={openDialog}
                                domain={site.domain}
                                isSearchResult
                                isClient={isClient}
                                isMyChaynsApp={isMyChaynsApp}
                                searchString={searchString}
                            />
                        );
                    })}
                </div>

                {total && (
                    <PageSelection
                        activePage={activePage}
                        totalPages={Math.ceil(total / elementsPerPage)}
                        onPageClick={(pageIndex) => {
                            dispatch(setSitesActivePage(pageIndex));
                        }}
                    />
                )}
            </Accordion>
            <style jsx>
                {`
                    .site_wrapper {
                        flex-wrap: wrap;
                        display: grid;
                        margin: 15px 0 30px 0;
                        min-height: ${total > elementsPerPage / 2 ? '250px' : '125px'};
                    }
                `}
            </style>
        </>
    );
};

SiteWrapper.propTypes = {
    elementWidth: PropTypes.string.isRequired,
};

SiteWrapper.displayName = 'SiteWrapper';

export default memo(SiteWrapper);
