import React, { memo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getAppState } from '../../../../../redux-modules/app/selector';
import { getEnvState } from '../../../../../redux-modules/env/selector';

type SitePlaceholderTypes = {
    isHidden?: boolean,
};

const SitePlaceholder: React.FC<SitePlaceholderTypes> = ({
    isHidden,
}) => {
    const app = useSelector(getAppState);
    const {
        davidDialogTitleColor,
    } = app;

    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
    } = env;

    const squareImgWrapperClassnames = classnames('infocenter__square__img-wrapper');

    const squareImgClassnames = classnames('infocenter__square__img', 'chayns__background-color--002');

    const squareClassnames = classnames('infocenter__square', 'fadeInUp');

    return (
        <>
            <div
                className={squareClassnames}
                style={{
                    opacity: isHidden ? '0' : undefined,
                }}
            >
                <div
                    className={squareImgWrapperClassnames}
                >
                    <img
                        className={squareImgClassnames}
                        alt=""
                    />
                </div>
                <div
                    className={classnames('infocenter__square__subline', {
                        'small-font': isMyChaynsApp,
                    })}
                >
                    <p
                        className="subline-paragraph"
                    />
                </div>
            </div>
            <style jsx>
                {`
                .infocenter__square {
                    user-select: none;
                    margin-bottom: 1vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    overflow: visible;
                    -webkit-touch-callout: none;

                    &__img-wrapper {
                        height: 60px;
                        width: 60px;
                        position: relative;
                        display: inline-block;
                        transition: all .3s;
                        background-color: ${davidDialogTitleColor || 'rgba(var(--chayns-color-rgb--002), 1)'};
                        
                        &:after {
                            content: '';
                            padding-top: 100%;
                            display: block;
                        }

                        &.is_mobile:hover {
                            transform: none;
                        }
                    }

                    &__img {
                        position: absolute;
                        background-position: center;
                        background-size: cover;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        outline: 0.05px solid rgba(0, 0, 0, 0.1);
                        outline-offset: -1px;
                        opacity: 0.8;
                    }

                    &__subline {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;
                        width: 100%;
                        text-align: center;
                        padding-top: 5px;
                        height: 45px;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        line-height: 1.4;
                        width: 90%;
                      
                        &.small-font {
                            font-size: 12px;
                            height: 50px;
                        }

                        .subline-paragraph {
                            width: 100%;
                            height: 15px;
                            background-color: ${davidDialogTitleColor || 'rgba(var(--chayns-color-rgb--002), 1)'};
                        }
                    }
                }

                @keyframes progress {
                    0% {
                        transform: translate3d(-100%, 0, 0);
                    }
                    100% {
                        transform: translate3d(100%, 0, 0);
                    }
                }

                a:hover {
                    opacity: 1 !important;
                }

                @keyframes zoomIn {
                    from {
                        transform: rotate(0deg) scale(0);
                    }
                    to {
                        transform: rotate(360deg) scale(1);
                    }
                }

                @keyframes fadeIn {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                    }
                }

                @keyframes fadeInUp {
                    from {
                        opacity: 0;
                        transform: translate3d(0, 20px, 0);
                    }
                    to {
                        opacity: 1;
                        transform: translate3d(0, 0, 0);
                    }
                }
            `}
            </style>
        </>
    );
};

SitePlaceholder.propTypes = {
    isHidden: PropTypes.bool,
};

SitePlaceholder.defaultProps = {
    isHidden: false,
};

SitePlaceholder.displayName = 'SitePlaceholder';

export default memo(SitePlaceholder);
