import React, { useMemo } from 'react';
import Icon from '@chayns-components/Icon';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { getAppState } from '../../../../../../redux-modules/app/selector';

type PageSelectionTypes = {
    activePage: number,
    totalPages: number,
    onPageClick: (pageIndex: number) => void,
};

const PageSelection: React.FC<PageSelectionTypes> = ({
    activePage,
    totalPages,
    onPageClick,
}) => {
    const app = useSelector(getAppState);
    const {
        colorMode,
    } = app;

    const shwonPages = useMemo(() => {
        const startIndex = (totalPages - activePage) >= 3 ? Math.max(activePage - 2, 1) : Math.max(totalPages - 4, 1);
        const endIndex = Math.min(startIndex + 4, totalPages);
        const pages = [];
        for (let i = startIndex; i <= endIndex; i += 1) {
            pages.push(i);
        }
        return pages;
    }, [activePage, totalPages]);

    if (totalPages === 1) {
        return null;
    }

    return (
        <>
            <div
                className="page-selection-wrapper"
            >
                <Icon
                    icon="far fa-arrow-left"
                    className={classNames('page-selection-arrow-left', {
                        'arrow-is-visible': activePage !== 1,
                    })}
                    onClick={() => onPageClick(activePage - 1)}
                />
                {shwonPages.map((page) => (
                    <div
                        key={page}
                        className={classNames('page', {
                            'page-is-selected': activePage === page,
                        })}
                        onClick={() => onPageClick(page)}
                    >
                        {page}
                    </div>
                ))}
                <Icon
                    icon="far fa-arrow-right"
                    className={classNames('page-selection-arrow-right', {
                        'arrow-is-visible': activePage !== totalPages,
                    })}
                    onClick={() => onPageClick(activePage + 1)}
                />
            </div>
            <style jsx global>
                {`
                    .page-selection-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 40px;

                        .page-selection-arrow-left {
                            margin-right: 20px;
                            opacity: 0;
                            cursor: default;
                            user-select: none;
                            pointer-events: none;
                            color: ${colorMode === 'dark' ? 'white' : 'var(--chayns-color--primary)'};

                            &.arrow-is-visible {
                                opacity: 1;
                                cursor: pointer;
                                pointer-events: all;
                            }
                        }

                        .page-selection-arrow-right {
                            margin-left: 20px;
                            opacity: 0;
                            cursor: default;
                            user-select: none;
                            pointer-events: none;
                            color: ${colorMode === 'dark' ? 'white' : 'var(--chayns-color--primary)'};

                            &.arrow-is-visible {
                                opacity: 1;
                                cursor: pointer;
                                pointer-events: all;
                            }
                        }

                        .page {
                            cursor: pointer;
                            margin: 0 5px;
                            user-select: none;
                            width: 20px;
                            display: flex;
                            justify-content: center;
                            color: ${colorMode === 'dark' ? 'white' : 'var(--chayns-color--primary)'};

                            &.page-is-selected {
                                font-weight: bold;
                                color: ${colorMode === 'dark' ? 'white' : 'black'};
                            }
                        }
                    }
                `}
            </style>
        </>
    );
};

export default PageSelection;
