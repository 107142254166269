import React, { FC } from 'react';

type TobitTeamLogoProps = {
    margin?: number;
    height?: number;
    width?: number;
}

const TobitTeamByDavidLogo: FC<TobitTeamLogoProps> = ({ margin = 0, height, width }) => (
    <>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={height}
            width={width}
            viewBox="0 0 384.95 124.31"
            className="tobit-team-by-david-logo"
        >
            <path
                className="cls-1"
                d="M372.21,98.61c-.69-.69-1.52-1.03-2.48-1.03-.47,0-.92.09-1.34.27-.43.18-.8.43-1.12.74-.32.32-.56.69-.74,1.12-.18.43-.27.89-.27,1.38s.09.96.27,1.38c.18.43.43.8.74,1.12.32.32.69.56,1.12.74.43.18.87.27,1.34.27.96,0,1.79-.34,2.48-1.03.69-.69,1.03-1.51,1.03-2.48s-.34-1.79-1.03-2.48ZM371.77,103.14c-.56.56-1.25.85-2.04.85s-1.47-.28-2.02-.85c-.55-.56-.83-1.25-.83-2.05s.27-1.48.83-2.05c.55-.56,1.22-.85,2.02-.85s1.48.28,2.04.85c.56.56.85,1.25.85,2.05s-.28,1.48-.85,2.05ZM370.52,101.34c.22-.08.4-.22.54-.41.14-.17.21-.4.21-.7,0-.41-.12-.72-.37-.91-.28-.22-.65-.33-1.12-.33h-1.45v4.09h.74v-1.57h.78l.83,1.57h.74l-.91-1.73ZM369.77,100.92h-.74v-1.36h.74c.22,0,.41.06.58.17.11.11.17.29.17.54,0,.22-.06.39-.17.5s-.3.17-.58.17Z"
            />
            <path
                className="cls-1"
                d="M366.55,98.28v-24.67h-7.44l.08,12.6c-.72-1.21-1.54-2.13-2.48-2.77-.85-.58-2.01-.87-3.47-.87-1.3,0-2.45.32-3.47.95-.96.66-1.75,1.5-2.35,2.52-.61.99-1.07,2.15-1.4,3.47-.14.63-.25,1.27-.33,1.9-.08.63-.12,1.27-.12,1.9,0,1.57.15,3.08.45,4.54.17.72.36,1.38.6,2,.23.62.52,1.19.85,1.72.66,1.02,1.5,1.83,2.52,2.44,1.02.61,2.23.91,3.64.91,1.13,0,2.16-.28,3.1-.83.96-.55,1.83-1.54,2.6-2.97h.08c0,.3,0,.59.02.87.01.28.03.56.06.87l.12,1.74h6.94v-.7c-.3-.39-.55-.81-.74-1.28-.19-.47-.29-.98-.29-1.53s.1-1.06.29-1.53c.19-.47.44-.89.74-1.28ZM358.41,98.03c-.5,1.13-1.21,1.69-2.15,1.69-1.07,0-1.82-.56-2.23-1.69-.41-1.13-.62-2.59-.62-4.38s.21-3.28.62-4.38c.41-1.1,1.16-1.65,2.23-1.65.94,0,1.65.55,2.15,1.65.47,1.16.7,2.62.7,4.38s-.23,3.22-.7,4.38Z"
            />
            <path
                className="cls-1"
                d="M336.88,73.62h7.44v6.2h-7.44v-6.2ZM338.79,83.08h5.54v21.53h-7.44v-15.66l1.9-5.87Z"
            />
            <path
                className="cls-1"
                d="M337.21,83.04l-6.9,21.53h-8.8l-6.78-21.53h7.85l3.68,14.71,3.68-14.71h7.27Z"
            />
            <path
                className="cls-1"
                d="M309.37,104.58c-.24-.44-.46-1.1-.64-1.96-1.25,1.57-3,2.36-5.24,2.36-2.06,0-3.8-.62-5.24-1.86-1.44-1.24-2.16-2.8-2.16-4.69,0-2.36.87-4.15,2.62-5.36,1.75-1.21,4.29-1.81,7.62-1.81h2.1v-1.15c0-2.01-.87-3.02-2.6-3.02-1.61,0-2.42.8-2.42,2.39h-6.79c0-2.11.9-3.82,2.69-5.13s4.08-1.97,6.86-1.97,4.98.68,6.59,2.04c1.61,1.36,2.44,3.22,2.48,5.58v9.65c.03,2,.34,3.53.93,4.6v.34h-6.79ZM305.12,100.14c.85,0,1.55-.18,2.11-.54.56-.36.96-.77,1.2-1.23v-3.49h-1.98c-2.37,0-3.55,1.06-3.55,3.18,0,.62.21,1.12.62,1.5.42.38.95.57,1.59.57Z"
            />
            <path
                className="cls-1"
                d="M271.34,75.72h11.11c2.12,0,4,.34,5.64,1.01,1.64.68,3.02,1.64,4.15,2.89,1.13,1.25,1.98,2.75,2.56,4.5.58,1.75.87,3.7.87,5.85,0,2.51-.32,4.68-.97,6.51-.65,1.83-1.58,3.35-2.81,4.54-1.23,1.2-2.73,2.09-4.52,2.66-1.79.58-3.83.87-6.11.87h-9.92v-28.84ZM279.1,99.02h1.69c2.23,0,3.9-.76,5.02-2.27,1.12-1.51,1.67-3.68,1.67-6.49,0-1.84-.21-3.35-.62-4.5s-.97-2.05-1.67-2.69c-.7-.63-1.53-1.07-2.48-1.3s-1.95-.35-3-.35h-.62v17.6Z"
            />
            <path
                className="cls-1"
                d="M250.38,99.29l4.82-15.53h3.79l-8.33,23.91c-1.29,3.45-3.34,5.17-6.15,5.17l-.67-.06-1.32-.25v-2.87l.96.08c1.2,0,2.13-.24,2.8-.73.67-.49,1.22-1.37,1.66-2.66l.78-2.11-7.39-20.48h3.87l5.17,15.53Z"
            />
            <path
                className="cls-1"
                d="M240.14,94.35c0,3.17-.73,5.71-2.18,7.63-1.46,1.92-3.41,2.88-5.86,2.88-2.62,0-4.64-.93-6.07-2.78l-.17,2.39h-3.25v-29.4h3.54v10.97c1.43-1.77,3.4-2.66,5.92-2.66s4.49.95,5.92,2.85c1.44,1.9,2.15,4.5,2.15,7.81v.31ZM236.6,93.95c0-2.41-.47-4.28-1.4-5.59s-2.27-1.97-4.02-1.97c-2.34,0-4.01,1.08-5.03,3.25v8.96c1.08,2.17,2.78,3.25,5.07,3.25,1.7,0,3.02-.66,3.96-1.97.94-1.31,1.42-3.29,1.42-5.93Z"
            />
            <path
                className="cls-1"
                d="M191.49,93.95c0-3.18.75-5.73,2.26-7.67,1.51-1.93,3.48-2.9,5.92-2.9s4.35.83,5.76,2.49v-10.8h3.54v29.4h-3.25l-.17-2.22c-1.42,1.74-3.39,2.6-5.92,2.6s-4.36-.98-5.87-2.95c-1.51-1.96-2.27-4.53-2.27-7.7v-.27ZM195.03,94.35c0,2.35.48,4.19,1.45,5.51s2.31,1.99,4.02,1.99c2.25,0,3.89-1.01,4.92-3.02v-9.51c-1.06-1.95-2.69-2.93-4.88-2.93-1.74,0-3.09.67-4.06,2.01s-1.45,3.32-1.45,5.95Z"
            />
            <path
                className="cls-1"
                d="M180.96,104.86c-2.81,0-5.09-.92-6.85-2.77-1.76-1.84-2.64-4.31-2.64-7.4v-.65c0-2.05.39-3.89,1.18-5.5.78-1.61,1.88-2.88,3.29-3.79,1.41-.91,2.94-1.37,4.58-1.37,2.69,0,4.79.89,6.28,2.66,1.49,1.77,2.24,4.31,2.24,7.62v1.47h-14.03c.05,2.04.65,3.69,1.79,4.95,1.14,1.26,2.59,1.89,4.36,1.89,1.25,0,2.31-.26,3.18-.77.87-.51,1.63-1.19,2.28-2.03l2.16,1.68c-1.74,2.67-4.34,4-7.81,4ZM180.52,86.29c-1.43,0-2.63.52-3.6,1.56s-1.57,2.5-1.8,4.37h10.38v-.27c-.1-1.8-.59-3.19-1.45-4.18-.87-.99-2.04-1.48-3.52-1.48Z"
            />
            <path
                className="cls-1"
                d="M161.24,99.67l5.13-15.91h3.62l-7.43,20.71h-2.7l-7.5-20.71h3.62l5.26,15.91Z"
            />
            <path
                className="cls-1"
                d="M151.59,86.94c-.54-.09-1.12-.13-1.74-.13-2.32,0-3.9.99-4.73,2.97v14.7h-3.54v-20.71h3.45l.06,2.39c1.16-1.85,2.81-2.78,4.94-2.78.69,0,1.21.09,1.57.27v3.29Z"
            />
            <path
                className="cls-1"
                d="M130.19,104.86c-2.81,0-5.09-.92-6.85-2.77-1.76-1.84-2.64-4.31-2.64-7.4v-.65c0-2.05.39-3.89,1.18-5.5.78-1.61,1.88-2.88,3.29-3.79,1.41-.91,2.94-1.37,4.58-1.37,2.69,0,4.79.89,6.28,2.66,1.49,1.77,2.24,4.31,2.24,7.62v1.47h-14.03c.05,2.04.65,3.69,1.79,4.95,1.14,1.26,2.59,1.89,4.36,1.89,1.25,0,2.31-.26,3.18-.77.87-.51,1.63-1.19,2.28-2.03l2.16,1.68c-1.74,2.67-4.34,4-7.81,4ZM129.75,86.29c-1.43,0-2.63.52-3.6,1.56s-1.57,2.5-1.8,4.37h10.38v-.27c-.1-1.8-.59-3.19-1.45-4.18-.87-.99-2.04-1.48-3.52-1.48Z"
            />
            <path
                className="cls-1"
                d="M114.22,98.98c0-.96-.36-1.7-1.08-2.23-.72-.53-1.98-.99-3.77-1.37-1.79-.38-3.22-.84-4.27-1.38s-1.83-1.17-2.33-1.91c-.5-.74-.76-1.62-.76-2.64,0-1.7.72-3.13,2.15-4.31s3.27-1.76,5.5-1.76c2.35,0,4.25.61,5.71,1.82,1.46,1.21,2.19,2.76,2.19,4.65h-3.56c0-.97-.41-1.81-1.23-2.51s-1.86-1.05-3.11-1.05-2.3.28-3.02.84c-.73.56-1.09,1.29-1.09,2.2,0,.85.34,1.5,1.01,1.93.68.43,1.9.85,3.67,1.24,1.77.4,3.2.87,4.3,1.42,1.1.55,1.91,1.21,2.44,1.98.53.77.79,1.71.79,2.82,0,1.85-.74,3.33-2.22,4.45-1.48,1.12-3.4,1.68-5.76,1.68-1.66,0-3.13-.29-4.4-.88-1.28-.59-2.28-1.41-3-2.46-.72-1.05-1.08-2.19-1.08-3.42h3.54c.06,1.19.54,2.13,1.43,2.82s2.06,1.04,3.51,1.04c1.34,0,2.42-.27,3.23-.81.81-.54,1.22-1.27,1.22-2.17Z"
            />
            <path
                className="cls-1"
                d="M330.83,14.5v5.77h-1.57v-5.77h-2.25v-1.27h5.99v1.27h-2.17ZM340.94,20.27v-6.14l-2.1,6.14h-1.42l-2.1-6.14v6.14h-1.35v-7.04h2.4l1.8,5.32,1.8-5.32h2.4v7.04h-1.42Z"
            />
            <path
                className="cls-1"
                d="M273.94,33.19c0-1.95-.05-3.67-.15-5.17-.1-1.5-.2-2.57-.3-3.22h12.66c.1.8.17,1.81.22,3.03.05,1.22.07,2.39.07,3.48h.15c.2-.5.52-1.17.97-2.02.45-.85,1.1-1.67,1.95-2.47.85-.8,1.94-1.49,3.26-2.06,1.32-.57,2.93-.86,4.83-.86,1.05,0,2.09.1,3.11.3,1.02.2,2,.54,2.92,1.01.92.48,1.79,1.14,2.59,1.99.8.85,1.5,1.92,2.1,3.22,1.3-2.05,2.87-3.65,4.72-4.8,1.85-1.15,4.17-1.72,6.97-1.72,1.55,0,3.02.22,4.42.67,1.4.45,2.62,1.16,3.67,2.14,1.05.97,1.87,2.21,2.47,3.71.6,1.5.9,3.3.9,5.4v28.03h-13.49v-23.45c0-1.9-.3-3.3-.9-4.2-.6-.9-1.57-1.35-2.92-1.35-1,0-1.8.2-2.4.6-.6.4-1.07.91-1.42,1.54-.35.63-.59,1.31-.71,2.06-.13.75-.19,1.5-.19,2.25v22.56h-13.49v-23.45c0-3.7-1.22-5.55-3.67-5.55-.85,0-1.57.2-2.17.6-.6.4-1.1.95-1.5,1.65-.4.7-.7,1.51-.9,2.44-.2.92-.3,1.89-.3,2.88v21.43h-13.49v-30.65Z"
            />
            <path
                className="cls-1"
                d="M259.11,63.83c-.44-.8-.83-1.99-1.17-3.55-2.27,2.85-5.44,4.28-9.51,4.28-3.73,0-6.9-1.13-9.51-3.38-2.61-2.26-3.92-5.09-3.92-8.51,0-4.29,1.59-7.54,4.76-9.73,3.17-2.2,7.78-3.29,13.83-3.29h3.81v-2.1c0-3.65-1.57-5.48-4.72-5.48-2.93,0-4.39,1.45-4.39,4.34h-12.33c0-3.82,1.63-6.93,4.88-9.32s7.41-3.58,12.46-3.58,9.04,1.23,11.96,3.7c2.93,2.46,4.43,5.84,4.5,10.14v17.53c.05,3.63.61,6.42,1.68,8.34v.62h-12.33ZM251.39,55.78c1.54,0,2.81-.33,3.82-.99,1.01-.66,1.74-1.4,2.18-2.23v-6.33h-3.59c-4.29,0-6.44,1.93-6.44,5.78,0,1.12.38,2.03,1.13,2.73.76.7,1.72,1.04,2.89,1.04Z"
            />
            <path
                className="cls-1"
                d="M216.02,64.56c-6.07,0-10.99-1.81-14.75-5.43-3.76-3.62-5.63-8.34-5.63-14.14v-1.02c0-4.05.75-7.62,2.25-10.72,1.5-3.1,3.69-5.49,6.57-7.19,2.88-1.7,6.29-2.54,10.24-2.54,5.56,0,9.95,1.73,13.17,5.18,3.22,3.45,4.83,8.26,4.83,14.43v4.79h-24.51c.44,2.22,1.4,3.96,2.89,5.23,1.49,1.27,3.41,1.9,5.78,1.9,3.9,0,6.95-1.37,9.15-4.1l5.63,6.66c-1.54,2.12-3.71,3.81-6.53,5.07s-5.85,1.88-9.09,1.88ZM214.63,33.02c-3.61,0-5.76,2.39-6.44,7.17h12.44v-.95c.05-1.98-.45-3.51-1.5-4.59-1.05-1.09-2.55-1.63-4.5-1.63Z"
            />
            <polygon
                className="cls-1"
                points="177.77 23.3 164.21 23.3 164.21 11.46 205.2 11.46 205.2 23.3 191.41 23.3 191.41 63.84 177.77 63.84 177.77 23.3"
            />
            <path
                className="cls-1"
                d="M162.39,51.63c1.9,0,3.5.66,4.8,1.99,1.3,1.32,1.95,2.94,1.95,4.83s-.66,3.51-1.99,4.83c-1.32,1.32-2.91,1.99-4.76,1.99-.95,0-1.85-.18-2.7-.52-.85-.35-1.59-.84-2.21-1.46-.63-.62-1.11-1.35-1.46-2.17-.35-.82-.52-1.71-.52-2.66,0-1.9.66-3.51,1.99-4.83,1.32-1.32,2.96-1.99,4.91-1.99ZM162.39,63.99c1.5,0,2.78-.55,3.86-1.65,1.07-1.1,1.61-2.4,1.61-3.9s-.54-2.8-1.61-3.9c-1.07-1.1-2.36-1.65-3.86-1.65-1.6,0-2.94.54-4.01,1.61s-1.61,2.39-1.61,3.93.55,2.86,1.65,3.93c1.1,1.07,2.42,1.61,3.97,1.61ZM163.97,58.9l1.65,3.22v.08h-1.65l-1.42-2.85h-1.27v2.85h-1.57v-7.72h2.85c.75,0,1.4.18,1.95.52.55.35.82.95.82,1.8,0,1-.45,1.7-1.35,2.1ZM163.74,56.95c0-.8-.4-1.2-1.2-1.2h-1.27v2.32h1.27c.8,0,1.2-.37,1.2-1.12Z"
            />
            <polygon
                className="cls-1"
                points="140.1 11.55 152.99 11.55 152.99 28.11 162.28 28.11 162.28 38.9 152.99 38.9 152.99 64.08 140.1 64.08 140.1 11.55"
            />
            <path
                className="cls-1"
                d="M123.39,24.74v-13.19h13.19v13.19h-13.19ZM136.58,63.99V28.02h-13.19v35.97h13.19Z"
            />
            <path
                className="cls-1"
                d="M120.06,37.54c-.48-2.25-1.24-4.28-2.29-6.11-1.05-1.82-2.44-3.33-4.16-4.53-1.72-1.2-3.83-1.8-6.33-1.8s-4.5.55-5.99,1.65c-1.4.85-2.87,2.5-4.42,4.95V11.46h-13.04v52.53h12.59v-6.14h.15c1.4,2.7,2.92,4.45,4.57,5.25,1.6,1,3.42,1.5,5.47,1.5,2.85,0,5.2-.63,7.04-1.87,1.85-1.25,3.3-2.87,4.35-4.87,1.05-2,1.77-4.21,2.17-6.63.4-2.42.6-4.81.6-7.16,0-2.1-.24-4.27-.71-6.52ZM107.09,47.96c-.07,1.2-.28,2.34-.6,3.41-.33,1.07-.84,2-1.54,2.77-.7.78-1.67,1.16-2.92,1.16-1.6,0-2.82-.95-3.67-2.85-1-1.7-1.5-4.25-1.5-7.64s.5-6.02,1.5-7.87c.85-1.8,2.07-2.77,3.67-2.92,1.9.15,3.27,1.12,4.12,2.92.7,2.1,1.05,4.72,1.05,7.87,0,.9-.04,1.95-.11,3.15Z"
            />
            <path
                className="cls-1"
                d="M79.89,36.89c-.8-2.45-2-4.56-3.6-6.33-1.6-1.77-3.57-3.13-5.92-4.08-2.35-.95-5.1-1.42-8.24-1.42s-5.91.48-8.28,1.42c-2.37.95-4.35,2.3-5.92,4.05-1.57,1.75-2.76,3.86-3.56,6.33-.8,2.47-1.2,5.21-1.2,8.21,0,6.25,1.64,11.12,4.91,14.61,3.27,3.5,7.95,5.25,14.05,5.25,3,0,5.67-.46,8.02-1.39,2.35-.92,4.33-2.24,5.96-3.93,1.62-1.7,2.86-3.78,3.71-6.26.85-2.47,1.27-5.23,1.27-8.28s-.4-5.72-1.2-8.17ZM67.3,48.28c-.05,1.25-.25,2.45-.6,3.6-.35,1.15-.87,2.12-1.57,2.92-.7.8-1.7,1.2-3,1.2s-2.22-.4-2.92-1.2c-.7-.8-1.22-1.76-1.57-2.89-.35-1.12-.55-2.31-.6-3.56-.05-1.25-.07-2.35-.07-3.3,0-.9.02-1.99.07-3.26.05-1.27.24-2.5.56-3.67.32-1.17.84-2.16,1.54-2.96.7-.8,1.7-1.2,3-1.2s2.3.41,3,1.24c.7.82,1.22,1.81,1.57,2.96.35,1.15.55,2.36.6,3.63.05,1.27.07,2.36.07,3.26s-.03,1.97-.07,3.22Z"
            />
            <polygon
                className="cls-1"
                points="25.27 23.4 11.7 23.4 11.7 11.56 52.69 11.56 52.69 23.4 38.9 23.4 38.9 63.94 25.27 63.94 25.27 23.4"
            />
        </svg>
        <style jsx>
            {`
                .tobit-team-by-david-logo {
                    margin-left: ${margin}px;
                    fill: #fff;
                }
            `}
        </style>
    </>
);

export default TobitTeamByDavidLogo;
