// BB Codes
const BB_CENTER_REGEX = /\[center]([\s\S]*?)(?:\[\/center])/g;
const BB_STRIKETHROUGH_REGEX = /\[s]([\s\S]*?)(?:\[\/s])/g;
const BB_UNDERLINE_REGEX = /\[u]([\s\S]*?)(?:\[\/u])/g;
const BB_ITALIC_REGEX = /\[i]([\s\S]*?)(?:\[\/i])/g;
const BB_BOLD_REGEX = /\[b]([\s\S]*?)(?:\[\/b])/g;

// BB Codes HTML
const BB_SPAN_REGEX = /\[span(?:|.*?style="(.*?)")]([\s\S]*?)(?:\[\/span])/g;
const BB_H1_REGEX = /\[h1(?:|.*?style="(.*?)")]([\s\S]*?)(?:\[\/h1])/g;
const BB_H2_REGEX = /\[h2(?:|.*?style="(.*?)")]([\s\S]*?)(?:\[\/h2])/g;
const BB_H3_REGEX = /\[h3(?:|.*?style="(.*?)")]([\s\S]*?)(?:\[\/h3])/g;
const BB_P_REGEX = /\[p(?:|.*?style="(.*?)")]([\s\S]*?)(?:\[\/p])/g;
const BB_UL_REGEX = /\[ul]([\s\S]*?)(?:\[\/ul])/g;
const BB_OL_REGEX = /\[ol]([\s\S]*?)(?:\[\/ol])/g;
const BB_LI_REGEX = /\[li]([\s\S]*?)(?:\[\/li])/g;

// Button
const BUTTON_REGEX = /\[button(?:|=".+?"|(?: \S+?)*)](.+?)\[\/button]/ig;

// Link
const LINK_REGEX = /\[link(?:|=".+?"|(?: \S+?)*)](.+?)\[\/link]/ig;

export const escapeHtmlInText = (text: string): string => text.replace(/</g, '&lt;').replace(/>/g, '&gt;');

export const convertAdditionalEmojis = (text: string): string => text.replace(/\(y\)/g, '👍').replace(/\(n\)/g, '👎');

export const escapeBBCodes = (text: string): string => text
    .replace(BB_P_REGEX, '$2 ')
    .replace(BB_H1_REGEX, '$2 ')
    .replace(BB_H2_REGEX, '$2 ')
    .replace(BB_H3_REGEX, '$2 ')
    .replace(BB_UL_REGEX, '$1 ')
    .replace(BB_OL_REGEX, '$1 ')
    .replace(BB_LI_REGEX, '$1')
    .replace(BB_SPAN_REGEX, '$2')
    .replace(BB_BOLD_REGEX, '$1')
    .replace(BB_CENTER_REGEX, '$1 ')
    .replace(BB_ITALIC_REGEX, '$1')
    .replace(BB_UNDERLINE_REGEX, '$1')
    .replace(BB_STRIKETHROUGH_REGEX, '$1')
    .replace(BUTTON_REGEX, '$1 ')
    .replace(LINK_REGEX, '$1');
