import getSitesTappWidth from './getSitesTappWidth';

const calcItemWidth = (
    isMyChaynsApp: boolean,
    width: number,
    hideOtherContent: boolean,
): string => {
    try {
        const sitesTappWidth = getSitesTappWidth(width, hideOtherContent, isMyChaynsApp);

        switch (true) {
            case sitesTappWidth < 300:
                return '50%';
            case sitesTappWidth < 360:
                return `${(1 / 3) * 100}%`;
            case sitesTappWidth < 450:
                return '25%';
            case sitesTappWidth < 580:
                return '20%';
            case sitesTappWidth < 800:
                return `${(1 / 6) * 100}%`;
            default:
                return '12.5%';
        }
    } catch (error) {
        return '12.5%';
    }
};

export default calcItemWidth;
