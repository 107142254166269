import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DialogButtonType, DialogType } from 'chayns-api';
import { getAppState } from '../../redux-modules/app/selector';
import DialogHelper from '../../calls/dialog/DialogHelper';
import { getUserState } from '../../redux-modules/user/selector';
import { clearVrLogin } from '../../redux-modules/app/actions';

const dialog = new DialogHelper();

const useVrLogin = () => {
    const app = useSelector(getAppState);
    const dispatch = useDispatch();
    const {
        isVrLogin,
    } = app;

    const user = useSelector(getUserState);

    useEffect(() => {
        if (!isVrLogin || !user?.personId) return;

        const login = async () => {
            const { buttonType, result } = await dialog.createDialog({
                type: DialogType.INPUT,
                placeholder: 'Code',
                text: 'Gib den 6-stelligen Code ein, um Dich automatisch in der VR-Welt einzuloggen.',
                buttons: [{
                    text: 'Ok',
                    type: DialogButtonType.OK,
                }, {
                    text: 'Abbrechen',
                    type: DialogButtonType.CANCEL,
                }],
            }).open();
            if (buttonType !== DialogButtonType.OK || !result) return 0;
            const res = await fetch('https://auth.chayns.net/v2/remotelogin?siteId=60021-08989', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${typeof chaynsInfo !== 'undefined' ? window.chaynsInfo.User.TobitAccessToken : chayns.env.user.tobitAccessToken}`,
                },
                body: JSON.stringify({
                    code: result,
                }),
            });
            if (!res.ok) {
                await dialog.createDialog({
                    type: DialogType.ALERT,
                    text: 'Falscher Code',
                }).open();
                login();
                return 1;
            }
            return 2;
        };
        (async () => {
            const res = await login();
            if (res === 2) {
                await dialog.createDialog({
                    type: DialogType.ALERT,
                    text: 'Erfolgreich eingeloggt',
                }).open();
            }
            dispatch(clearVrLogin());
        })();
    }, [isVrLogin, user?.personId]);
};

export default useVrLogin;
