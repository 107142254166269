import React, { useMemo } from 'react';
import tobitEmojione from 'tobit-emojione';
import { useSelector } from 'react-redux';
import Icon from '@chayns-components/Icon';
import { Member, Message } from '../../../../../../../types/thread';
import { getMemberById, getMemberName, getMyMember } from '../utils/member';
import { convertAdditionalEmojis, escapeBBCodes, escapeHtmlInText } from '../utils/text';
import { getIntercomPluginsState } from '../../../../../../../redux-modules/intercom/selector';

type PreviewTextTypes = {
    members: Array<Member>,
    message: Message,
    userId: number
};

export const THUMBS_UP_MESSAGE_TEXT_WITHOUT_THUMB = ' Stimme zu.';

export const THUMBS_UP_MESSAGE_TEXT = `👍${THUMBS_UP_MESSAGE_TEXT_WITHOUT_THUMB}`;

const PreviewText: React.FC<PreviewTextTypes> = ({ members, message, userId }) => {
    const plugins = useSelector(getIntercomPluginsState);

    const { author, images, pluginId, text, typeId } = message;

    let prefix = '';
    let content = '';

    const myMember = getMyMember(members, userId);
    const authorMember = getMemberById(members, author.id);

    const authorName = authorMember ? getMemberName(authorMember) : '';

    const isMessageFromMe = author.id === myMember?.id;

    if (
        members.length > 2
        && !isMessageFromMe
        && typeId !== 12
        && authorName.trim() !== ''
    ) {
        prefix = `${authorName}: `;
    }

    if (!text || text.trim() === '') {
        if (typeof pluginId === 'number' && pluginId > 0 && plugins.length > 0) {
            const givenPlugin = plugins.find(({ id }) => id === pluginId);

            if (givenPlugin) {
                const { name, previewText } = givenPlugin;

                content = `${prefix}${previewText || name || 'Plugin'}`;
            } else {
                content = `${prefix}Unbekanntes Plugin`;
            }
        } else if (images.length > 0) {
            content = `${prefix}Bild`;
        }
    }

    return useMemo(() => {
        if (content) {
            return <span className="preview-text">{content}</span>;
        }

        if (typeof text === 'string' && text.trim() !== '') {
            let previewText = escapeHtmlInText(text);

            let isThumbsUpMessage = false;

            if (previewText === THUMBS_UP_MESSAGE_TEXT) {
                isThumbsUpMessage = true;

                previewText = THUMBS_UP_MESSAGE_TEXT_WITHOUT_THUMB;
            } else {
                previewText = escapeBBCodes(previewText);
                previewText = convertAdditionalEmojis(previewText);
                previewText = previewText.replace(/\s{2,}/, ' ');
                previewText = tobitEmojione.toImage(previewText);
            }

            return (
                // eslint-disable-next-line react/no-danger
                <span className="preview-text">
                    {prefix && (
                        <span className="preview-text__prefix notranslate">
                            {`${prefix}:`}
                        </span>
                    )}
                    {/* eslint-disable-next-line react/no-danger */}
                    {isThumbsUpMessage && (
                        <Icon
                            icon="fal fa-thumbs-up"
                            style={{ color: 'var(--chayns-color--text)', margin: '0 2px' }}
                        />
                    )}
                    <span
                        className="notranslate"
                        dangerouslySetInnerHTML={{ __html: previewText }}
                    />
                </span>

            );
        }

        return <span/>;
    }, [content, prefix, text]);
};

PreviewText.displayName = 'PreviewText';

export default PreviewText;
