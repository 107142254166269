import logFetch from '../../utils/logFetch';
import { Site } from '../../types/chayns';
import logger from "../../constants/logger";

const loadSearchSites = async (
    searchString: string,
    take: number,
    skip: number,
    geoPosition: { geoPivot: number, longitude: number, latitude: number },
    activePage: number,
    signal?: AbortSignal,
): Promise<{ status: number, result?: { values: Array<Site>, total: number }}> => {
    try {
        const logOptions = {
            fileName: __filename,
            section: '0',
            message: 'GET search sites',
        };

        const requestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${typeof chaynsInfo !== 'undefined' ? chaynsInfo.User.TobitAccessToken : chayns.env.user.tobitAccessToken}`,
            },
            signal,
        };

        const response = await logFetch(
            `https://webapi.tobit.com/chayns_search/v1.0/search/global?searchString=${encodeURIComponent(searchString?.trim())}&skip=${skip}&take=${take}&removeTextFromResult=true&includeHighlightedText=false&includeMetadata=false&keywordSearch=true&siteNameBoost=40&tappNameBoost=0&exactMatchWeight=10&domainBoost=40&geoPivot=${geoPosition.geoPivot}&longitude=${geoPosition.longitude}&latitude=${geoPosition.latitude}&type=Location&includeSiteColor=true`,
            requestInit,
            logOptions,
        );

        logger().info({
            message: 'Load Sites',
            section: 'global search sites',
            customText: searchString,
            customNumber: activePage,
            data: {
                take,
                skip,
                activePage,
                searchString,
            },
        });

        if (response.status === 200) {
            const result = await response.json();

            return {
                status: response.status,
                result,
            };
        }
        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: 'Failed to load search sites',
            section: 'api/search/sites',
        }, {
            message: ex?.message,
        });
        return {
            status: 500,
        };
    }
};

const loadSearchPages = async (
    searchString: string,
    take: number,
    skip: number,
    geoPosition: { geoPivot: number, longitude: number, latitude: number },
    activePage: number,
    signal?: AbortSignal,
): Promise<{ status: number, result?: { total: number, values: Array<Site> }}> => {
    try {
        const logOptions = {
            fileName: __filename,
            section: '0',
            message: 'GET search sites',
        };

        const requestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${typeof chaynsInfo !== 'undefined' ? chaynsInfo.User.TobitAccessToken : chayns.env.user.tobitAccessToken}`,
            },
            signal,
        };

        const response = await logFetch(`https://webapi.tobit.com/chayns_search/v1.0/search/global?searchString=${encodeURIComponent(searchString?.trim())}&skip=${skip}&take=${take}&removeTextFromResult=true&includeHighlightedText=true&includeMetadata=true&keywordSearch=true&siteNameBoost=1&tappNameBoost=1&domainBoost=3&exactMatchWeight=100&geoPositionBoost=3&geoPivot=${geoPosition.geoPivot}&longitude=${geoPosition.longitude}&latitude=${geoPosition.latitude}&type=Page`, requestInit, logOptions);

        logger().info({
            message: 'Load Pages',
            section: 'global search Pages',
            customText: searchString,
            customNumber: activePage,
            data: {
                take,
                skip,
                activePage,
                searchString,
            },
        });

        if (response.status === 200) {
            const result = await (response as Response).json();

            return {
                status: response.status,
                result,
            };
        }
        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: 'Failed to load search pages',
            section: 'api/search/sites',
        }, {
            message: ex?.message,
        });
        return {
            status: 500,
        };
    }
};

export {
    loadSearchSites,
    loadSearchPages,
};
