import { addApiListener, dispatchApiEvent, removeApiListener } from './apiListenerHelper';

const listener = () => {
    dispatchApiEvent('windowMetrics', {
        bottomBarHeight: 0,
        topBarHeight: 0,
        offsetTop: 0,
        windowHeight: window.innerHeight,
        pageHeight: 0,
        pageWidth: window.innerWidth,
        pageSize: null,
    });
};

export const addWindowMetricsListener = (callback) => {
    const { shouldInitialize, id } = addApiListener('windowMetrics', callback);

    if (shouldInitialize) {
        window.addEventListener('resize', listener);
    }
    return id;
};

export const removeWindowMetricsListener = (id: number) => {
    if (removeApiListener('windowMetrics', id)) {
        window.removeEventListener('resize', listener);
    }
};
