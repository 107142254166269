import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import parseJwt from '../../../../utils/parseJwt';
import { getUserState } from '../../../../redux-modules/user/selector';

type UseListTypes = {
    allUser,
    setuserIds,
    searchValue,
    userIds: Array<number>,
    endpoint: string,
};

const UserList: React.FC<UseListTypes> = ({
    allUser,
    setuserIds,
    searchValue,
    userIds,
    endpoint,
}) => {
    const user = useSelector(getUserState);
    const tokenJti = useMemo(() => parseJwt(user.token), [user.token]);

    return (
        <>
            <div
                className="dialog_list scrollbar"
            >
                {allUser && allUser.map((currentUser) => {
                    if (tokenJti && `${currentUser.firstname} ${currentUser.lastname}`.toLowerCase()
                        .includes(searchValue)) {
                        return (
                            <div
                                className={classnames('dialog__item', { 'chayns__background-color--103': userIds.find((selected) => selected === currentUser.id) })}
                                key={currentUser.id}
                                onClick={() => {
                                    if (!userIds.find((item) => item === currentUser.id)) {
                                        setuserIds([...userIds, currentUser.id]);
                                    } else {
                                        setuserIds((prev) => prev.filter((selected) => selected !== currentUser.id));
                                    }
                                }}
                            >
                                <div
                                    className="dialog__item--checkbox"
                                >
                                    <input
                                        type="checkbox"
                                        className="checkbox"
                                        name="check"
                                        id="check"
                                        checked={!!userIds.find((item) => item === currentUser.id)}
                                    />
                                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                    <label
                                        htmlFor="check"
                                    />
                                </div>
                                <div
                                    className="dialog__item--image"
                                >
                                    <img
                                        src={`${endpoint}v1.0/Users/${currentUser.id}/Photo?jti=${tokenJti}`}
                                        // eslint-disable-next-line no-return-assign, no-param-reassign
                                        onError={(event) => (event.target as HTMLImageElement).src = '/unknown_user.png'}
                                        alt=""
                                    />
                                </div>
                                <div
                                    className="dialog__item--name"
                                >
                                    <span>
                                        {`${currentUser.firstname || ''} ${currentUser.lastname || ''}`.trim()}
                                    </span>
                                </div>
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
            <style jsx>
                {`
                .dialog_list {
                    overflow-y: scroll;
                    max-height: 240px;

                    .dialog__item {
                        align-items: center;
                        cursor: pointer;
                        height: 42px;
                        margin: 0 0 2px 0;
                        display: flex;
                        align-items: center;
                        user-select: none;

                        .dialog__item--checkbox {
                            padding: 0 4px 0 8px;
                            pointer-events: none;
                            user-select: none;
                        }

                        .dialog__item--image {
                            display: flex;
                            align-items: center;
                            user-select: none;

                            & img {
                            height: 32px;
                            width: 32px;
                            object-fit: cover;
                            object-position: center;
                            }
                        }

                        .dialog__item--name {
                            height: 100%;
                            display: flex;
                            align-items: center;
                            line-height: 1;
                            margin-left: 10px;
                            user-select: none;
                        }
                    }
                }
            `}
            </style>
        </>
    );
};

UserList.displayName = 'UserList';

export default UserList;
