let jsonCallListeners = [];

export const dispatchEventListeners = (action, data) => {
    let wasDispatched = false;

    jsonCallListeners.forEach((listener) => {
        if (listener.action === action) {
            if (!listener.srcFrame) {
                const namespaces = listener.callback.split('.');
                let func = window;
                for (let i = 1, l = namespaces.length; i < l; i += 1) {
                    if (typeof func !== 'undefined') {
                        func = func[namespaces[i]];
                    }
                }
                if (func && typeof func === 'function') {
                    func({ retVal: data });
                }
            } else if (listener.srcFrame && listener.srcFrame.contentWindow) {
                listener.srcFrame.contentWindow.postMessage(`chayns.customTab.jsoncall:${JSON.stringify({ retVal: data, callback: listener.callback })}`, '*');
            }
            wasDispatched = true;
        }
    });

    return wasDispatched;
};

export const removeJsonCallListener = (action, srcFrame) => {
    jsonCallListeners = jsonCallListeners.filter((listener) => listener.action !== action || listener.srcFrame !== srcFrame);
};

export const getJsonCallListener = (action, srcFrame) => jsonCallListeners.find((listener) => listener.action === action && listener.srcFrame === srcFrame);

export const removeAllJsonCallListener = () => {
    jsonCallListeners = [];
};

export const addJsonCallListener = (action, callback, srcFrame) => {
    removeJsonCallListener(action, srcFrame);
    jsonCallListeners.push({ action, callback, srcFrame });
};
