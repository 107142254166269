import { v4 as uuidV4 } from 'uuid';
import { IS_DAVID_WINDOWS, DAVID_VERSION_WINDOWS } from '../../utils/environment';

import RESULT_STATUS from '../../constants/native-calls-status';
import logger from "../../constants/logger";

const callbacks = {};
let id = 0;

/**
 *  Executes JSON NativeCalls
 * @param config
 */
export default async function executeCall(config) {
    if (typeof config !== 'object' || config === null) {
        return false;
    }

    const {
        action,
        data,
        parameter,
        callback: {
            func,
            executeOnlyOnce,
        },
        fallback,
    } = config;

    if (typeof window.external !== 'object' || typeof window.external.jsonCall !== 'function') {
        if (typeof fallback === 'function') {
            func({
                parameter: parameter || {},
                data: fallback(data) || {},
                status: {
                    code: RESULT_STATUS.FALLBACK,
                },
            });
        } else {
            func({
                parameter: parameter || {},
                data: {},
                status: {
                    code: RESULT_STATUS.NOT_AVAILABLE,
                },
            });
        }
        return false;
    }

    const callId = (IS_DAVID_WINDOWS && DAVID_VERSION_WINDOWS < 6853) ? id : uuidV4();
    id += 1;

    const callConfig = {
        action,
        value: {
            callback: 'window.nativeCallCallback',
            id: callId,
            parameter,
            data,
        },
    };
    callbacks[callId] = {
        func,
        executeOnlyOnce,
        fallback,
        callData: data,
    };

    window.external.jsonCall(JSON.stringify(callConfig));
    return true;
}

export function callbackHandler(result) {
    try {
        const { id: callId, parameter, data, status } = result;

        const callback = callbacks[callId];
        if (callback !== undefined) {
            const { func, executeOnlyOnce, fallback, callData } = callback;

            if (result.status.code === RESULT_STATUS.NOT_AVAILABLE && typeof fallback === 'function') {
                func({
                    parameter: parameter || {},
                    data: fallback(callData) || {},
                    status: {
                        code: RESULT_STATUS.FALLBACK,
                    },
                });
            } else {
                func({
                    parameter: parameter || {},
                    data: data || {},
                    status: status || {},
                });
            }

            if (executeOnlyOnce) {
                delete callbacks[callId];
            }
        } else {
            logger().error({
                message: 'no json NativeCalls callback found',
                customNumber: callId,
                fileName: 'json-native-calls',
                section: 'callbackHandler',
            });
        }
    } catch (e) {
        logger().error({
            fileName: 'json-native-calls',
            section: 'callbackHandler',
            ex: {
                message: e.message,
                stackTrace: e.stack,
            },
        });
    }
}
