import { decodeTobitAccessToken } from '../../../utils/convert';
import { setCookie } from '../../../utils/helper';

export default function setTobitAccessToken(tobitAccessToken) {
    if (tobitAccessToken === '') {
        setCookie('chayns_at_378', '', -1);
        setCookie('chayns_rt_378', '', -1);
        setCookie('chayns_guarded_at', '', -1);
        setCookie('incognito', '', -1);
    } else {
        const { exp, type } = decodeTobitAccessToken(tobitAccessToken);
        if (type !== 4) {
            setCookie('chayns_at_378', tobitAccessToken, new Date(exp).getTime() * 1000);
        } else {
            setCookie('chayns_rt_378', tobitAccessToken, new Date(exp).getTime() * 1000);
        }
    }
}
