/* eslint-disable global-require */
import cloneDeep from 'lodash.clonedeep';
import { useEffect, useCallback, useRef } from 'react';
import { handleLoadIntercom, handleLoadThreadById, setIntercomThreads, setReadIntercomThread, setUpdatedIntercomThread } from '../../redux-modules/intercom/actions';
import { SelectedItem } from '../../types/selectedItem';
import { Thread } from '../../types/thread';
import addOnActivateListener from '../addOnActivateListener';
import usePrevious from './usePrevious';
import { loadIntercomWebsocket } from "../../redux-modules/app/actions";
import { intercomWsClientObj } from "../../components/util-components/IntercomWebsocketComponent";

type IntercomHook = {
    dispatch: (x: unknown) => void,
    intercom: {
        threads: Array<Thread>
        lastFetch: string,
    },
    isChaynsReady: boolean,
    selectedItem: SelectedItem,
    isUserAuthenticated: boolean,
};

const useIntercom = ({
    dispatch,
    intercom,
    isChaynsReady,
    selectedItem,
    isUserAuthenticated
}: IntercomHook) : void => {
    const webSocketClient = useRef(null);
    const intercomStore = useRef(intercom);
    const selectedItemStore = useRef(selectedItem);
    const previousSelectedItem = usePrevious(selectedItem);

    const intercomLoading = useRef(false);

    useEffect(() => {
        intercomStore.current = intercom;
    }, [intercom]);

    useEffect(() => {
        selectedItemStore.current = selectedItem;
    }, [selectedItem]);

    const handleUpdateThreads = useCallback(() => {
        dispatch(handleLoadIntercom({
            lastFetch: intercomStore.current.lastFetch,
            take: (navigator.userAgent.toLowerCase().includes('mobile') && !navigator.userAgent.includes(' team/')) ? 5 : 1000
        }));
    }, [dispatch]);

    const handleAddMessage = useCallback(({ message }) => {
        const userId = typeof chaynsInfo !== 'undefined' ? window.chaynsInfo.User.ID : chayns.env.user.id;
        if (userId === message.author?.tobitId) {
            dispatch(setReadIntercomThread({
                id: message.threadId,
                selectedItem,
            }));
        } else {
            const findThread = intercomStore.current?.threads?.find((thread) => thread.id === message.threadId);
            if (findThread) {
                const updatedThread = cloneDeep(findThread);
                updatedThread.lastMessage = message;
                updatedThread.lastMessageTime = message.creationTime;
                dispatch(setUpdatedIntercomThread({
                    thread: updatedThread,
                    selectedItem,
                }));
            } else {
                setTimeout(() => {
                    dispatch(handleLoadThreadById({
                        id: message.threadId,
                    }));
                }, 10 * 1000);
            }
        }
    }, [dispatch, selectedItem]);

    const handleThreadUpdate = useCallback((thread) => {
        setTimeout(() => {
            dispatch(handleLoadThreadById({
                id: thread.id || thread.threadId,
            }));
        }, 1000);
    }, [dispatch]);

    const handleReadStatusUpdate = useCallback((message) => {
        const thread = intercomStore.current.threads?.find((t) => t.id === message.threadId);
        if (thread) {
            const updatedThread = {
                ...thread,
                members: thread.members.map((member) => {
                    if (member.tobitId === message.member.tobitId) {
                        return {
                            ...member,
                            unreadFlag: false,
                            unreadCount: 0,
                        };
                    }
                    return member;
                }),
            };
            dispatch(setUpdatedIntercomThread({
                thread: updatedThread,
                selectedItem: selectedItemStore.current,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedItem]);

    useEffect(() => {
        if (isChaynsReady && !webSocketClient.current && isUserAuthenticated) {
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            dispatch(loadIntercomWebsocket(true));
            window.addEventListener('loaded-intercom-websocket', () => {
                if (intercomLoading.current) {
                    return;
                }
                intercomLoading.current = true;
                webSocketClient.current = intercomWsClientObj.client;
                webSocketClient.current.initialize({
                    environment: 'production',
                });

                webSocketClient.current.on('addMessage', handleAddMessage);

                webSocketClient.current.on('readStatus', handleReadStatusUpdate);

                webSocketClient.current.on('unreadStatus', handleThreadUpdate);

                webSocketClient.current.on('threadUpdate', handleThreadUpdate);

                webSocketClient.current.onAuthStateChange = (message: string) => {
                    if (message === 'successful') {
                        handleUpdateThreads();
                    }
                };
            });
        }
    }, [handleAddMessage, handleReadStatusUpdate, handleThreadUpdate, handleUpdateThreads, isChaynsReady, isUserAuthenticated]);

    useEffect(() => {
        if (!isUserAuthenticated && webSocketClient.current) {
            webSocketClient.current.disconnect({ shouldReconnect: false });
            webSocketClient.current = null;
        }
    }, [isUserAuthenticated]);

    useEffect(() => {
        if (previousSelectedItem) {
            dispatch(setIntercomThreads({
                result: [],
                selectedItem,
                updateLastFetch: false,
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, selectedItem]);

    useEffect(() => {
        const cb = (isVisible: boolean) => {
            if (webSocketClient.current) {
                if (isVisible) {
                    webSocketClient.current.connect({ shouldReconnect: true });
                } else {
                    webSocketClient.current.disconnect({ shouldReconnect: false });
                }
            }
        };
        return addOnActivateListener(cb);
    }, [isChaynsReady]);
};

export default useIntercom;
