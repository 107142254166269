import TAPPIDS from '../constants/tapp-ids';
import { Tapp } from '../types/tapp';
import { electron } from './electronUtils';
import { timer } from './resizeInterval';

const setTitle = async (tapps: Array<Tapp>, isElectronClient: boolean) : Promise<void> => {
    let titleCount = 0;

    if (tapps?.length > 0) {
        tapps.forEach((tapp) => {
            if ([TAPPIDS.SMART_CLIENT, TAPPIDS.INTERCOM, TAPPIDS.MONEY, TAPPIDS.WALLET, TAPPIDS.CHAYNS_ID, TAPPIDS.SPACE].includes(tapp.id) && typeof tapp.badge === 'number' && tapp.badge > 0) {
                titleCount += tapp.badge;
            }
        });
    }

    for (let i = 0; i < 5; i += 1) {
        if (titleCount > 0) {
            document.title = `(${titleCount}) Tobit.Team`;
        } else {
            document.title = 'Tobit.Team';
        }
        // eslint-disable-next-line no-await-in-loop
        await timer(100);
    }

    if (isElectronClient) {
        electron.updateBadge(titleCount);
    }
};

export default setTitle;
