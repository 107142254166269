import React, { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveServerState } from '../../redux-modules/smart-client/selector';
import { getAppState } from '../../redux-modules/app/selector';
import { getSearchStringState } from '../../redux-modules/search/selector';
import { MOBILE_VIEW_BREAKPOINT } from '../../constants/size';
import { setSelectedItem } from '../../redux-modules/app/actions';
import OverlayElement from '../tapp/tapp-content/sites-tapp/overlay-element/OverlayElement';
import { MAIL } from '../../constants/types';
import TAPPIDS from '../../constants/tapp-ids';

const TobitTeamInfoCenter: FC = () => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        colorMode,
        width,
        messageSearchString,
        selectedItem,
        sitesBackgroundColor,
        activeTapp
    } = app;
    const activeServer = useSelector(getActiveServerState);
    const searchString = useSelector(getSearchStringState);

    const searchStringToUse = (width >= MOBILE_VIEW_BREAKPOINT) ? messageSearchString : searchString;

    const mails = !searchStringToUse ? activeServer?.emailConnection?.values : activeServer?.searchMails?.values;

    const backgroundColor = useMemo(() => {
        if (sitesBackgroundColor) {
            return sitesBackgroundColor;
        }
        if (colorMode === 'dark') {
            return 'rgb(30, 30, 30)';
        }
        return 'white';
    }, [colorMode, sitesBackgroundColor]);

    useEffect(() => {
        if (mails?.length > 0 && !selectedItem && activeTapp === TAPPIDS.INFO_CENTER) {
            dispatch(setSelectedItem({
                type: MAIL,
                id: mails[0].id,
            }));
        }
    }, [dispatch, mails, selectedItem, activeTapp]);

    return (
        <>
            <div className="overlay-wrapper">
                <OverlayElement/>
            </div>
            <style jsx>
                {`
                    .overlay-wrapper {
                        position: relative;
                        background-color: ${backgroundColor};
                        width: 100%;
                        height: 100%;
                        flex: 1;
                    }
                `}
            </style>
        </>
    );
};

export default TobitTeamInfoCenter;
