import React, { useMemo } from 'react';
import classNames from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';

import { getAppState } from '../../redux-modules/app/selector';
import { getFloatingButtons } from '../../redux-modules/components/selector';

const FloatingButton: React.FC = () => {
    const app = useSelector(getAppState);
    const {
        activeTapp,
    } = app;

    const floatingButtons = useSelector(getFloatingButtons);

    const { callback, enabled, color, icon, text }: { callback?: () => void, enabled?: boolean, color?: string, icon?: string, text?: string } = (() => floatingButtons.find((config) => (config.id === activeTapp)) || {})();

    return useMemo(() => (
        <TransitionGroup component={null}>
            {enabled ? (
                <CSSTransition
                    classNames="cw-floating-button-animation cw-floating-button-animation"
                    key="cw-floating-button-animation"
                    appear
                    timeout={400}
                >
                    <div>
                        <div
                            className={classNames('floating-button')}
                            onClick={() => {
                                if (callback) callback();
                            }}
                            style={{
                                backgroundColor: color
                            }}
                        >
                            {icon ? (
                                <span className={`fa ${icon}`}/>
                            ) : (
                                <span>{text || '!'}</span>
                            )}
                        </div>
                    </div>
                </CSSTransition>
            ) : false}
        </TransitionGroup>
    ), [enabled, color, icon, text, callback]);
};

FloatingButton.displayName = 'FloatingButton';

export default FloatingButton;
