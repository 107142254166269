import { removeKeyForTapp, setKeyForTapp } from '../../../utils/chayns-storage';

export default function setObjectForKey(req, res) {
    const value = 'value' in req ? req.value : req;
    const tappId = window.chaynsInfo.getGlobalData().AppInfo.TappSelected.Id;

    if (value.object == null) {
        removeKeyForTapp(tappId, value.key, value.accessMode);
    } else {
        setKeyForTapp(tappId, value.key, value.object, value.accessMode, value.tappIDs);
    }

    if (!res.answer) {
        const params = {
            callback: value.callback,
            addJSONParam: value.addJSONParam || {},
            retVal: undefined,
        };

        const frame = res[0];
        if (frame && typeof frame.postMessage === 'function') {
            frame.postMessage(`${res[1] || 'chayns.customTab.'}jsoncall:${JSON.stringify(params)}`, '*');
        }
        return;
    }
    res.answer();
}
