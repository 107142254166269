export default function showPictures(req, res) {
    const urls = req.urls || req.value.urls;
    let startIndex = req.startIndex || req.value.startIndex;

    if (typeof document === 'undefined') {
        return null;
    }

    if ((urls || []).length === 0) {
        return res.event(2, 'Field urls is missing.');
    }

    if (startIndex) {
        if (startIndex < 0) {
            startIndex = 0;
        } else if (startIndex > urls.length - 1) {
            startIndex = urls.length - 1;
        }
    } else {
        startIndex = 0;
    }

    window.handleChaynsCalls('imageWrapper', { urls, startIndex }, res);
    return null;
}
