import { getVideoType } from '../../../utils/helper';

export default function showVideo(req, res) {
    if (typeof document === 'undefined') {
        return;
    }

    const url = req.url || req.value.url;

    if (!url) {
        res.event(2, 'Field url is missing.');
        return;
    }

    if (getVideoType((url))) {
        window.handleChaynsCalls('videoWrapper', { url }, res);
    } else {
        res.event(2, 'Unsupported video type.');
    }
}
