import localForage from 'localforage';
import { isDEVELOPMENT, isQA, isSTAGING } from '../../../../../../../constants/environments';

export const getIsIndexedDBSupported = (): boolean => {
    try {
        return (
            'indexedDB' in window
            || 'mozIndexedDB' in window
            || 'webkitIndexedDB' in window
            || 'msIndexedDB' in window
        );
    } catch (error) {
        return false;
    }
};

const getStorageItemKey = (userId: number): string => `user_${userId}`;

const getStorageName = (): string => {
    let name = 'chayns_de_intercom';

    if (isDEVELOPMENT) {
        name += '_development';
    } else if (isQA) {
        name += '_qa';
    } else if (isSTAGING) {
        name += '_staging';
    }

    return name;
};

const config = {
    driver: localForage.INDEXEDDB,
    name: getStorageName(),
    version: 1,
};

const stores = getIsIndexedDBSupported()
    ? {
        image: localForage.createInstance({ ...config, storeName: 'image' }),
        plugin: localForage.createInstance({ ...config, storeName: 'plugin' }),
        thread: localForage.createInstance({ ...config, storeName: 'thread' }),
    }
    : null;

type StoreName = 'image' | 'plugin' | 'thread';

export const clearStorage = async (store: StoreName): Promise<void> => {
    if (stores) {
        try {
            await stores[store].clear();
        } catch (error) {
            // ignore
        }
    }
};

export const getStorageItem = async <T>(store: StoreName, key: string, userId?: number): Promise<T | null> => {
    if (stores) {
        try {
            return stores[store].getItem<T>(key || getStorageItemKey(userId));
        } catch (error) {
            // ignore
        }
    }

    return null;
};

export const setStorageItem = async <T>(
    store: StoreName,
    value: T,
    key: string,
    userId: number,
): Promise<T | null> => {
    if (stores) {
        try {
            return stores[store].setItem<T>(key || getStorageItemKey(userId), value);
        } catch (error) {
            // ignore
        }
    }

    return null;
};
