import { ANSWER_ALL_MAIL, ANSWER_MAIL, EDIT_MAIL, FORWARD_MAIL } from '../../constants/types';

const getType = (type: string): string => {
    if (type === ANSWER_MAIL) {
        return 'reply';
    }
    if (type === ANSWER_ALL_MAIL) {
        return 'reply_all';
    }
    if (type === EDIT_MAIL) {
        return 'edit';
    }
    if (type === FORWARD_MAIL) {
        return 'forward';
    }
    return '';
};

export default getType;
