import TAPPIDS from './tapp-ids';

const ROUTES = {
    tappRoutes: {
        '': TAPPIDS.INFO_CENTER,
        money: TAPPIDS.MONEY,
        chat: TAPPIDS.INTERCOM,
        id: TAPPIDS.CHAYNS_ID,
        david: TAPPIDS.SMART_CLIENT,
        calendar: TAPPIDS.CALENDAR,
        space: TAPPIDS.SPACE,
        cards: TAPPIDS.WALLET,
    },
    actionRoutes: {
        scan: 'setShowScanner',
        find: 'setShowScanner',
    },
};

export default ROUTES;
