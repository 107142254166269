import { addApiListener, dispatchApiEvent, removeApiListener } from "./apiListenerHelper";

let invokeCallCounter = 0;
const invokeAppCall = (call) => {
    call = JSON.stringify(call);
    if (window.webkit?.messageHandlers?.jsonCall) {
        window.webkit.messageHandlers.jsonCall.postMessage(call);
    } else if (window.chaynsApp?.jsonCall) {
        window.chaynsApp.jsonCall(call);
    } else if (window.chaynsElectron?.jsonCall) {
        window.chaynsElectron.jsonCall(call);
    } else {
        throw new Error('jsoncall interface not found');
    }
};

export default function appCall(action: number, value: unknown = {}, { callback = undefined, awaitResult = true } = {}) {
    try {
        if (!awaitResult) {
            invokeAppCall({ action, value });
            return;
        }
        return new Promise((resolve) => {
            const callbackName = `chaynsDeV5Callback_${invokeCallCounter++}`;
            window[callbackName] = (v) => {
                if (callback) {
                    callback(v?.retVal ?? v);
                } else {
                    delete window[callbackName];
                }
                resolve(v?.retVal ?? v);
            };
            value.callback = `window.${callbackName}`;
            invokeAppCall({ action, value });
        });
    } catch{
        // ignore
    }
}

export async function addGeoLocationListener(value, callback, { isMyChaynsApp }) {
    if (isMyChaynsApp) {
        const { shouldInitialize, id } = addApiListener('requestGeoLocation', callback);

        if (shouldInitialize) {
            appCall(14, value, {
                callback: (e) => {
                    callback({
                        longitude: e.longitude,
                        latitude: e.latitude,
                        speed: e.speed || 0,
                        accuracy: e.accuracy || null,
                        code: e.code || 0,
                    });
                }
            });
        }

        return id;
    }
    if (!navigator.geolocation) return undefined;

    if (value.silent) {
        const permissionState = await navigator.permissions.query({ name: 'geolocation' });
        if (permissionState.state === 'prompt') {
            callback({
                message: 'not prompted',
                code: -1,
            });
            await new Promise((resolve) => {
                permissionState.onchange = resolve;
            });
        }
    }

    const timeout = value.timeout ?? 0;

    const { shouldInitialize, id } = addApiListener('requestGeoLocation', callback);

    if(shouldInitialize) {
        navigator.geolocation.watchPosition((pos) => {
            dispatchApiEvent('requestGeoLocation', {
                latitude: pos.coords.latitude,
                longitude: pos.coords.longitude,
                speed: pos.coords.speed || 0,
                accuracy: pos.coords.accuracy,
            })
        }, (e) => {
            dispatchApiEvent('requestGeoLocation', {
                message: e.message,
                code: e.code,
            })
        }, { timeout });
    }

    return id;
}

export async function getGeoLocation(value, { isMyChaynsApp }) {
    if(isMyChaynsApp) {
        return appCall(14, value);
    }
    if (!navigator.geolocation) return undefined;

    if (value.silent) {
        const permissionState = await navigator.permissions.query({ name: 'geolocation' });
        if (permissionState.state === 'prompt') {
            return {
                message: 'not prompted',
                code: -1,
            };
        }
    }
    const timeout = value.timeout ?? 0;
    return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition(
            (pos) => resolve({
                latitude: pos.coords.latitude,
                longitude: pos.coords.longitude,
                speed: pos.coords.speed || 0,
                accuracy: pos.coords.accuracy,
            }),
            (e) => resolve({
                message: e.message,
                code: e.code,
            }),
            { timeout }
        );
    });
}

export async function removeGeoLocationListener(value) {
    removeApiListener('requestGeoLocation', value);
}
