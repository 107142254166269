import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getActiveDavidSiteId, getActiveTapp, getAppState } from "../../../../redux-modules/app/selector";
import ApiV5Tapp from "../ApiV5Tapp/ApiV5Tapp";
import TAPPIDS from "../../../../constants/tapp-ids";
import { loadCalendarStore, setSelectedItem } from "../../../../redux-modules/app/actions";
import { CalendarSystem } from "../../../../constants/defaults";

const CalendarInfoCenter = ({ backgroundColor }) => {
    const dispatch = useDispatch();

    const app = useSelector(getAppState);
    const {
        colorMode,
    } = app;

    const activeDavidSiteId = useSelector(getActiveDavidSiteId);

    const activeTapp = useSelector(getActiveTapp);

    const dispatchSelectedItem = (data) => {
        void dispatch(setSelectedItem(data));
    }

    useEffect(() => {
        dispatch(loadCalendarStore(true));
    }, []);

    return (
        <>
            <div className="calendar-wrapper">
                <ApiV5Tapp customData={{serverId: activeDavidSiteId, infoCenterView: true, setSelectedItem: dispatchSelectedItem, activeTapp}} type="client-module" tappId={TAPPIDS.CALENDAR} system={CalendarSystem} />
            </div>
            <style jsx>
                {`
                    .calendar-wrapper {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    height: 50%;
                    border-bottom: 1px solid ${colorMode === 'dark' ? 'rgba(255, 255, 255, .15)' : 'rgba(0, 0, 0, 0.15)'};
                    background-color: ${backgroundColor};
                }
                `}
            </style>
        </>
    );
}

export default CalendarInfoCenter;
