const updateBadge = (count: number): void => {
    window.postMessage({
        action: 2,
        value: { count },
    }, '*');
};

const updateTappTitle = (name: string): void => {
    window.postMessage({
        action: 19,
        value: { name },
    }, '*');
};

const setElectronAccountSwitchListener = (callback: (personId: string) => void) => {
    window.setElectronAccountSwitchListener = callback;
    window.postMessage({
        action: 20,
        value: { callback: 'setElectronAccountSwitchListener' },
    }, '*');
};

const getElectronMainUser = (callback: (user: unknown) => void) => {
    window.getElectronMainUser = callback;
    window.postMessage({
        action: 21,
        value: { callback: 'getElectronMainUser' },
    }, '*');
};

const setIncognitoModeSwitchListener = (callback: () => void) => {
    window.setElectronIngocnitoSwitchListener = callback;
    window.postMessage({
        action: 22,
        value: { callback: 'setElectronIngocnitoSwitchListener' },
    }, '*');
};

export const electron = {
    updateBadge,
    updateTappTitle,
    setElectronAccountSwitchListener,
    getElectronMainUser,
    setIncognitoModeSwitchListener,
};
