import { User } from '../types/user';
import { getCookie } from './helper';

const parseUserData = (isGuardedAccountSelected = false): User => {
    if (typeof window !== 'undefined' && window?.chaynsInfo?.User) {
        const user = window.chaynsInfo.User;
        const mainToken = getCookie('chayns_at_378');
        const incognito = !!getCookie('incognito');

        return (
            {
                firstname: user.FirstName,
                lastname: user.LastName,
                token: user.TobitAccessToken,
                userId: user.ID,
                personId: user.PersonID,
                isGuardedAccountSelected,
                mainToken: isGuardedAccountSelected ? mainToken : null,
                incognito: incognito || false,
            }
        );
    }
    return null;
};

export default parseUserData;
