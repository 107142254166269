import logFetch from '../../../utils/logFetch';
import logger from "../../../constants/logger";

const managedAccountSwitch = async (personId: string, token: string) : Promise<{status: number, result?: { token: string, expires: string }}> => {
    try {
        const logOptions = {
            fileName: __filename,
            section: '0',
            message: 'POST switch managed account',
        };

        const requestInit = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
                personId,
            }),
        };

        const response = await logFetch('https://auth.tobit.com/v2/Token/SwitchUser', requestInit, logOptions);

        if (response.status === 200) {
            const result = await (response as Response).json();

            return {
                status: response.status,
                result,
            };
        }
        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: 'Failed to switch managed account',
            section: 'api/token/post/managedAccountSwitch.ts',
        }, ex);
        return {
            status: 500,
        };
    }
};

export { managedAccountSwitch };
