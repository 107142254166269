/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import handleSetActiveTapp from '../handleSetActiveTapp';
import { setTapps } from '../../redux-modules/tapps/actions';
import { Tapp } from '../../types/tapp';

type Props = {
    isInitialRender: boolean,
    activeTapp: number,
    dispatch: (action: any) => any,
    tapps: Array<Tapp>,
    fromPopState: React.MutableRefObject<boolean>,
    prevSmartClientUrl: React.MutableRefObject<string>,
    setPopStateRef: (value: boolean) => void,
    isMyChaynsApp: boolean,
};

const useHistory = ({
    isInitialRender,
    activeTapp,
    dispatch,
    tapps,
    fromPopState,
    prevSmartClientUrl,
    setPopStateRef,
    isMyChaynsApp,
}: Props) => {
    useEffect(() => {
        const handlePopState = (e: { state: { activeTapp: number; displayType: string }; }) => {
            if (e.state?.activeTapp && !e.state.displayType) {
                setPopStateRef(true);
                // const updatedTapps = tapps.map((t) => ({ ...t, wasLoaded: false }));
                dispatch(setTapps(tapps));

                handleSetActiveTapp(e.state.activeTapp, activeTapp, dispatch, prevSmartClientUrl, fromPopState.current, isInitialRender, isMyChaynsApp);
                setTimeout(() => {
                    setPopStateRef(false);
                }, 1);
            }
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTapp, dispatch, isInitialRender, tapps]);
};

export default useHistory;
