type Item = {
    createdDateTime?: string,
    lastMessageTime?: string,
    creationTime?: string,
    isRead?: boolean,
    id?: string,
    type?: string,
}

const mergeLists = (mails: Array<Item>, threads: Array<Item>): Array<Item> => {
    let temp = [];

    if (mails) {
        temp = [...mails.map((mail) => ({ ...mail, type: 'mail' }))];
    }

    if (threads) {
        temp = [...temp, ...threads.map((thread) => ({ ...thread, type: 'intercom' }))];
    }

    temp.sort((a, b) => {
        const timeA = a.createdDateTime || a.lastMessageTime || a.creationTime;
        const timeB = b.createdDateTime || b.lastMessageTime || a.creationTime;
        return timeB.localeCompare(timeA);
    });
    return temp;
};

export default mergeLists;
