import { useEffect, useMemo, useCallback } from 'react';
import JwtDecode from 'jwt-decode';
import { managedAccountSwitch } from '../../api/token/post/managedAccountSwitch';
import { User } from '../../types/user';
import { electron } from '../electronUtils';
import { getCookie, setCookie } from '../helper';
import { AT_COOKIE_NAME } from '../TokenHelper';

type Props = {
    isElectronClient: boolean,
    updateUserData: (isGuardedAccount: boolean) => Promise<boolean>,
    user?: User,
};

const useElectron: (props: Props) => void = ({
    isElectronClient,
    updateUserData,
    user,
}) => {
    const mainUser = useMemo(() => {
        if (user?.isGuardedAccountSelected) {
            try {
                const parseToken = JwtDecode(user.mainToken);
                return {
                    userId: parseToken.TobitUserID,
                    personId: parseToken.PersonID,
                    firstname: parseToken.FirstName,
                    lastname: parseToken.LastName,
                    token: user.mainToken,
                };
            } catch (error) {
                // ignore
            }
        }
        return user || {};
    }, [user]);

    const handleAccountSwitch = useCallback(async (personId: string, fromIncognito = false) => {
        if (!fromIncognito) {
            setCookie('incognito', '', -1);
        }

        const isMainAccount = personId === mainUser.personId;
        if (!isMainAccount) {
            const { status, result } = await managedAccountSwitch(personId, mainUser.token);
            if (status === 200) {
                const { token, expires } = result;
                document.cookie = `chayns_guarded_at=${token}; expires=${new Date(expires).toUTCString()}; path=/`;
                await updateUserData(true);
                chayns.getGlobalData();

                const event = new CustomEvent('login', {
                    detail: {
                        token,
                    },
                });
                window.dispatchEvent(event);
            }
        } else {
            document.cookie = `chayns_guarded_at=0; expires=${new Date('1970-1-1').toUTCString()}; path=/`;
            await updateUserData(false);
            chayns.getGlobalData();

            const event = new CustomEvent('login', {
                detail: {
                    token: getCookie(AT_COOKIE_NAME),
                },
            });
            window.dispatchEvent(event);
        }
        window.loggedIn(true, !isMainAccount);
    }, [mainUser.personId, mainUser.token, updateUserData]);

    useEffect(() => {
        if (isElectronClient) {
            electron.setElectronAccountSwitchListener(handleAccountSwitch);
        }
    }, [handleAccountSwitch, isElectronClient, mainUser.personId, mainUser.token, updateUserData]);

    useEffect(() => {
        if (isElectronClient) {
            electron.getElectronMainUser(() => mainUser);
        }
    }, [isElectronClient, mainUser]);

    const handleSetIncognito = useCallback(async () => {
        setCookie('incognito', 'true');
        if (mainUser.personId !== user.personId || !user.incognito) {
            await handleAccountSwitch(mainUser.personId, true);
        }
    }, [handleAccountSwitch, mainUser.personId, user?.incognito, user?.personId]);

    useEffect(() => {
        if (isElectronClient) {
            electron.setIncognitoModeSwitchListener(handleSetIncognito);
        }
    }, [handleSetIncognito, isElectronClient]);
};

export default useElectron;
