import { CRAWLER_URL } from '../../constants/defaults';
import logger from '../../constants/logger';

const linkCrawler = async (url: string) : Promise<number> => {
    try {
        const requestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${typeof chaynsInfo !== 'undefined' ? window.chaynsInfo.User.TobitAccessToken : chayns.env.user.tobitAccessToken}`,
            },
            body: JSON.stringify({
                url,
            }),
        };

        const response = await fetch(CRAWLER_URL, requestInit);

        if (response.status >= 500) {
            logger().warning({
                message: 'Failed to crawl url',
                data: {
                    url,
                    status: response.status,
                },
            });
        }

        return response.status;
    } catch (ex) {
        logger().warning({
            message: 'Failed to load badges',
            section: 'api/badges/index.js',
        }, ex);
        return 500;
    }
};

export { linkCrawler };
