import React, { useMemo, memo } from 'react';
import classNames from 'classnames';

import { useSelector } from 'react-redux';
import { getAppBottomBarHeight, getAppState } from '../../../../../../redux-modules/app/selector';
import { getEnvState } from '../../../../../../redux-modules/env/selector';
import { getDraggedSiteState, getIgnoreFocusedState, getSitesState } from '../../../../../../redux-modules/sites/selector';

const IgnoreSite: React.FC = () => {
    const app = useSelector(getAppState);
    const {
        colorMode,
    } = app;

    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
    } = env;

    const draggedSite = useSelector(getDraggedSiteState);

    const appBottomBarHeight = useSelector(getAppBottomBarHeight);

    const sites = useSelector(getSitesState);

    const ignoreFocused = useSelector(getIgnoreFocusedState);

    const ignoreSiteClassnames = classNames('ignore-site', {
        active: draggedSite,
        focus: ignoreFocused,
        desktop: !isMyChaynsApp,
        dark_mode: colorMode === 'dark',
    });

    const site = useMemo(() => sites?.values?.find((t) => t?.id === draggedSite), [draggedSite, sites]);

    return (
        <>
            <div
                className={ignoreSiteClassnames}
            >
                <i
                    className="delete-icon fa fa-times"
                />
                <img
                    className="deleting-image"
                    src={site?.id ? `https://sub60.tobit.com/l/${site.siteId}?size=114` : ''}
                    style={{
                        opacity: site?.id && ignoreFocused ? 1 : 0,
                    }}
                    onContextMenu={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                    }}
                    alt=""
                />
            </div>

            <style jsx global>
                {`
                    .ignore-site {
                        position: fixed;
                        height: 120px;
                        width: 100%;
                        display: flex !important;
                        align-items: center;
                        justify-content: center;
                        transition: all .2s;
                        transform: translate3d(0, 20px, 0);
                        opacity: 0;
                        bottom: 70px;
                        z-index: 109;
                        pointer-events: none;
                        
                        &:not(.desktop) {
                          bottom: ${30 + appBottomBarHeight}px;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            border: 2px solid rgba(0,0,0,0.5);
                            background-color: #dedede;
                            box-shadow: 0px 0px 0px 5px #dedede;
                            border-radius: 500px;
                            height: 50px;
                            width: 50px;
                            transition: all .1s;
                            z-index: -1;
                        }
                        .delete-icon {
                            color: rgba(0,0,0,0.5);
                        }

                        &.dark_mode {
                            &:after {
                                border: 2px solid rgba(255,255,255,0.5);
                                background-color: black;
                                box-shadow: 0px 0px 0px 5px black;
                            }
                            .delete-icon {
                                color: rgba(255,255,255,0.5);
                            }
                        }
                        
                        .deleting-image{
                            position: absolute;
                            width: 35px;
                            height: 35px;
                            filter: grayscale(100%);
                            transition: opacity .2s ease-in-out;
                        }

                        &.focus {
                            transform: translate3d(0,-10px,0)!important;
                            color: red!important;
                            .delete-icon {
                                color: red!important;
                            }
                            &:after {
                                border-color: red!important;
                                color: red!important;
                                height: 70px;
                                width: 70px
                            }
                        }
                        
                        &.active {
                            opacity: 1;
                            transform: translate3d(0, 0, 0);
                            pointer-events: all;
                        }
                    }
                `}
            </style>
        </>
    );
};

IgnoreSite.displayName = 'IgnoreSite';

export default memo(IgnoreSite);
