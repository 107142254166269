import { setKeyValue, getKeyValue } from '../calls/json-native-calls/calls';

export const ACCESS_MODES = {
    public: 0,
    protected: 1,
    private: 2,
};

const getStorageKey = () => `tappKeyStorage-${chaynsInfo.SiteID}`;

const read = async () => (await getKeyValue(getStorageKey()) as { data: { value: Array<unknown> }}).data.value || [];
const write = (value: unknown) => setKeyValue(getStorageKey(), value);

type Item = {
    Key?: string,
    OriginTappID?: number,
    AccessMode?: number,
    TappIDs?: Array<number>,
    Value?: unknown
};

function createItem(key: string, tappId: number, value: unknown, accessMode: number, tappIds: Array<number>) {
    const item: Item = {};
    item.Key = key;
    item.OriginTappID = tappId;
    item.AccessMode = accessMode || ACCESS_MODES.public;
    item.TappIDs = tappIds || [];
    if (item.TappIDs.indexOf(item.OriginTappID) < 0) {
        item.TappIDs.push(item.OriginTappID);
    }
    item.Value = value;
    return item;
}

function getItemFromStorage(storage: Array<Item>, tappId: number, key: string, accessMode = ACCESS_MODES.public) {
    if (storage && storage.length) {
        // eslint-disable-next-line no-restricted-syntax
        for (const item of storage) {
            if (item.Key === key && item.AccessMode === accessMode) {
                switch (item.AccessMode) {
                    case ACCESS_MODES.public:
                        return item;
                    case ACCESS_MODES.private:
                        if (item.OriginTappID === tappId) {
                            return item;
                        }
                        break;
                    case ACCESS_MODES.protected:
                        if (item.TappIDs.indexOf(tappId) >= 0) {
                            return item;
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    }
    return undefined;
}

export const removeKeyForTapp = async (tappId: number, key: string, accessMode: number): Promise<void> => {
    try {
        const storage = await read();
        const keyItem = getItemFromStorage(storage, tappId, key, accessMode);

        if (keyItem) {
            storage.splice(storage.indexOf(keyItem), 1);
            await write(storage);
        }
    } catch (e) {
        // ignore
    }
};

export const setKeyForTapp = async (tappId: number, key: string, value: unknown, accessMode: number, tappIds: Array<number>): Promise<void> => {
    try {
        const storage = await read();
        const savedItem = getItemFromStorage(storage, tappId, key, accessMode);

        if (savedItem) {
            storage.splice(storage.indexOf(savedItem), 1);
        }

        if (value) {
            storage.push(createItem(key, tappId, value, accessMode, tappIds));
        }

        await write(storage);
    } catch (e) {
        // ignore
    }
};

export const getKeyForTapp = async (tappId: number, key: string, accessMode: number): Promise<unknown> => {
    try {
        const storage = await read();
        const keyItem = getItemFromStorage(storage, tappId, key, accessMode);
        return (keyItem) ? keyItem.Value : undefined;
    } catch (e) {
        // ignore
        return null;
    }
};
