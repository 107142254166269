import React, { useMemo, memo, useEffect, useRef } from 'react';
import Accordion from '@chayns-components/Accordion';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import PageSelection from '../page-selection/PageSelection';
import PersonPlaceholder from './person-placeholder/PersonPlaceholder';
import Person from './person/Person';
import { loadSearchPersons } from '../../../../../../api/search/persons';
import { getEnvState } from '../../../../../../redux-modules/env/selector';
import { getIsSearchingPersonsState, getSearchPersonsState, getSearchStringState } from '../../../../../../redux-modules/search/selector';
import { setIsSearchingPersons, setPersonsActivePage, setPersonsPageResult } from '../../../../../../redux-modules/search/actions';
import { Person as PersonType } from '../../../../../../types/person';

type PersonWrapperTypes = {
    elementWidth: string,
};

const PersonWrapper: React.FC<PersonWrapperTypes> = ({
    elementWidth,
}) => {
    const dispatch = useDispatch();
    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
    } = env;

    const searchString = useSelector(getSearchStringState);

    const isSearchingPersons = useSelector(getIsSearchingPersonsState);
    const personsSearch = useSelector(getSearchPersonsState);
    const {
        list,
        activePage,
        count,
    } = personsSearch;

    const abortControllerRef = useRef(null);

    const elementsPerPage = Math.round(100 / parseFloat(elementWidth)) * 2;

    const personPlaceholder = useMemo(() => {
        const tempPlaceholder = [];
        for (let i = 0; i < elementsPerPage; i += 1) {
            tempPlaceholder.push(<PersonPlaceholder key={i}/>);
        }
        return tempPlaceholder;
    }, [elementsPerPage]);

    const shownValues: Array<PersonType | { type: string, key: number }> = useMemo(() => {
        const startIndex = (activePage - 1) * elementsPerPage;
        const endIndex = startIndex + (elementsPerPage);
        const sliceValues: Array<PersonType | { type: string, key: number }> = list?.slice(startIndex, endIndex) || [];
        const shownAmount = count > elementsPerPage / 2 ? elementsPerPage : elementsPerPage / 2;
        if (sliceValues.length < shownAmount) {
            const missingAmount = shownAmount - sliceValues.length;
            for (let i = 0; i < missingAmount; i += 1) {
                if (count - endIndex < 0) {
                    sliceValues.push({
                        type: 'empty',
                        key: i,
                    });
                } else {
                    sliceValues.push({
                        type: 'placeholder',
                        key: i,
                    });
                }
            }
        }
        return sliceValues;
    }, [activePage, count, elementsPerPage, list]);

    const loadPersons = () => {
        abortControllerRef.current = new AbortController();
        const startIndex = (activePage - 1) * elementsPerPage;
        loadSearchPersons(searchString, elementsPerPage, startIndex, activePage, abortControllerRef.current.signal).then((value) => {
            if (value.status === 200 && value.result) {
                dispatch(setPersonsPageResult({
                    value: {
                        values: value.result.list,
                        startIndex,
                    },
                }));
            } else {
                dispatch(setIsSearchingPersons(false));
            }
        }).catch(() => {
            dispatch(setIsSearchingPersons(false));
        });
    };

    useEffect(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        if (!list) {
            return;
        }

        if (activePage > 1 && (activePage - 1) * elementsPerPage > count) {
            dispatch(setPersonsActivePage(activePage - 1));
            return;
        }
        const startIndex = (activePage - 1) * elementsPerPage;
        const endIndex = startIndex + elementsPerPage;
        let areValuesLoaded = true;
        for (let index = startIndex; index < endIndex && index < count; index += 1) {
            const element = list[index];
            if (!element) {
                areValuesLoaded = false;
            }
        }
        if (!areValuesLoaded) {
            loadPersons();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePage, elementsPerPage]);

    if (!count && !isSearchingPersons) {
        return null;
    }

    return (
        <>
            <Accordion
                head="Personen"
                defaultOpened
            >
                <div
                    className="person_wrapper"
                    style={{
                        gridTemplateColumns: `repeat(auto-fill, minmax(${elementWidth}, 1fr))`,
                    }}
                >
                    {(shownValues.length === 0 || isSearchingPersons) && personPlaceholder}
                    {shownValues.length > 0 && !isSearchingPersons && shownValues.map((person, index) => {
                        if (person.type === 'placeholder' || person.type === 'empty') {
                            return <PersonPlaceholder key={person.key} isHidden={person.type === 'empty'}/>;
                        }
                        return (
                            <Person
                                key={person.personId}
                                personId={person.personId}
                                firstName={person.firstName}
                                lastName={person.lastName}
                                userId={person.userId}
                                isMyChaynsApp={isMyChaynsApp}
                                index={index}
                            />

                        );
                    })}
                </div>

                {!isSearchingPersons && (
                    <PageSelection
                        activePage={activePage}
                        totalPages={Math.ceil(count / elementsPerPage)}
                        onPageClick={(pageIndex) => {
                            dispatch(setPersonsActivePage(pageIndex));
                        }}
                    />
                )}
            </Accordion>
            <style jsx>
                {`
                    .person_wrapper {
                        margin: auto;
                        flex-wrap: wrap;
                        display: grid;
                        margin: auto;
                        margin: 15px 0 30px 0;
                        min-height: ${count > elementsPerPage / 2 ? '250px' : '125px'};
                    }
                `}
            </style>
        </>
    );
};

PersonWrapper.propTypes = {
    elementWidth: PropTypes.string.isRequired,
};

PersonWrapper.displayName = 'PersonWrapper';

export default memo(PersonWrapper);
