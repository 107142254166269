import nodeCache from '../../utils/nodeCache';
import logger from '../../constants/logger';


const loadLib = async (libName: string, lang: string) : Promise<{ status: number, result?: {[key: string]: string}}> => {
    try {
        const url = `https://webapi.tobit.com/TextStringService/v1.0/LangStrings/${libName}?language=${lang}`;
        const value = nodeCache.get(url) as { status: number, result?: {[key: string]: string}};

        if (value) {
            return value;
        }

        const response = await fetch(url);

        if (response.status === 200) {
            const result = await response.json();

            nodeCache.set(url, {
                status: response.status,
                result,
            }, 60 * 15);

            return {
                status: response.status,
                result,
            };
        }
        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: `Failed to load ${libName} textStrings`,
            section: 'api/text-strings/index.js',
        }, ex);
        return {
            status: 500,
        };
    }
};

const loadTextStrings = async (libNames: Array<string>, lang: string) : Promise<{[key: string]: string}> => {
    const promises = libNames.map((libName) => loadLib(libName, lang));

    const resultSets = (await Promise.all(promises)).filter((promise) => promise.status === 200);

    if (resultSets.length === 0) {
        return null;
    }

    if (resultSets.length === 1) {
        return resultSets[0].result;
    }

    return resultSets.reduce((acc, promise) => ({ ...acc, ...promise.result }), {});
};

export { loadTextStrings };
