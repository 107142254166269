import React, { memo, useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import ApiV5Tapp from "../ApiV5Tapp/ApiV5Tapp";
import TAPPIDS from "../../../../constants/tapp-ids";
import { useDispatch, useSelector } from "react-redux";
import { getActiveDavidSiteId } from "../../../../redux-modules/app/selector";
import { CalendarSystem } from "../../../../constants/defaults";
import { loadCalendarStore } from "../../../../redux-modules/app/actions";

const propTypes = {
    isActive: PropTypes.bool.isRequired,
};

const CalendarTapp: React.FC<InferProps<typeof propTypes>> = ({
    isActive,
    tappId
}) => {
    if(!isActive) return null;

    const activeDavidSiteId = useSelector(getActiveDavidSiteId);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadCalendarStore(true));
    }, []);

    return (
            <div style={{position: 'relative', height:'100%'}}>
                <ApiV5Tapp customData={{serverId: activeDavidSiteId}} type="client-module" tappId={tappId || TAPPIDS.CALENDAR} system={CalendarSystem} />
            </div>
    );
};

CalendarTapp.propTypes = propTypes;
CalendarTapp.displayName = 'CalendarTapp';

export default memo(CalendarTapp);
