import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getEnvState } from '../../../../../../../redux-modules/env/selector';

type TabHeadTypes = {
    index: number,
    selectElements: Array<{ hasBadge: boolean, name: string }>
};

const TabHead: React.FC<TabHeadTypes> = ({
    index,
    selectElements,
}) => {
    const env = useSelector(getEnvState);
    const {
        isIOS,
    } = env;

    const {
        hasBadge,
        name,
    } = selectElements[index];

    return (
        <>
            <div className="wrapper">
                <div
                    className="tab"
                    style={{
                        letterSpacing: isIOS ? '-0.5px' : undefined,
                    }}
                >
                    {name}
                </div>
                {hasBadge && (
                    <div
                        className="red-marker"
                    />
                )}
            </div>
            <style jsx>
                {`
                  .wrapper {
                    position: relative;
                  }

                  .tab {
                    font-weight: bold;
                    cursor: pointer;
                    position: relative;
                    padding: 0 5px;
                    line-height: 1.4;
                  }

                  .red-marker {
                    position: absolute;
                    right: -4px;
                    top: 2px;
                    width: 5px;
                    height: 5px;
                    background-color: #e71e28;
                    border-radius: 50%;
                  }
                `}
            </style>
        </>
    );
};

TabHead.displayName = 'TabHead';

TabHead.propTypes = {
    index: PropTypes.number.isRequired,
};

export default memo(TabHead);
