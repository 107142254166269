/* eslint-disable prefer-destructuring */
/* eslint-disable no-bitwise */

const utf8Decode = (utftext: string): string => {
    let string = '';
    let i = 0;
    let c;
    let c1;
    let c2;

    while (i < utftext.length) {
        c = utftext.charCodeAt(i);

        if (c < 128) {
            string += String.fromCharCode(c);
            i += 1;
        } else if ((c > 191) && (c < 224)) {
            c1 = utftext.charCodeAt(i + 1);
            string += String.fromCharCode(((c & 31) << 6) | (c1 & 63));
            i += 2;
        } else {
            c1 = utftext.charCodeAt(i + 1);
            c2 = utftext.charCodeAt(i + 2);
            string += String.fromCharCode(((c & 15) << 12) | ((c1 & 63) << 6) | (c2 & 63));
            i += 3;
        }
    }
    return string;
};

export const decodeTobitAccessToken = (tobitAccessToken: string): {
    exp: number,
    PersonID: string,
    TobitUserID: number,
    FirstName: string,
    LastName: string,
    FacebookUserID?: string,
} => {
    if (tobitAccessToken && typeof (tobitAccessToken) === 'string' && tobitAccessToken.length > 0) {
        const spl = tobitAccessToken.split('.');
        if (spl.length === 3) {
            try {
                spl[1] = spl[1].slice(0, spl[1].length + (spl[1].length % 4));
                spl[1] = spl[1].replace(/-/g, '+').replace(/_/g, '/');

                return JSON.parse(utf8Decode(atob(spl[1])));
            } catch (e) {
                //
            }
        }
    }
    return null;
};
