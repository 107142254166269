import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getUserState } from '../../../../redux-modules/user/selector';
import { setActiveTapp } from '../../../../redux-modules/app/actions';
import TAPPIDS from '../../../../constants/tapp-ids';

type UserInfoTypes = {
    isDarkMode: boolean
};

const UserInfo: React.FC<UserInfoTypes> = ({
    isDarkMode,
}) => {
    const dispatch = useDispatch();
    const user = useSelector(getUserState);

    return (
        <>
            <div
                className={classNames('user-info', {
                    dark_mode: isDarkMode,
                })}
                onClick={() => {
                    dispatch(setActiveTapp(TAPPIDS.CHAYNS_ID));
                }}
            >
                <img
                    className="user-info__img"
                    src={`https://sub60.tobit.com/u/${user?.userId}?size=200`}
                    alt=""
                />
                <div
                    className="user-info__name"
                >
                    {`${user?.firstname || ''} ${user?.lastname || ''}`}
                </div>
            </div>
            <style jsx>
                {`
                .user-info {
                    position: relative;
                    display: flex;
                    height: 50px;
                    background-color: #e2e2e2;
                    cursor: default;

                    &__img {
                        height: 70%;
                        align-self: center;
                        border-radius: 25px;
                        margin-left: 7px;
                    }

                    &__name {
                        align-self: center;
                        margin-left: 8px;
                        font-weight: 700;
                        font-size: 14px;
                    }

                    &.dark_mode {
                        background-color: grey;
                    }
                }
            `}
            </style>
        </>
    );
};

UserInfo.displayName = 'UserInfo';

UserInfo.propTypes = {
    isDarkMode: PropTypes.bool.isRequired,
};

UserInfo.defaultProps = {

};

export default UserInfo;
