/* eslint-disable react/no-unstable-nested-components */
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { useDispatch, useSelector } from 'react-redux';

import { SITES } from '../../../../../../../constants/types';
import calcItemWidth from '../../../../../../../utils/calcItemWidth';
import { sortSites } from '../../../../../../../api/sites/patch/sortSites';
import SitesTappContent from '../../../sites-tapp-content/SitesTappContent';
import { getAppState } from '../../../../../../../redux-modules/app/selector';
import { getEnvState } from '../../../../../../../redux-modules/env/selector';
import { getTextStringState } from '../../../../../../../redux-modules/text-strings/selector';
import { getDraggedSiteState, getGlobalLocationsState, getIgnoreFocusedState, getIsSitesLoading, getSitesState } from '../../../../../../../redux-modules/sites/selector';
import { handleDeleteSite, setDraggedSite, setIgnoreFocused, setSites } from '../../../../../../../redux-modules/sites/actions';
import { getIsSearchingPagesState, getIsSearchingPersonsState, getIsSearchingSitesState, getSearchPagesState, getSearchPersonsState, getSearchSitesState, getSearchStringState } from '../../../../../../../redux-modules/search/selector';
import { Location } from '../../../../../../../types/location';

type TabBodyTypes = {
    index: number,
    selectElements: Array<{
        type: string,
    }>,
    noMargin: boolean,
    isSelected?: boolean,
};

const TabBody: React.FC<TabBodyTypes> = ({
    index,
    selectElements,
    noMargin,
}) => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        width,
        isClient,
        isScannerShown,
        isMenuShown,
        hideOtherContent,
    } = app;

    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
        isFirefox,
        isMobileBrowser,
    } = env;

    const textStrings = useSelector(getTextStringState);

    const draggedSite = useSelector(getDraggedSiteState);

    const globalLocations = useSelector(getGlobalLocationsState);

    const sites = useSelector(getSitesState);
    const isLoadingSites = useSelector(getIsSitesLoading);

    const ignoreFocused = useSelector(getIgnoreFocusedState);
    const searchString = useSelector(getSearchStringState);

    const pagesSearch = useSelector(getSearchPagesState);
    const isSearchingPages = useSelector(getIsSearchingPagesState);

    const isSearchingSites = useSelector(getIsSearchingSitesState);
    const sitesSearch = useSelector(getSearchSitesState);

    const isSearchingPersons = useSelector(getIsSearchingPersonsState);
    const personsSearch = useSelector(getSearchPersonsState);

    const {
        type: selectedContentType,
    } = selectElements[index];

    const willShowSuggestions = (sites?.values?.filter((s) => !s?.isHidden).length < 20 || !sites?.values) && globalLocations && !isLoadingSites;

    const locations: Array<Location> = useMemo(() => {
        const cloneSites = cloneDeep(sites?.values || []);
        return cloneSites.filter((site) => !site?.isHidden);
    }, [sites?.values]);

    const suggestions = useMemo(() => {
        if (willShowSuggestions) {
            return globalLocations.filter((site) => !sites?.values?.find((s) => s?.siteId === site.siteId));
        }
        return null;
    }, [globalLocations, sites?.values, willShowSuggestions]);

    const handleSetIgnoredFocus = useCallback((value) => {
        dispatch(setIgnoreFocused(value));
    }, [dispatch]);

    const handleSetDraggedSite = useCallback((value) => {
        dispatch(setDraggedSite(value));
    }, [dispatch]);

    const sortableRef = useRef(null);

    useEffect(() => {
        sortableRef.current = document.querySelector('.sortable-drag');
        if (draggedSite && sortableRef.current) {
            if (ignoreFocused) {
                sortableRef.current.style.opacity = 0;
            } else {
                sortableRef.current.style.opacity = 0.8;
            }
        }
    }, [ignoreFocused, draggedSite]);

    const siteWidth = useMemo(() => calcItemWidth(isMyChaynsApp, width, hideOtherContent), [isMyChaynsApp, width, hideOtherContent]);

    const onEnd = useCallback(async ({
        oldIndex,
        newIndex,
    }) => {
        if (isMyChaynsApp && typeof chayns !== 'undefined') {
            chayns.allowRefreshScroll();
        }

        const usedOldIndex = oldIndex;
        const usedNewIndex = newIndex;

        if (draggedSite && ignoreFocused) {
            const copyLocations = locations.slice();
            copyLocations.splice(usedOldIndex, 1);
            dispatch(setSites({
                ...sites,
                values: copyLocations,
            }));
            handleSetDraggedSite(null);
            handleSetIgnoredFocus(false);

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { payload } = await dispatch(handleDeleteSite({
                id: locations[usedOldIndex]?.id,
                isMyChaynsApp,
                sites: copyLocations,
            }));

            if (!payload) {
                dispatch(setSites(sites));
                chayns.dialog.toast({
                    description: 'Beim Verbergen der Site ist ein Fehler aufgetreten. Offline?',
                    type: 4,
                });
            }
        } else {
            handleSetDraggedSite(null);
            handleSetIgnoredFocus(false);
            const copyLocations = cloneDeep(sites.values);
            copyLocations.splice(usedNewIndex, 0, copyLocations.splice(usedOldIndex, 1)[0]);

            let moveAfter;
            let moveBefore;
            if (usedNewIndex === 0) {
                moveBefore = copyLocations[1].id;
            } else {
                moveAfter = copyLocations[usedNewIndex - 1].id;
            }

            dispatch(setSites({
                ...sites,
                values: copyLocations,
            }));

            const { status } = await sortSites({
                id: copyLocations[usedNewIndex].id,
                moveBefore,
                moveAfter,
            });

            if (status === 500) {
                chayns.dialog.toast({
                    description: 'Deine Änderungen konnten nicht gespeichert werden. Offline?',
                    type: 4,
                });
                dispatch(setSites(sites));
            }
        }
    }, [dispatch, draggedSite, handleSetDraggedSite, handleSetIgnoredFocus, ignoreFocused, isMyChaynsApp, locations, sites]);

    const noResults = searchString && !isSearchingSites && !isSearchingPages && !isSearchingPersons && !pagesSearch.total && !sitesSearch.total && !personsSearch.count;

    const wrapperRef = useRef(null);

    const marginTop = useMemo(() => {
        if (noMargin) {
            return 0;
        }
        if (selectedContentType === SITES) {
            return 20;
        }
        return 8;
    }, [selectedContentType, noMargin]);

    return (
        <div
            ref={wrapperRef}
            style={{ marginTop }}
        >
            {selectedContentType === SITES && (
                <div>
                    <SitesTappContent
                        isClient={isClient}
                        searchString={searchString}
                        firefox={isFirefox}
                        locations={locations}
                        onEnd={onEnd}
                        onChoose={({ oldIndex }) => {
                            const siteId = locations?.[oldIndex]?.id;
                            if (draggedSite !== siteId) {
                                handleSetDraggedSite(siteId);
                            }
                            if (isMyChaynsApp && typeof chayns !== 'undefined') {
                                chayns.disallowRefreshScroll();
                                chayns.vibrate([20, 0, 0], 5);
                            }
                        }}
                        onUnchoose={() => {
                            if (ignoreFocused || !draggedSite) {
                                return;
                            }
                            handleSetDraggedSite(null);
                            handleSetIgnoredFocus(false);
                            if (isMyChaynsApp && typeof chayns !== 'undefined') {
                                chayns.allowRefreshScroll();
                                chayns.vibrate([20, 0, 0], 5);
                            }
                        }}
                        draggedSite={draggedSite}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        onMove={(e) => !e.related.classList.contains('create_site_wrapper')}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        list={() => {}}
                        width={width}
                        isScannerShown={isScannerShown}
                        isMenuShown={isMenuShown}
                        myChaynsApp={isMyChaynsApp}
                        mobileBrowser={isMobileBrowser}
                        siteWidth={siteWidth}
                        noResults={noResults}
                        textStrings={textStrings}
                        suggestions={willShowSuggestions && suggestions}
                        searchError={false}
                    />
                </div>
            )}
        </div>
    );
};

TabBody.displayName = 'TabBody';

TabBody.defaultProps = {
    isSelected: false,
};

export default memo(TabBody);
