import React, { memo, useState } from 'react';
import Button from '@chayns-components/Button';
import Icon from '@chayns-components/Icon';
import Checkbox from '@chayns-components/Checkbox';

import { useDispatch, useSelector } from 'react-redux';
import UserList from '../shared/UserList';
import { getTextStringState } from '../../../../redux-modules/text-strings/selector';
import { setDialog } from '../../../../redux-modules/dialog/actions';
import { getActiveServerState } from '../../../../redux-modules/smart-client/selector';
import { handleDistribute } from '../../../../redux-modules/smart-client/actions';
import { Mail } from '../../../../types/smartClient';

type DistributeTypes = {
    mail: Mail
};

const Distribute: React.FC<DistributeTypes> = ({
    mail,
}) => {
    const dispatch = useDispatch();
    const textStrings = useSelector(getTextStringState);
    const server = useSelector(getActiveServerState);

    const [createCopy, setcreateCopy] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [userIds, setuserIds] = useState([]);

    return (
        <>
            <div
                className="api-dialog__content chayns__background-color--000"
            >
                <div
                    className="api-dialog__body scrollbar"
                    style={{
                        maxHeight: chayns.env.isMobile || chayns.env.isTablet ? '400px' : '100%',
                    }}
                >
                    <div
                        className="dialog_search"
                    >
                        <input
                            className="input"
                            placeholder={textStrings?.['txt_chayns_de_search'] || 'Original verschieben'}
                            onChange={({ target }) => {
                                setSearchValue(target.value.toLowerCase());
                            }}
                        />
                        <Icon
                            icon="far fa-search"
                            className="search_icon"
                        />
                    </div>
                    <div
                        className="dialog_cc"
                    >
                        <Checkbox
                            onChange={setcreateCopy}
                        >
                            {textStrings?.['txt_chayns_de_mail_move_original'] || 'Original verschieben'}
                        </Checkbox>
                    </div>
                    <UserList
                        allUser={server.users}
                        setuserIds={setuserIds}
                        searchValue={searchValue}
                        userIds={userIds}
                        endpoint={server?.endpoint}
                    />
                </div>
                <div
                    className="api-dialog__footer"
                >
                    <div
                        className="api-dialog__footer-row"
                    >
                        <Button
                            onClick={() => {
                                dispatch(handleDistribute({
                                    id: mail.id,
                                    userIds,
                                    createCopy: !createCopy,
                                }));
                                dispatch(setDialog({
                                    data: null,
                                    dialogType: null,
                                }));
                            }}
                        >
                            {textStrings?.['txt_chayns_de_dialog_ok'] || 'OK'}
                        </Button>
                        <Button
                            onClick={() => {
                                dispatch(setDialog({
                                    data: null,
                                    dialogType: null,
                                }));
                            }}
                        >
                            {textStrings?.['txt_chayns_de_dialog_cancel'] || 'Abbrechen'}
                        </Button>
                    </div>
                </div>
            </div>
            <style jsx global>
                {`
                    .dialog_search {
                        position: relative;

                        .input {
                            width: 100%;
                        }

                        .search_icon {
                            position: absolute;
                            right: 0;
                            top: 5px;
                            opacity: 0.6;
                        }
                    }

                    .dialog_cc {
                        padding: 15px 0;
                    }
                `}
            </style>
        </>
    );
};

Distribute.propTypes = {
};

Distribute.defaultProps = {

};

Distribute.displayName = 'Distribute';

export default memo(Distribute);
