import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { getTextStringState } from '../../../../../redux-modules/text-strings/selector';

const NoResults: React.FC = () => {
    const textStrings = useSelector(getTextStringState);

    return (
        <p
            className="chayns__color--text"
            style={{
                textAlign: 'center',
            }}
        >
            {textStrings?.['txt_chayns_de_no_results'] || 'Keine Ergebnisse'}
        </p>
    );
};

NoResults.displayName = 'NoResults';

export default memo(NoResults);
