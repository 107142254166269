import logFetch from '../../../utils/logFetch';
import logger from "../../../constants/logger";

const removeLoginHistory = async (deviceId: string, token: string) : Promise<{status: number, result?: unknown}> => {
    try {
        const logOptions = {
            fileName: __filename,
            section: '0',
            message: 'POST remove login history',
        };

        const requestInit = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
                token,
                removeFromDevice: deviceId,
            }),
        };

        const response = await logFetch('https://auth.tobit.com/v2/invalidToken', requestInit, logOptions);

        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: 'Failed to remove login history',
            section: 'api/token/post/removeLoginHistory.ts',
        }, ex);
        return {
            status: 500,
        };
    }
};

export { removeLoginHistory };
