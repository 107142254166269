import { login, logout, showLogin } from '../../../utils/login';
import { decodeTobitAccessToken } from '../../../utils/convert';
import { setCookie } from '../../../utils/helper';
import { renewAT } from '../../../utils/TokenHelper';

export async function setTobitAccessToken(req, res) {
    if (req.value && 'tobitAccessToken' in req.value) {
        const { exp, type } = decodeTobitAccessToken(req.value.tobitAccessToken);

        if(type === 12){
            if(typeof window.tobitLoginOverlayRes !== 'undefined') {
                window.tobitLoginOverlayRes.answer({
                    token: req.value.tobitAccessToken,
                    tobitAccessToken: req.value.tobitAccessToken,
                    loginState: req.value.tobitAccessToken ? 3: 2
                });
                window.openLoginOverlay(false);
            }
        } else {
            if (type !== 4) {
                setCookie('chayns_at_378', req.value.tobitAccessToken, new Date(exp).getTime() * 1000);
            } else {
                setCookie('chayns_rt_378', req.value.tobitAccessToken, new Date(exp).getTime() * 1000);
                await renewAT();
            }
            login(false, req.value.teamLogin);
            res.answer();
        }

    }
}

export function tobitLogin(req, res) {
    window.tobitLoginOverlayRes = res;
    showLogin(req?.value?.urlParams?.includes("ignoreAuthenticated") || req?.value?.confirmLogin);
}

export function tobitLogout() {
    logout();
}
