import { setColorMode } from '../redux-modules/app/actions';

const handleSetColorMode = (isDark: boolean, dispatch: ({ payload: string }) => void, isMyChaynsApp: boolean): void => {
    if (!isMyChaynsApp) {
        const newColorMode = isDark ? 1 : 0;
        window.chaynsInfo.ColorMode = newColorMode;
        if (typeof chayns !== 'undefined') {
            window.globalData.AppInfo.colorMode = newColorMode;
            chayns.env.site.colorMode = newColorMode;
            chayns.getGlobalData();
        }
        if (typeof window.dialog !== 'undefined') {
            window.dialog.createColors();
        }
        const ts = Date.now() + (60 * 1000 * 60 * 24 * 30);
        const expires = new Date(ts).toUTCString();
        document.cookie = `colormode=${newColorMode}; expires=${expires}; SameSite=Lax`;

        if (typeof window !== 'undefined' && typeof window.updateColorMode === 'function') {
            window.updateColorMode(newColorMode);
        }
    }
    dispatch(setColorMode(isDark ? 'dark' : 'light'));
};

export default handleSetColorMode;
