import React from 'react';

const LoginCompleted = () => {
    return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
<div style={{textAlign: 'center'}}>

        <div dangerouslySetInnerHTML={{__html: `<div style="text-align: center"><svg xmlns="http://www.w3.org/2000/svg" width="72px" height="72px">
            <g fill="none" stroke="#04ae00" stroke-width="3">
                <circle cx="36" cy="36" r="34" style="stroke-dasharray:240px, 240px; stroke-dashoffset: 480px; animation: checkmark-circle 0.6s ease-in-out backwards;"></circle>
                <path d="M17.417,37.778l9.93,9.909l25.444-25.393" style="stroke-dasharray:50px, 50px; stroke-dashoffset: 0px; animation: checkmark 0.25s ease-in-out 0.7s backwards;"></path>
            </g>
        </svg></div>`}}/>

    <h2><b>Authentifizierung abgeschlossen</b></h2>
    <p>Tobit.Team ist mit Deiner chayns.ID ({chayns.env.user.firstName} {chayns.env.user.lastName})</p>
    <p>verbunden. Du kannst dieses Fenster jetzt schließen.</p>
</div>
    </div>
}

export default LoginCompleted;
