/* eslint-disable no-param-reassign */
import TAPPIDS from '../../constants/tapp-ids';
import { SelectTappConfig } from '../../types/chayns';
import VERSION from '../../constants/version';
import logger from '../../constants/logger';

export const getTappById = ((tappId: number): SelectTappConfig => {
    if (typeof window.chaynsInfo === 'undefined') {
        return null;
    }
    return window.chaynsInfo.Tapps.find((tapp) => tapp.id === tappId) || null;
});

export function setUrlParams(url: string, tappId: number): string {
    if ((typeof window === 'undefined') || !window.chaynsInfo) return '';

    try {
        url = url.replace(/##.*?##/g, '');
        if (tappId === TAPPIDS.LANDING_PAGE) {
            url = url.replace(/colormode=[0-9]/ig, `colormode=${window.chaynsInfo.ColorMode}`);
        }

        const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

        const urlObject = new URL(url);
        const urlParams = new URLSearchParams({
            OS: 'chaynsnet-runtime',
            siteId: window.chaynsInfo.SiteID,
            color: window.chaynsInfo.Color.replace('#', ''),
            colormode: isDarkMode ? '1' : `${window.chaynsInfo.ColorMode || 0}`,
            tobituserid: window.chaynsInfo?.User?.ID.toString(),
            TappID: tappId.toString(),
            AppVersion: VERSION,
        });

        if (window.chaynsInfo.User !== undefined && window.chaynsInfo?.User?.ID > 0) {
            urlParams.append('firstname', window.chaynsInfo.User.FirstName);
            urlParams.append('lastname', window.chaynsInfo.User.LastName);
        }

        urlParams.forEach((value: string, key: string) => {
            urlObject.searchParams.append(key, value);
        });

        const parametersFromUrl = new URLSearchParams(window.location.search);

        for (const parameter of parametersFromUrl.entries()) {
            urlObject.searchParams.append(parameter[0], parameter[1]);
        }

        return urlObject.href;
    } catch (error) {
        logger().warning({
            message: 'setUrlParams crash',
            section: 'custom-tapp.ts',
            data: {
                error,
            },
        }, error);
        return '';
    }
}
