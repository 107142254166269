import { useEffect, useState } from 'react';
import { login, logout } from '../login';

type UseTabCommunication = {
    isMyChaynsApp: boolean
}

const useTabCommunication = ({
    isMyChaynsApp,
}: UseTabCommunication): void => {
    const [tabCommunication, setTabCommunication] = useState(null);
    const [useLocaleStorage, setUseLocalStorage] = useState(false);

    useEffect(() => {
        if (!isMyChaynsApp) {
            try {
                setTabCommunication(new BroadcastChannel('tobit-team-runtime-broadcast'));
            } catch (error) {
                setUseLocalStorage(true);
            }
        }
    }, [isMyChaynsApp]);

    useEffect(() => {
        if (tabCommunication) {
            tabCommunication.onmessage = ({ data }) => {
                if (data === 'logout') {
                    logout(true);
                } else if (data === 'login') {
                    login(true);
                }
            };
        }
    }, [tabCommunication]);

    useEffect(() => {
        const handleLogout = () => {
            if (tabCommunication) {
                tabCommunication.postMessage('logout');
            } else if (useLocaleStorage) {
                localStorage.setItem('message', 'logout');
                localStorage.removeItem('message');
            }
        };

        window.addEventListener('logout', handleLogout);
        return () => {
            window.removeEventListener('logout', handleLogout);
        };
    }, [tabCommunication, useLocaleStorage]);

    useEffect(() => {
        const handleLogin = () => {
            if (tabCommunication) {
                tabCommunication.postMessage('login');
            } else if (useLocaleStorage) {
                localStorage.setItem('message', 'login');
                localStorage.removeItem('message');
            }
        };

        window.addEventListener('login', handleLogin);
        return () => {
            window.removeEventListener('login', handleLogin);
        };
    }, [tabCommunication, useLocaleStorage]);

    useEffect(() => {
        const handleStorage = (e) => {
            if (e.key === 'message') {
                if (e.newValue === 'logout') {
                    logout(true);
                } else if (e.newValue === 'login') {
                    login(true);
                }
            }
        };

        if (useLocaleStorage) {
            window.addEventListener('storage', handleStorage);
        }

        return () => {
            window.removeEventListener('storage', handleStorage);
        };
    }, [useLocaleStorage]);
};

export default useTabCommunication;
