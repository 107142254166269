import React, { useMemo, memo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Menu from './menu/Menu';
import QRScanner from './qr-scanner/QRScanner';
import TappBtn from './tappBtn/TappBtn';
import SmartClientBar from './smart-client-bar/SmartClientBar';

import TAPPIDS from '../../constants/tapp-ids';
import { getAppState } from '../../redux-modules/app/selector';
import { setIsMenuShown, setIsScannerShown } from '../../redux-modules/app/actions';
import { getTextStringState } from '../../redux-modules/text-strings/selector';
import { getTappsState } from '../../redux-modules/tapps/selector';
import { getIsCalendarShown } from '../../redux-modules/smart-client/selector';
import { getEnvState } from '../../redux-modules/env/selector';
import {CUSTOM_HOVER_COLOR_STYLE} from "../../constants/colors";
import TobitTeamLogo from "../util-components/TobitTeamLogo";

type BottomBarProps = {
  scannerCallback: () => void
  handleLoadTapp: (tappId: number) => void,
};

const BottomBar: React.FC<BottomBarProps> = ({
    scannerCallback,
    handleLoadTapp,
}) => {
    const dispatch = useDispatch();

    const env = useSelector(getEnvState);
    const {
        isIOS,
    } = env;

    const app = useSelector(getAppState);
    const {
        activeTapp,
        colorMode,
        width,
        isClient,
        isUserAuthenticated,
        isMenuShown,
        isScannerShown,
        selectedItem,
    } = app;

    const textStrings = useSelector(getTextStringState);

    const tapps = useSelector(getTappsState);

    const isCalendarShown = useSelector(getIsCalendarShown);

    const usedTapps = useMemo(() => tapps.filter((tapp) => {
        const tappIds = [TAPPIDS.SMART_CLIENT, TAPPIDS.INTERCOM, TAPPIDS.MONEY, TAPPIDS.WALLET, TAPPIDS.CHAYNS_ID, TAPPIDS.CALENDAR, TAPPIDS.SPACE, TAPPIDS.CHAYNS_SITES];

        if (!isCalendarShown) {
            tappIds.splice(tappIds.indexOf(TAPPIDS.CALENDAR), 1);
        }

        return tappIds.includes(tapp.id);
    }), [isCalendarShown, tapps]);

    const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);

    const {
        activeColor,
        iconColor,
    } = useMemo(() => {
        if (isDarkMode) {
            return {
                activeColor: 'white',
                iconColor: '#A8A8A8',
            };
        }
        return {
            activeColor: 'var(--chayns-color--primary)',
            iconColor: 'grey',
        };
    }, [isDarkMode]);

    const {
        tappsRendered,
        isMenuRendered,
        isActiveTappInMenu,
    } = useMemo(() => {
        const tappsFittingOnScreen = Math.floor(width / 75);
        const getRenderedTapps = Math.min(tappsFittingOnScreen, usedTapps.length + 1);
        const getIsMenuRendered = tappsFittingOnScreen < usedTapps.length + 1;
        const getIsActiveTappInMenu = getIsMenuRendered && usedTapps.findIndex((tapp) => tapp.tappId === activeTapp) >= tappsRendered - 1;

        return {
            tappsRendered: getRenderedTapps,
            isMenuRendered: getIsMenuRendered,
            isActiveTappInMenu: getIsActiveTappInMenu,
        };
    }, [width, usedTapps, activeTapp]);

    const chaynsIconOpacity = useMemo(() => {
        if (activeTapp === TAPPIDS.INFO_CENTER && !selectedItem?.type) {
            return '1';
        }
        if (isDarkMode) {
            return '.6';
        }
        return '.75';
    }, [activeTapp, isDarkMode, selectedItem?.type]);

    const chaynsIconStyles = useMemo(() => ({
        color: activeTapp === TAPPIDS.INFO_CENTER && !selectedItem?.type ? activeColor : iconColor,
        width: `${100 / (tappsRendered)}%`,
    }), [activeTapp, selectedItem?.type, activeColor, iconColor, tappsRendered]);

    return (
        <>
            <div className="bottom-bar">
                {isUserAuthenticated && (
                    <>
                        <Menu
                            show={isMenuShown}
                            tapps={usedTapps.slice(tappsRendered - 1, usedTapps.length)}
                            handleLoadTapp={handleLoadTapp}
                            isDarkMode={isDarkMode}
                            isSmartClientActive={activeTapp === TAPPIDS.SMART_CLIENT}
                            isIOS={isIOS}
                        />
                        <QRScanner
                            show={isScannerShown}
                            isDarkMode={isDarkMode}
                            setShowScanner={() => {
                                dispatch(setIsScannerShown(false));
                            }}
                            callback={scannerCallback}
                            isClient={isClient}
                        />
                    </>
                )}

                <div
                    className={classNames('bar', {
                        dark_mode: isDarkMode,
                        user_authenticated: isUserAuthenticated,
                        smart_client_active: activeTapp === TAPPIDS.SMART_CLIENT,
                    })}
                >
                    <SmartClientBar
                        active={activeTapp === TAPPIDS.SMART_CLIENT}
                        iconColor={iconColor}
                        isDarkMode={isDarkMode}
                        activeColor={activeColor}
                        tappsRendered={tappsRendered}
                    />

                    <div
                        className={classNames('bar__tapp-wrapper', {
                            dark_mode: isDarkMode,
                            sites_tapp_active: activeTapp === TAPPIDS.INFO_CENTER && !selectedItem?.type,
                        })}
                        style={{
                            pointerEvents: isUserAuthenticated ? 'auto' : 'none',
                        }}
                    >
                        <div
                            className="bar__sites-icon"
                            onClick={() => {
                                if (typeof window !== 'undefined') {
                                    history.replaceState(null, '', '/');
                                    handleLoadTapp(TAPPIDS.INFO_CENTER);
                                }
                            }}
                            style={chaynsIconStyles}
                        >
                            <div className="chayns_icon svg_container">
                                <TobitTeamLogo />
                            </div>
                        </div>
                        {usedTapps.map((t, i) => {
                            if (i + 1 < tappsRendered) {
                                return (
                                    <TappBtn
                                        key={t.tappId}
                                        tapp={t}
                                        handleTappClick={() => handleLoadTapp(t.tappId)}
                                        active={activeTapp === t.tappId}
                                        badge={t.badge}
                                        style={{
                                            width: `${100 / (tappsRendered)}%`,
                                        }}
                                        activeColor={activeColor}
                                        isDarkMode={isDarkMode}
                                        iconColor={iconColor}
                                        isSmartClientActive={activeTapp === TAPPIDS.SMART_CLIENT}
                                    />

                                );
                            }
                            return null;
                        })}
                        {isMenuRendered && (
                            <div
                                className="bar__more"
                                onClick={() => {
                                    dispatch(setIsMenuShown(!isMenuShown));
                                }}
                                style={{
                                    color: isActiveTappInMenu ? activeColor : iconColor,
                                    width: `${100 / (tappsRendered)}%`,
                                }}
                            >
                                <i
                                    className={classNames('fas fa-ellipsis-h bar__more__icon', {
                                        smart_client_active: activeTapp === TAPPIDS.SMART_CLIENT,
                                    })}
                                />
                                <div
                                    className={classNames('bar__more__name', {
                                        visible: activeTapp !== TAPPIDS.SMART_CLIENT,
                                    })}
                                >
                                    {textStrings?.['txt_chayns_de_more'] || 'Mehr'}
                                </div>
                            </div>

                        )}
                    </div>
                </div>
            </div>
            <style jsx>
                {`
                  .bar {
                    z-index: 99;
                    bottom: 0;
                    right: -6px;
                    left: 0;
                    font-weight: 400;
                    color: #A0A0A0;
                    transition: all .3s;
                    box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
                    background-color: white;
                    max-height: 0;
                    height: 100%;

                    .bar__tapp-wrapper {
                      justify-content: center;
                    }

                    &.user_authenticated {
                      max-height: 70px;

                      &.smart_client_active {
                        max-height: 90px;

                        .bar__tapp-wrapper {
                          height: 35%;
                        }

                        .bar__sites-icon {
                          padding-top: 0;

                          .chayns_icon {
                            max-width: 18px;
                            max-height: 18px;
                            margin-bottom: 5px;
                          }
                        }
                      }
                    }
                      
                    .svg_container {
                        display: flex;
                        height: 100%;
                    }

                    &.dark_mode {
                      box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, .15);
                      background-color: #1E1E1E;

                      .bar__more:hover {
                        background-color: rgba(255, 255, 255, .1) !important;
                      }

                      .bar__sites-icon:hover {
                        background-color: rgba(255, 255, 255, .1) !important;
                      }
                    }

                    .chayns_icon {
                      width: 36px;
                      height: 36px;
                      transition: all .3s;

                      &.hidden {
                        opacity: 0;
                      }
                    }

                    &__scan-and-find {
                      position: absolute;
                      width: 100%;
                      cursor: pointer;
                      transition: opacity 0.3s;
                      text-align: center;
                      vertical-align: middle;
                      justify-content: center;
                      bottom: 70px;
                      background-color: white;
                      color: grey;
                      height: 0;
                      transform: translateY(200px);
                      opacity: 0;
                      border-bottom: none;

                      .bar__icon {
                        background-color: grey;
                        height: 5px;
                        width: 50px;
                        margin: auto;
                        border-radius: 100px;
                        margin-bottom: 10px;
                      }

                      &.user_authenticated {
                        transform: translateY(0px);

                        &.menues_closed {
                          height: 25px;
                          opacity: 1;
                          border: 1px solid lightgray;
                        }
                      }

                      &__search__wrapper {
                        position: absolute;
                        margin: auto;
                        left: 0;
                        right: 0;
                        bottom: 0px;
                        background-color: white;
                        transition: opacity 0.3s;
                        transform-origin: bottom;
                      }

                      &__search {
                        margin-top: 9px;
                        height: 26px;
                        fill: grey;
                        padding-left: 2px;
                      }
                    }

                    &__more {
                      position: relative;
                      text-align: center;
                      max-width: 95px;
                      cursor: pointer;
                      padding-top: 5px;
                      transition: all .3s;

                      &:hover {
                        background-color: ${CUSTOM_HOVER_COLOR_STYLE};
                      }

                      &__icon {
                        margin-top: 13px;
                        position: relative;
                        font-size: 25px;

                        &.smart_client_active {
                          margin-top: 0;
                          font-size: 18px;
                        }
                      }

                      &__name {
                        position: absolute;
                        margin: auto;
                        left: 0;
                        right: 0;
                        font-size: 13px;
                        bottom: 5px;
                        opacity: 0;
                        transition: all .6s;

                        &.visible {
                          opacity: 1;
                        }
                      }
                    }

                    &__sites-icon {
                      text-align: center;
                      max-width: 95px;
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      &:hover {
                      // Keep chayns background color for now
                        background-color: rgba(var(--chayns-color-rgb--primary), 0.2);
                      }
                    }

                    &__context-menu {
                      margin-left: 15px;
                    }

                    &__button {
                      position: absolute;
                      right: 20px;
                      top: 15px;
                    }

                    &__tapp-wrapper {
                      display: flex;
                      transition: all .3s;
                      height: 100%;
                      width: 100%;

                      &__btn {
                        margin-right: 5px;
                      }
                    }
                  }
                  .bottom-bar {
                    min-height: 70px;
                    flex-shrink: 0;
                  }
                `}
            </style>
        </>
    );
};

BottomBar.propTypes = {
    scannerCallback: PropTypes.func,
    handleLoadTapp: PropTypes.func.isRequired,
};

BottomBar.defaultProps = {
    scannerCallback: null,
};

BottomBar.displayName = 'BottomBar';

export default memo(BottomBar);
