import React, { FC } from 'react';

const TobitTeamLogo: FC = () => (
    <svg
        id="Ebene_1"
        data-name="Ebene 1"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        viewBox="0 0 498.62 454.77"
        className="tobit-team-logo"
    >
        <path
            className="cls-1"
            d="M303.67,269.4c1.88,0,3.73.08,5.58.2l-23.15,40.1h-73.9s-23.18-40.14-23.18-40.14c1.64-.09,3.29-.15,4.95-.15h109.7Z"
        />
        <path
            className="cls-1"
            d="M149.26,354.41v15.36c0,24.65,20.06,44.71,44.71,44.71h109.7c24.65,0,44.71-20.06,44.71-44.71v-15.36c0-5.75-1.1-11.25-3.09-16.3l22.68-39.27c12.9,14.9,20.71,34.32,20.71,55.58v15.36c0,46.95-38.06,85.01-85.01,85.01h-109.7c-46.95,0-85.01-38.06-85.01-85.01v-15.36c0-21.47,7.98-41.07,21.11-56.03l22.55,39.05c-2.16,5.24-3.36,10.97-3.36,16.98Z"
        />
        <path
            className="cls-1"
            d="M456.1,253.64l-13.3,7.68c-18.52,10.69-39.38,13.6-58.83,9.78l22.6-39.15c5.51-.77,10.97-2.59,16.08-5.54l13.3-7.68c21.35-12.33,28.69-39.72,16.36-61.07l-54.85-95c-12.33-21.35-39.72-28.69-61.07-16.36l-13.3,7.68c-5.1,2.94-9.39,6.75-12.81,11.12h-45.22c6.42-18.74,19.37-35.34,37.88-46.02l13.3-7.68c40.66-23.47,92.65-9.54,116.12,31.11l54.85,95c23.47,40.66,9.54,92.65-31.11,116.12Z"
        />
        <path
            className="cls-1"
            d="M329.43,234.64c-.95-1.44-1.88-2.91-2.76-4.43l-54.85-95c-.88-1.53-1.69-3.08-2.47-4.64h46.33l36.92,63.94-23.17,40.12Z"
        />
        <path
            className="cls-1"
            d="M175.53,53.98l-13.3-7.68c-21.35-12.33-48.75-4.99-61.07,16.36l-54.85,95c-12.33,21.35-4.99,48.75,16.36,61.07l13.3,7.68c5,2.89,10.33,4.68,15.73,5.48l22.66,39.25c-19.37,3.73-40.12.8-58.54-9.84l-13.3-7.68C1.86,230.17-12.07,178.18,11.4,137.52l54.85-95C89.73,1.86,141.72-12.07,182.37,11.4l13.3,7.68c18.51,10.68,31.45,27.28,37.88,46.02h-45.22c-3.42-4.37-7.71-8.18-12.81-11.12Z"
        />
        <path
            className="cls-1"
            d="M171.94,230.21c-.93,1.61-1.92,3.16-2.93,4.68l-23.15-40.1,37.08-64.22h46.33c-.78,1.56-1.59,3.11-2.47,4.64l-54.85,95Z"
        />
        <path
            className="cls-1"
            d="M79.24,185.18c-5.58-9.66-2.28-22,7.36-27.57,3.17-1.83,6.63-2.7,10.05-2.7,6.97,0,13.76,3.63,17.51,10.11l107.97,187.01c5.58,9.66,2.28,22-7.36,27.57-3.17,1.83-6.63,2.7-10.05,2.7-6.97,0-13.76-3.63-17.51-10.11l-107.97-187.01Z"
        />
        <path
            className="cls-1"
            d="M311.09,372.19c-3.74,6.48-10.53,10.11-17.51,10.11-3.42,0-6.88-.87-10.05-2.7-9.64-5.57-12.94-17.91-7.36-27.57l107.97-187.01c3.74-6.48,10.53-10.11,17.51-10.11,3.42,0,6.88.87,10.05,2.7,9.64,5.57,12.94,17.91,7.36,27.57l-107.97,187.01Z"
        />
        <path
            className="cls-1"
            d="M356.79,77.68c11.15,0,20.19,9.02,20.19,20.16s-9.04,20.16-20.19,20.16h-215.94c-11.15,0-20.19-9.02-20.19-20.16s9.04-20.16,20.19-20.16h215.94Z"
        />
    </svg>
);

export default TobitTeamLogo;
