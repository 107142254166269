/* eslint-disable react/no-array-index-key */
import Icon from '@chayns-components/Icon';
import { motion, PanInfo } from 'framer-motion';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

interface MenuItem {
    backgroundColor: string;
    callback: (isDirectExecuted?: boolean) => unknown;
    icon: string;
    color: string;
    text: string;
}

interface MenuItems {
    left: MenuItem[];
    right: MenuItem[];
}

type GestureMenuWrapperProps = {
    children: JSX.Element;
    menuItems: MenuItems;
};

const ITEM_WIDTH_TO_HEIGHT_RATIO = 1.25;

const getMenuItemElement = (
    { backgroundColor, callback, icon, color, text }: MenuItem,
    {
        fullWidth,
        directExecutionWidth,
        isDragActive,
        itemCount,
        itemIndex,
        onClick,
        optimalItemWidth,
        side,
    }: GetMenuItemElementOptions,
): JSX.Element => {
    const itemPositionMultiplier = side === 'left' ? itemCount - itemIndex - 1 : itemIndex;

    let contentPosition = '0%';
    let contentTransform = 'translateX(0%)';
    let itemPosition = (fullWidth / itemCount) * itemPositionMultiplier;
    let itemWidth = fullWidth / itemCount;

    if (
        fullWidth > directExecutionWidth
        && ((side === 'left' && itemIndex === 0) || (side === 'right' && itemIndex === itemCount - 1))
    ) {
        contentPosition = '50%';
        contentTransform = `translateX(${side === 'left' ? '-' : ''}50%)`;
        itemPosition = 0;
        itemWidth = fullWidth;
    }

    let itemTransition;

    if (isDragActive) {
        itemTransition = { type: 'tween', duration: 0 };
    }

    const otherSide = side === 'right' ? 'left' : 'right';

    return (
        <motion.div
            animate={{ [otherSide]: itemPosition, width: Math.max(itemWidth, optimalItemWidth) }}
            className={`gesture-menu-wrapper__${side}-items-wrapper__item`}
            key={`gesture-menu_${side}_${itemIndex}`}
            onClick={() => onClick(callback)}
            style={{ backgroundColor }}
            transition={itemTransition}
        >
            <motion.div
                animate={{ [side]: contentPosition, transform: contentTransform }}
                className={`gesture-menu-wrapper__${side}-items-wrapper__item__content`}
                transition={{ type: 'spring', duration: 0.25 }}
            >
                <Icon icon={icon} style={{ color }}/>
                <div
                    className={`gesture-menu-wrapper__${side}-items-wrapper__item__content__text`}
                    style={{ color }}
                >
                    {text}
                </div>
            </motion.div>
        </motion.div>
    );
};

const GestureMenuWrapper: FC<GestureMenuWrapperProps> = ({ children, menuItems }) => {
    const [dragOffset, setDragOffset] = useState(0);
    const [dragPosition, setDragPosition] = useState(0);
    const [isDragActive, setIsDragActive] = useState(false);
    const [shouldRenderItems, setShouldRenderItems] = useState(false);

    const menuWrapperRef = useRef<HTMLDivElement>(null);

    const renderTimeoutRef = useRef(null);

    const handleReset = useCallback(() => {
        setDragPosition(0);

        clearTimeout(renderTimeoutRef.current);

        renderTimeoutRef.current = setTimeout(setShouldRenderItems, 1000, false);
    }, []);

    const handleDragStart = useCallback(() => {
        clearTimeout(renderTimeoutRef.current);

        setShouldRenderItems(true);
        setIsDragActive(true);
    }, []);

    const handleDrag = useCallback(
        (event: TouchEvent, info: PanInfo) => {
            const offsetX = info.offset.x * 0.85;
            const deltaX = info.delta.x * 0.85;

            const lastOffset = offsetX - deltaX;

            const directExecutionWidth = typeof window !== 'undefined' ? window.innerWidth * 0.55 : 0;
            const lastAbsoluteDragDistance = Math.abs(dragPosition + lastOffset);
            const currentAbsoluteDragDistance = Math.abs(dragPosition + offsetX);

            if (typeof chayns !== 'undefined' && chayns.env.isApp
                && ((lastAbsoluteDragDistance > directExecutionWidth
                    && currentAbsoluteDragDistance < directExecutionWidth)
                    || (lastAbsoluteDragDistance < directExecutionWidth
                        && currentAbsoluteDragDistance > directExecutionWidth))
            ) {
                chayns.vibrate([150], 5);
            }

            setDragOffset(offsetX);
        },
        [dragPosition],
    );

    const handleDragEnd = useCallback(
        (event: TouchEvent, info: PanInfo) => {
            setIsDragActive(false);

            let shouldResetDragPosition = false;

            if (info.point.x !== 0) {
                const offsetX = info.offset.x * 0.85;
                const dragDistance = dragPosition + offsetX;
                const wrapperHeight = menuWrapperRef.current?.clientHeight || 64;

                const directExecutionWidth = typeof window !== 'undefined' ? window.innerWidth * 0.55 : 0;
                const optimalItemWidth = wrapperHeight * ITEM_WIDTH_TO_HEIGHT_RATIO;

                const leftItemCount = menuItems.left.length;
                const rightItemCount = menuItems.right.length;

                const minLeftDragWidth = leftItemCount * (optimalItemWidth / 2);
                const minRightDragWidth = rightItemCount * (optimalItemWidth / 2) * -1;

                if (dragDistance > directExecutionWidth) {
                    // Directly execute the callback of the first left item, since over 50% was dragged to
                    // the right
                    menuItems.left[0]?.callback(true);

                    shouldResetDragPosition = true;
                } else if (dragDistance < directExecutionWidth * -1) {
                    // Directly execute the callback of the last right item, since over 50% was dragged to
                    // the left
                    menuItems.right[menuItems.right.length - 1]?.callback(true);

                    shouldResetDragPosition = true;
                } else if (offsetX > 0 && dragPosition >= 0) {
                    // Show the left menu in the optimal width
                    setDragPosition(minLeftDragWidth * 2);
                } else if (offsetX < 0 && dragPosition <= 0) {
                    // Show the right menu in the optimal width
                    setDragPosition(minRightDragWidth * 2);
                } else {
                    shouldResetDragPosition = true;
                }
            } else {
                shouldResetDragPosition = true;
            }

            setDragOffset(0);

            if (shouldResetDragPosition) {
                handleReset();
            }
        },
        [dragPosition, handleReset, menuItems.left, menuItems.right],
    );

    const handleItemClick = useCallback(
        (callback: (isDirectExecuted?: boolean) => unknown) => {
            callback(false);

            handleReset();
        },
        [handleReset],
    );

    const handleBodyTouchStart = useCallback(
        (event: TouchEvent) => {
            let isTargetInCurrentWrapper = false;

            if (menuWrapperRef.current && event.target) {
                // @ts-expect-error: Difficult to type target
                isTargetInCurrentWrapper = menuWrapperRef.current.contains(event.target);
            }

            if (!isTargetInCurrentWrapper) {
                handleReset();
            }
        },
        [handleReset],
    );

    useEffect(() => {
        if (isDragActive || dragPosition !== 0) {
            document.body.addEventListener('touchstart', handleBodyTouchStart);
        }

        return () => {
            document.body.removeEventListener('touchstart', handleBodyTouchStart);
        };
    }, [dragPosition, handleBodyTouchStart, isDragActive]);

    const leftItems = useMemo(() => {
        const wrapperHeight = menuWrapperRef.current?.clientHeight || 64;

        const optimalItemWidth = wrapperHeight * ITEM_WIDTH_TO_HEIGHT_RATIO;

        const options: GetMenuItemElementOptions = {
            fullWidth: Math.max(dragOffset + dragPosition, 0),
            directExecutionWidth: typeof window !== 'undefined' ? window.innerWidth * 0.55 : 0,
            isDragActive,
            itemCount: menuItems.left.length,
            itemIndex: 0, // Will be overwritten by the correct index within the map function
            onClick: handleItemClick,
            optimalItemWidth,
            side: 'left',
        };

        const items = menuItems.left.map((item, itemIndex) => getMenuItemElement(item, { ...options, itemIndex }));

        let wrapperTransition;

        if (isDragActive) {
            wrapperTransition = { type: 'tween', duration: 0 };
        }

        return (
            <motion.div
                animate={{ width: options.fullWidth }}
                className="gesture-menu-wrapper__left-items-wrapper"
                transition={wrapperTransition}
            >
                {items}
            </motion.div>
        );
    }, [dragOffset, dragPosition, handleItemClick, isDragActive, menuItems.left]);

    const rightItems = useMemo(() => {
        const wrapperHeight = menuWrapperRef.current?.clientHeight || 64;

        const optimalItemWidth = wrapperHeight * ITEM_WIDTH_TO_HEIGHT_RATIO;

        const options: GetMenuItemElementOptions = {
            fullWidth: Math.max((dragOffset + dragPosition) * -1, 0),
            directExecutionWidth: typeof window !== 'undefined' ? window.innerWidth * 0.55 : 0,
            isDragActive,
            itemCount: menuItems.right.length,
            itemIndex: 0, // Will be overwritten by the correct index within the map function
            onClick: handleItemClick,
            optimalItemWidth,
            side: 'right',
        };

        const items = menuItems.right.map((item, itemIndex) => getMenuItemElement(item, { ...options, itemIndex }));

        let wrapperTransition;

        if (isDragActive) {
            wrapperTransition = { type: 'tween', duration: 0 };
        }

        return (
            <motion.div
                animate={{ width: options.fullWidth }}
                className="gesture-menu-wrapper__right-items-wrapper"
                transition={wrapperTransition}
            >
                {items}
            </motion.div>
        );
    }, [dragOffset, dragPosition, handleItemClick, isDragActive, menuItems.right]);

    return useMemo(
        () => (
            <>
                <div className="gesture-menu-wrapper" ref={menuWrapperRef}>
                    {shouldRenderItems && leftItems}
                    <motion.div animate={{ translateX: dragPosition }} transition={{ duration: 0.15 }}>
                        <motion.div
                            className="gesture-menu-wrapper__content"
                            drag="x"
                            dragConstraints={{ left: 0, right: 0 }}
                            dragElastic={0.85}
                            onDrag={handleDrag}
                            onDragEnd={handleDragEnd}
                            onDragStart={handleDragStart}
                        >
                            {children}
                        </motion.div>
                    </motion.div>
                    {shouldRenderItems && rightItems}
                </div>
                <style jsx global>
                    {`
                    .gesture-menu-wrapper {
                        position: relative;
                        height: 100%;

                        &__left-items-wrapper,
                        &__right-items-wrapper {
                            white-space: nowrap;
                            position: absolute;
                            overflow: hidden;
                            height: 100%;
                            top: 0;

                            &__item {
                                position: absolute;
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                overflow: hidden;

                                &__content {
                                    padding: 8px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    flex-direction: column;
                                    position: relative;
                                    height: 100%;

                                    &__text {
                                        flex: 0 0 auto;
                                        font-size: 85%;
                                    }

                                    i {
                                        justify-content: center;
                                        align-items: center;
                                        font-size: 20px;
                                        flex: 1 1 auto;
                                        display: flex;
                                        height: 50%;
                                    }
                                }
                            }
                        }

                        &__left-items-wrapper {
                            left: 0;
                        }

                        &__right-items-wrapper {
                            right: 0;
                        }
                    }
                `}
                </style>
            </>
        ),
        [
            children,
            dragPosition,
            handleDrag,
            handleDragEnd,
            handleDragStart,
            leftItems,
            rightItems,
            shouldRenderItems,
        ],
    );
};

GestureMenuWrapper.displayName = 'GestureMenuWrapper';

export default GestureMenuWrapper;

interface GetMenuItemElementOptions {
    fullWidth: number;
    directExecutionWidth: number;
    isDragActive: boolean;
    itemCount: number;
    itemIndex: number;
    onClick: (callback: (isDirectExecuted?: boolean) => unknown) => unknown;
    optimalItemWidth: number;
    side: 'left' | 'right';
}
