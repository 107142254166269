import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TAPPIDS from '../../constants/tapp-ids';

import TappContent from './tapp-content/TappContent';
import { getAppState } from '../../redux-modules/app/selector';
import { getUserState } from '../../redux-modules/user/selector';
import { getTappsState } from '../../redux-modules/tapps/selector';
import LoginCompleted from './tapp-content/login-completed/LoginCompleted';
import Infocenter from '../team-app-infocenter/Infocenter';
import useLoadedTapps from '../../utils/hooks/useLoadedTapps';

const TappComponent: React.FC = () => {
    const app = useSelector(getAppState);
    const {
        activeTapp,
        isClient,
        isScannerShown,
        isMenuShown,
        isUserAuthenticated,
        iframePointerEvents,
    } = app;

    const user = useSelector(getUserState);

    const tapps = useSelector(getTappsState);

    const usedTapps = useLoadedTapps();

    if (activeTapp === TAPPIDS.APP_INFO_CENTER) return <Infocenter/>;
    return (
        <>
            <div className="tapp-wrapper">
                {activeTapp === TAPPIDS.LOGIN_COMPLETED ? <LoginCompleted/> : false}

                {usedTapps.map((tapp) => {
                    if ((isClient && (tapp.wasLoaded || tapp.id === TAPPIDS.LANDING_PAGE)) || (tapp.id === TAPPIDS.CALENDAR && activeTapp === tapp.id)) {
                        const url = tapp.id === TAPPIDS.INTERCOM ? tapp.source.replace('overview', 'full-view') : tapp.source;
                        return (
                            <TappContent
                                key={tapp.id}
                                isActive={tapp.id === activeTapp}
                                url={url}
                                tappId={tapp.id}
                                overlayActive={isMenuShown || isScannerShown || iframePointerEvents}
                                isTappAlreadyLoaded={!!tapps.find((openedTapp) => openedTapp.id === tapp.id)}
                                bundleUrl={tapp.bundleUrl}
                                postTobitAccessToken={user && tapp.type === 4}
                                params={tapp.params}
                                apiVersion={tapp.apiVersion}
                            />
                        );
                    }
                    return null;
                })}
                {isUserAuthenticated && (
                    <TappContent
                        key={TAPPIDS.INFO_CENTER}
                        isActive={TAPPIDS.INFO_CENTER === activeTapp}
                        tappId={TAPPIDS.INFO_CENTER}
                        overlayActive={isMenuShown || isScannerShown || iframePointerEvents}
                    />
                )}
            </div>

            <style jsx>
                {`
                .tapp-wrapper {
                    height: 100%
                }`}
            </style>
        </>
    );
};

TappComponent.displayName = 'Tapp';

export default memo(TappComponent);
