import React, { useMemo, useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import { getAppState } from '../../redux-modules/app/selector';
import { getImageWrapperState } from '../../redux-modules/components/selector';
import { setImageWrapper } from '../../redux-modules/components/actions';

const ImageWrapper: React.FC = () => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        isClient,
    } = app;

    const imageWrapper = useSelector(getImageWrapperState);

    const { urls, startIndex } = imageWrapper;

    const [currentIndex, setCurrentIndex] = useState(startIndex || 0);

    const getPreviousIndex = useCallback(() => {
        let index = currentIndex - 1;

        const imageCount = urls.length;

        if (index < 0) index += imageCount;

        return index;
    }, [currentIndex, urls]);

    const getNextIndex = useCallback(() => {
        let index = currentIndex + 1;

        const imageCount = urls.length;

        if (index > imageCount - 1) index -= imageCount;

        return index;
    }, [currentIndex, urls]);

    const handleKeydown = useCallback((event) => {
        if (!urls) return null;
        if (event.keyCode === 27) {
            setCurrentIndex(0);
            dispatch(setImageWrapper({}));
        }

        if (event.keyCode === 37) {
            setCurrentIndex(getPreviousIndex());
        }

        if (event.keyCode === 39) {
            setCurrentIndex(getNextIndex());
        }
        return null;
    }, [dispatch, getNextIndex, getPreviousIndex, urls]);

    useEffect(() => {
        if (isClient) {
            window.addEventListener('keydown', handleKeydown);
        }
        return () => window.removeEventListener('keydown', handleKeydown);
    }, [handleKeydown, isClient]);

    const renderImage = useCallback((index, show) => {
        const containerClasses = classNames('image-wrapper__image-box__image-container', {
            'image-wrapper__image-box__image-container--hide': !show,
        });

        const imgBtnPrevClasses = classNames('image-wrapper__image-box__image-container__img-button', 'image-wrapper__image-box__image-container__img-button--prev', {
            'image-wrapper__image-box__image-container__img-button--hide': urls.length < 2,
        });

        const imgBtnNextClasses = classNames('image-wrapper__image-box__image-container__img-button', 'image-wrapper__image-box__image-container__img-button--next', {
            'image-wrapper__image-box__image-container__img-button--hide': urls.length < 2,
        });

        const image = urls[index];

        return (
            <div
                id={`imageContainer_${index}`}
                className={containerClasses}
            >
                <div
                    className="image-wrapper__image-box__image-container__image"
                >
                    <div
                        className={imgBtnPrevClasses}
                        onClick={() => {
                            setCurrentIndex(getPreviousIndex());
                        }}
                    />
                    <img
                        className="image-wrapper__image-box__image-container__image_picture"
                        src={image}
                        alt=""
                    />
                    <div
                        className={imgBtnNextClasses}
                        onClick={() => {
                            setCurrentIndex(getNextIndex());
                        }}
                    />
                </div>
            </div>
        );
    }, [getNextIndex, getPreviousIndex, urls]);

    return useMemo(() => {
        if (!urls) return null;
        return (
            <>
                <div
                    className="image-shadow"
                    onClick={() => {
                        setCurrentIndex(0);
                        dispatch(setImageWrapper({}));
                    }}
                />
                <div
                    className="image-wrapper"
                    onClick={(event) => {
                        if ((event.target as HTMLElement)?.nodeName === 'IMG' || (event.target as HTMLElement).classList.contains('image-wrapper__nav-button') || (event.target as HTMLElement).classList.contains('image-wrapper__nav-button__icon')) {
                            return;
                        }
                        setCurrentIndex(0);
                        dispatch(setImageWrapper({}));
                    }}
                >
                    {/* prev image icon */}
                    {urls.length > 1 && (
                        <div
                            className="image-wrapper__nav-button image-wrapper__nav-button--prev"
                            onClick={() => {
                                setCurrentIndex(getPreviousIndex());
                            }}
                        >
                            <i
                                className="fa fa-chevron-left image-wrapper__nav-button__icon image-wrapper__nav-button__icon--prev"
                            />
                        </div>
                    )}

                    <div
                        className="image-wrapper__image-box"
                    >
                        {/* render prev image */}
                        {urls.length > 1 && (
                            renderImage(getPreviousIndex(), false)
                        )}

                        {/* render current image */}
                        {renderImage(currentIndex, true)}

                        {/* render next image */}
                        {urls.length > 1 && (
                            renderImage(getNextIndex(), false)
                        )}
                    </div>

                    {/* next image icon */}
                    {urls.length > 1 && (
                        <div
                            className="image-wrapper__nav-button image-wrapper__nav-button--next"
                            onClick={() => {
                                setCurrentIndex(getNextIndex());
                            }}
                        >
                            <i
                                className="fa fa-chevron-right image-wrapper__nav-button__icon image-wrapper__nav-button__icon--next"
                            />
                        </div>
                    )}
                </div>
            </>
        );
    }, [urls, renderImage, getPreviousIndex, currentIndex, getNextIndex, dispatch]);
};

ImageWrapper.displayName = 'ImageWrapper';

export default ImageWrapper;
