import React, { Component, Suspense } from 'react';
import classNames from 'classnames';

import ScannerAnimation from './scanner-animation/ScannerAnimation';
import ErrorBoundary from '../../error-boundary/ErrorBoundary';
import logger from "../../../constants/logger";

let QrReader = null;
if (global.window) {
    QrReader = require('react-qr-reader');
}

type QrScannerProps = {
    show: boolean,
    setShowScanner: (value: boolean) => void,
    callback: (result: string) => void,
    isClient: boolean,
    isDarkMode: boolean,
};

type QrScannerState = {
    searchActive: boolean,
    camAvailable: boolean,
};

class QRScanner extends Component<QrScannerProps, QrScannerState> {
    qrReader;

    constructor(props: QrScannerProps) {
        super(props);

        this.state = {
            searchActive: false,
            camAvailable: true,
        };
    }

    shouldComponentUpdate(nextProps: QrScannerProps): boolean {
        const { show } = this.props;
        if (!show && nextProps.show) {
            this.setState({
                camAvailable: true,
            });
        } else if (show && !nextProps.show) {
            this.stopMediaTracks();
        }

        return true;
    }

    componentDidUpdate(prevProps: QrScannerProps): void {
        const { show } = this.props;
        if (prevProps.show !== show) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ searchActive: false });
        }
    }

    onScan = (result: string): void => {
        const { setShowScanner, callback } = this.props;
        if (result) {
            if (callback) {
                callback(result);
            } else if (typeof window.qrCodeScannerChaynsCallback === 'function') {
                window.qrCodeScannerChaynsCallback({ retVal: { qrCode: result } });
            }
            setShowScanner(false);
        }
    };

    handleScannerError = (error: string): void => {
        const { isClient } = this.props;
        if (isClient) {
            logger().info({
                message: 'handleScannerError',
                data: {
                    error,
                },
            });
        }
        const { callback } = this.props;

        if (callback) {
            callback(error);
        }
        this.setState({
            camAvailable: false,
        });
    };

    stopMediaTracks = (): void => {
        if (this.qrReader && this.qrReader.stopCamera) {
            this.qrReader.stopCamera();
        }

        if (this.qrReader && this.qrReader.clearComponent) {
            this.qrReader.clearComponent();
        }

        const videoElements = document.querySelectorAll('video');
        if (videoElements.length > 1) {
            const videoElemenSrcObject = document.querySelectorAll('video')[1].srcObject;
            if (videoElemenSrcObject) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                videoElemenSrcObject.getTracks().forEach((t) => t.stop());
                document.querySelectorAll('video')[1].srcObject = null;
            }
        }
    };

    render(): JSX.Element {
        const { show, isClient, isDarkMode, center } = this.props;
        const { searchActive, camAvailable } = this.state;

        return (
            <ErrorBoundary
                section="qrScanner"
            >
                <div
                    className={classNames('qr-scanner', {
                        dark_mode: isDarkMode,
                        center
                    })}
                    style={{ transform: show ? 'translateY(0px)' : 'translateY(570px)' }}
                >
                    {
                        show && (
                            <div className="qr-scanner__wrapper">
                                {!searchActive && !camAvailable && (
                                    <div
                                        className={classNames('qr-scanner__wrapper__no-cam-info', {
                                            dark_mode: isDarkMode,
                                        })}
                                    >
                                        <i
                                            className="fa fa-webcam-slash qr-scanner__wrapper__no-cam-info__icon"
                                        />
                                        <div className="qr-scanner__wrapper__no-cam-info__text">
                                            Um einen QR-Code zu scannen, aktiviere Deine Kamera.
                                        </div>
                                    </div>
                                )}
                                {isClient && !searchActive && camAvailable && (
                                    <Suspense fallback={<div/>}>
                                        <QrReader
                                            // eslint-disable-next-line no-return-assign
                                            ref={(ref) => this.qrReader = ref}
                                            className="qr-scanner__wrapper__qr-reader"
                                            onScan={this.onScan}
                                            delay={1000}
                                            resolution={600}
                                            showViewFinder={false}
                                            onError={this.handleScannerError}
                                            style={{ width: '500px', opacity: searchActive ? '0' : '1' }}
                                            constraints={{ facingMode: { ideal: 'environment' } }}
                                        />
                                    </Suspense>
                                )}
                                {!searchActive && camAvailable && (
                                    <ScannerAnimation/>
                                )}
                            </div>
                        )
                    }
                </div>

                <style jsx>
                    {`
                    .qr-scanner {
                        z-index: 30;
                        position: fixed;
                        overflow: hidden;
                        font-weight: 400;
                        bottom: 70px;
                        margin: auto;
                        max-width: 412px;
                        height: 500px;
                        max-height: 80vh;
                        background-color: white;
                        left: 0;
                        right: 0;
                        transition: transform 0.3s ease-in;
                        max-height: 50vh;
                        
                        &.center {
                          bottom: 0;
                          top: 0;
                        }

                        &.dark_mode {
                            background-color: #1E1E1E;
                        }

                        &__iframe {
                            width: 100%;
                            max-height: 500px;
                        }

                        &__wrapper {
                            &__no-cam-info {
                                position: absolute;
                                top: 50%;
                                padding: 0 18%;
                                right: 0;
                                left: 0;
                                text-align: center;

                                &.dark_mode {
                                    color: white;
                                }

                                    &__icon {
                                        color: grey;
                                        font-size: 30px;
                                        padding-bottom: 10px;
                                    }
                            }

                            &__qr-reader {
                                transform: translate(-45px);
                                transition: opacity 0.3s ease-in;
                            }

                            &__crosshair {
                                width: 100px;
                                height: 100px;
                                position: absolute;
                                top: 200px;
                                margin: auto;
                                left: 0;
                                right: 0;
                                z-index: 1;

                                &:before, &:after {
                                    content: "";
                                    position: absolute;
                                    z-index: -1;
                                    background: white;
                                }

                                &:before {
                                    left: 50%;
                                    width: 2%;
                                    top: 35%;
                                    height: 30%;;
                                }

                                &:after {
                                    top: 50%;
                                    height: 2%;
                                    width: 30%;
                                    left: 35px;
                                }

                                &__top, &__right, &__bottom, &__left {
                                    position: absolute;
                                    margin: auto;
                                    left: 0;
                                    right: 0;
                                    z-index: 1;
                                    background-color: white;
                                }

                                &__top, &__bottom {
                                    width: 2px;
                                    height: 20px;
                                }

                                &__left, &__right {
                                    width: 20px;
                                    height: 2px;
                                }

                                &__top {
                                    width: 2px;
                                    height: 30px;
                                    left: 2px;
                                    top: -10px;
                                }

                                &__right {
                                    width: 30px;
                                    height: 2px;
                                    top: 50px;
                                    left: 80px;
                                }

                                &__bottom {
                                    width: 2px;
                                    height: 30px;
                                    left: 2px;
                                    top: 80px;
                                }

                                &__left {
                                    width: 30px;
                                    height: 2px;
                                    top: 50px;
                                    left: -90px;
                                }
                                }
                            }
                        }
                    `}
                </style>
            </ErrorBoundary>
        );
    }
}

export default QRScanner;
