import React, { memo, useMemo } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import calcSearchResultWidth from '../../../../../utils/calcSearchResultWidth';

import SiteWrapper from './site-wrapper/SiteWrapper';
import PersonWrapper from './person-wrapper/PersonWrapper';
import PageWrapper from './page-wrapper/PageWrapper';
import calcItemWidth from '../../../../../utils/calcItemWidth';
import { getEnvState } from '../../../../../redux-modules/env/selector';
import { getAppState } from '../../../../../redux-modules/app/selector';

const SearchResults: React.FC = () => {
    const app = useSelector(getAppState);
    const {
        width,
        hideOtherContent,
    } = app;

    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
        isMobileBrowser,
    } = env;

    const siteWidth = useMemo(() => calcItemWidth(isMyChaynsApp, width, hideOtherContent), [isMyChaynsApp, width, hideOtherContent]);
    const pageWidth = useMemo(() => calcSearchResultWidth(isMyChaynsApp, width), [isMyChaynsApp, width]);

    return (
        <>
            <div
                className={classnames('search_result_wrapper', {
                    chayns_app: isMyChaynsApp,
                    mobile_browser: isMobileBrowser,
                    'chayns__color--texti': isMyChaynsApp,
                })}
                style={{
                    padding: isMyChaynsApp || isMobileBrowser ? '0px 10px 20px' : '15px 43px 30px',
                }}
            >
                <SiteWrapper
                    elementWidth={siteWidth}
                />

                <PersonWrapper
                    elementWidth={siteWidth}
                />

                <PageWrapper
                    elementWidth={pageWidth}
                />

                <div
                    style={{
                        height: '70px',
                    }}
                />
            </div>
            <style jsx>
                {`
                    .search_result_wrapper {
                        padding-top: 15px;
                        max-width: 1000px;
                        display: flex;
                        flex-direction: column;
                        margin: auto;
                    }
                `}
            </style>
        </>
    );
};

export default memo(SearchResults);
