import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import Icon from '@chayns-components/Icon';

import { getTextStringState } from '../../../../../../../redux-modules/text-strings/selector';
import { getAppState } from '../../../../../../../redux-modules/app/selector';

const SelectedNewsHeader: React.FC = () => {
    const textStrings = useSelector(getTextStringState);
    const app = useSelector(getAppState);
    const {
        selectedItem,
    } = app;

    const {
        id,
        siteId,
        locationName,
    } = selectedItem;

    const shownLocationName = locationName ? `| ${locationName}` : '';
    const headerText = `${textStrings?.['txt_chayns_de_news_current'] || 'Aktuell'} ${shownLocationName}`;

    return (
        <>
            <div
                className="selected-news-header"
            >
                <h2>{headerText}</h2>
                <div
                    className="icon-bar"
                >
                    <Icon
                        icon="far fa-window-restore"
                        className="icon"
                        onClick={() => {
                            const url = `https://chayns.net/${siteId}/tapp/91958?tid=${id}`;
                            if (typeof chayns !== 'undefined') {
                                chayns.openUrlInBrowser(url, '_blank');
                            } else {
                                window.open(url);
                            }
                        }}
                    />
                </div>
            </div>
            <style jsx global>
                {`
                    .selected-news-header {
                        flex: 0 0 auto;
                        display: flex;
                        padding: 5px 10px;
                        align-items: center;
                        justify-content: space-between;

                        h2 {
                            margin: 0;
                        }

                        .icon-bar {
                            display: flex;
                            align-items: center;
                            min-height: 35px;

                            .icon {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding: 5px 0;
                                min-width: 35px;
                                cursor: pointer;
                            }

                            i {
                                font-size: 20px;
                            }
                        }
                    }
                `}
            </style>
        </>
    );
};

SelectedNewsHeader.displayName = 'SelectedNewsHeader';

export default memo(SelectedNewsHeader);
