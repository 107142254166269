/* eslint-disable jsx-a11y/iframe-has-title */
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { getAppState } from '../../../../../../../redux-modules/app/selector';

const SelectedNewsContent = () => {
    const app = useSelector(getAppState);
    const {
        selectedItem,
    } = app;

    const {
        id,
        siteId,
        tappId,
        postId,
    } = selectedItem;

    return (
        <>
            <div
                className="selected-news-content"
            >
                <iframe
                    key={id}
                    className="news-iframe"
                    frameBorder="0"
                    marginHeight={0}
                    marginWidth={0}
                    id={`TappIframe_${id}`}
                    name={`TappIframe_${id}`}
                    src={`https://chayns.net/${siteId}/tapp/${tappId || 91958}?tid=${id}&forcechaynsparent=1&isChaynsDe=1&cwAccessToken=${window.chaynsInfo.User.TobitAccessToken}&postId=${postId}`}
                />
            </div>
            <style jsx>
                {`
                    .selected-news-content {
                        display: flex;
                        flex: 1 1 auto;
                        overflow: hidden;
                        padding: 10px;

                        .news-iframe {
                            height: 100%;
                            width: 100%;
                        }
                    }
                `}
            </style>
        </>
    );
};

SelectedNewsContent.displayName = 'SelectedNewsContent';

export default memo(SelectedNewsContent);
