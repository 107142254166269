import React from 'react';
import { useSelector } from 'react-redux';
import { getUserState } from '../../../../redux-modules/user/selector';
import { QR_CODE_GENERATOR_URL } from '../../../../constants/defaults';

const ScannerAnimation: React.FC = () => {
    const user = useSelector(getUserState);

    if (!user.code) return null;

    return (
        <>
            <div
                className="scanner-animation"
            >
                <img
                    className="scanner-animation__img"
                    src={`${QR_CODE_GENERATOR_URL}svg?value=${user.code}&scannerPreview=1`}
                    alt=""
                />
            </div>
            <style jsx>
                {`
                    .scanner-animation {
                        position: absolute;
                        margin: auto;
                        inset: 0;
                        width: 200px;
                        height: 200px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &__img {
                            width: 200px;
                            height: auto;
                        }
                    }
                `}
            </style>
        </>
    );
};

ScannerAnimation.displayName = 'ScannerAnimation';

export default ScannerAnimation;
