import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { createItemDate } from '../../../../../../utils/createItemDate';
import { getTextStringState } from '../../../../../../redux-modules/text-strings/selector';

type MailItemDateTypes = {
    date: string,
};

const MailItemDate: React.FC<MailItemDateTypes> = ({
    date,
}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, setRerender] = useState({});
    const interval = useRef(null);

    const textStrings = useSelector(getTextStringState);

    useEffect(() => {
        const checkDate = (d) => d && (Date.now() - new Date(d).getTime()) / 1000 <= 3600;
        clearInterval(interval.current);
        if (checkDate(date)) {
            interval.current = setInterval(() => {
                if (checkDate(date)) {
                    setRerender({});
                } else {
                    clearInterval(interval.current);
                }
            }, 20000);
            setRerender({});
        }

        return () => clearInterval(interval.current);
    }, [date]);

    return (
        <>
            <span className="date-item">{createItemDate(date, textStrings)}</span>
            <style jsx>
                {`
                    .date-item {
                        font-size: 85%;
                        opacity: .6;
                        white-space: nowrap;
                    }
                `}
            </style>
        </>
    );
};

MailItemDate.displayName = 'MailItemDate';

export default MailItemDate;
