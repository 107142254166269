import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getTappsState } from '../../redux-modules/tapps/selector';
import TAPPIDS from '../../constants/tapp-ids';
import { isPRODUCTION } from '../../constants/environments';
import { LOGIN_TAPP_LIVE_URL, LOGIN_TAPP_STAGING_URL } from '../../constants/defaults';
import { getTappById } from '../tapp/custom-tapp';
import { setSelectedTapp } from '../chayns-info';
import { Tapp } from '../../types/tapp';
import { setTapps, updateTapp } from '../../redux-modules/tapps/actions';
import { getAppState } from '../../redux-modules/app/selector';
import { getEnvState } from '../../redux-modules/env/selector';
import { setActiveTapp } from '../../redux-modules/app/actions';

const useLoadedTapps = () => {
    const dispatch = useDispatch();

    const {
        activeTapp,
        isUserAuthenticated,
        colorMode,
        isChaynsReady,
    } = useSelector(getAppState);

    const { isMyChaynsApp } = useSelector(getEnvState);

    const { isDesktop } = useSelector(getEnvState);

    const tapps = useSelector(getTappsState);
    const usedTapps = isUserAuthenticated ? tapps?.filter((tapp) => tapp.id !== TAPPIDS.LANDING_PAGE) || [] : [{
        id: TAPPIDS.LANDING_PAGE,
        source: `${isPRODUCTION ? LOGIN_TAPP_LIVE_URL : LOGIN_TAPP_STAGING_URL}${isDesktop ? '&desktop=1' : ''}&colormode=${colorMode === 'dark' ? '1' : '2'}`,
    } as Tapp];
    useEffect(() => {
        const tapp = getTappById(activeTapp);
        if (tapp) {
            setSelectedTapp(tapp as Tapp);
        }
        if (tapp || isMyChaynsApp) {
            if (activeTapp !== TAPPIDS.INFO_CENTER) {
                const findTapp = tapps.find((t) => t.tappId === activeTapp);
                if (!findTapp?.wasLoaded) {
                    const patch = tapp ? { ...tapp, ...findTapp } : { ...findTapp };
                    patch.wasLoaded = true;
                    dispatch(updateTapp(patch));
                }
            }
        }
    }, [activeTapp, dispatch, isMyChaynsApp, tapps]);

    useEffect(() => {
        if (isChaynsReady) {
            window.setActiveTapp(activeTapp);
        }
    }, [activeTapp, isChaynsReady]);

    useEffect(() => {
        const handleSetActiveTapp = ({ detail }: CustomEvent) => {
            if (detail.tappId) {
                if (detail.params) {
                    const updatedTapps = tapps.map((tapp) => {
                        if (tapp.id === detail.tappId) {
                            return {
                                ...tapp,
                                params: detail.params,
                            };
                        }
                        return tapp;
                    });
                    dispatch(setTapps(updatedTapps));
                }
                dispatch(setActiveTapp(detail.tappId));
            }
        };

        window.addEventListener('set-active-tapp', handleSetActiveTapp);

        return () => {
            window.removeEventListener('set-active-tapp', handleSetActiveTapp);
        };
    }, [dispatch, tapps]);

    return usedTapps;
};

export default useLoadedTapps;
