import React from 'react';
import { useSelector } from "react-redux";
import { getAppState } from "../../../../redux-modules/app/selector";
import ApiV5Tapp from "../ApiV5Tapp/ApiV5Tapp";
import TAPPIDS from "../../../../constants/tapp-ids";
import { SmartClientSystem } from "../../../../constants/defaults";

const SmartClientStore = () => {
    const { loadSmartClientStore } = useSelector(getAppState);

    if(!loadSmartClientStore) return null;

    return (
        <ApiV5Tapp customData={{loadStore: true, loadBoth: false}} type="client-module" tappId={TAPPIDS.SMART_CLIENT} system={SmartClientSystem} />
    )
}

export default SmartClientStore;
