import {BAR_HEIGHT, HEADER_HEIGHT, MOBILE_VIEW_BREAKPOINT} from "../constants/size";

export function setCookie(cName: string, value: string | number, exTS?: string | Date | number): void {
    const exdate = new Date(exTS);
    const cValue = escape(`${value}`) + ((exTS === null) ? '' : `; expires=${exdate.toUTCString()};SameSite=Lax; path=/`);
    document.cookie = `${cName}=${cValue}`;
}

export function getCookie(cCame: string): string {
    let i;
    let x;
    let y;
    const ARRcookies = document.cookie.split(';');

    for (i = 0; i < ARRcookies.length; i++) {
        x = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='));
        y = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1);
        x = x.replace(/^\s+|\s+$/g, '');
        if (x === cCame) {
            return unescape(y);
        }
    }
    return null;
}

export type WindowMetrics = {
    AvailHeight: number,
    WindowScrollTop: number,
    WindowInnerHeight: number,
    pageYOffset: number,
    windowHeight: number,
    menuHeight: number,
    bottomBarHeight: number,
}

export function getWindowMetrics(): WindowMetrics {
    let availHeight;
    const tappIFrame = document.querySelector('.tapp-iframe-wrapper');

    let $headerBarHeight = document.querySelector('.header_bar')?.offsetHeight ?? document.querySelector('.top-bar-wrapper')?.offsetHeight ?? document.querySelector('.head-search')?.offsetHeight;
    $headerBarHeight = ($headerBarHeight || 0) + 2;
    const $barHeight = document.querySelector('.bottom-bar')?.offsetHeight ?? 0;

    // mobile height of tapp iframe wrapper
    if (tappIFrame?.getBoundingClientRect().height > 0) {
        availHeight = tappIFrame.getBoundingClientRect().height;
    } else {
        // desktop full height - bottom bar
        let barHeight = 0;
        if (typeof chaynsInfo !== 'undefined') {
            barHeight = $barHeight;
        }

        const headerHeight = $headerBarHeight ?? 0;
        availHeight = window.innerHeight - barHeight - headerHeight;
    }

    const menuHeight = $barHeight + $headerBarHeight;

    return {
        AvailHeight: availHeight,
        WindowScrollTop: (document.querySelector('.tobit-team-runtime-content') || document.body).scrollTop,
        WindowInnerHeight: window.innerHeight,
        pageYOffset: window.pageYOffset,
        windowHeight: window.innerHeight,
        menuHeight,
        bottomBarHeight: $barHeight,
    };
}

export const getVideoType = (url: string): string => {
    const urlParts = url.split('.');
    const type = `video/${urlParts[urlParts.length - 1]}`;

    const $checkTypePlayer = document.createElement('video');

    if ($checkTypePlayer.canPlayType(type) !== '') {
        return type;
    }
    return null;
};

export const isAppWithoutHotCard = (appVersion, userAgent): boolean => {
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return appVersion >= 6815;
    }
    return appVersion >= 7055;
};

export const isAppWithBlueHeader = (appVersion, userAgent): boolean => {
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return appVersion >= 6825;
    }
    return appVersion >= 7060;
};
