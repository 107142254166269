import { Member } from '../../../../../../../types/thread';
import { getIsExactOneLocationGiven, getIsLocationIntercom, getMembersImages, getMyMember, getOtherMembers } from './member';
import { getStorageItem, setStorageItem } from './storage';

const getImageElement = async (src: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
    const image = new Image();

    image.crossOrigin = 'anonymous';
    image.onload = () => resolve(image);
    image.onerror = () => reject(image);
    image.src = src;
});

export const generateThreadImage = async ({
    imageUrl,
    members,
    userId,
}:{
    imageUrl: string,
    userId: number,
    members: Array<Member>
}): Promise<{ imageUrl?: string, isRound?: boolean }> => {
    try {
        const shownMembers = getOtherMembers(members, userId);
        const myMember = getMyMember(members, userId);

        if (myMember) {
            shownMembers.push(myMember);
        }

        const isExactOneLocationGiven = getIsExactOneLocationGiven(shownMembers);

        let isRound = shownMembers.some(({ tobitId }) => typeof tobitId === 'number');

        let imageUrls = [];

        if (typeof imageUrl === 'string' && imageUrl.trim() !== '') {
            imageUrls = [imageUrl];
        } else if (isExactOneLocationGiven && !getIsLocationIntercom()) {
            const locationMember = shownMembers.find(({ locationId }) => typeof locationId === 'number');

            if (locationMember) {
                isRound = false;
                imageUrls = [`https://sub60.tobit.com/l/${locationMember.locationId}?size=90`];
            }
        } else {
            imageUrls = getMembersImages(shownMembers);
        }

        let cacheKey;

        const canvasSize = 128;
        const dividerWidth = 2;

        const canvas = document.createElement('canvas');
        canvas.width = canvasSize;
        canvas.height = canvasSize;

        const context = canvas.getContext('2d');

        if (!context || imageUrls.length === 0) {
            return { imageUrl: '/unknown_user.png', isRound };
        }

        if (imageUrls.length < 3) {
            const firstUrl = imageUrls[0];

            cacheKey = firstUrl;

            const cacheEntry = await getStorageItem('image', cacheKey, userId);

            if (typeof cacheEntry === 'string') {
                return { imageUrl: cacheEntry, isRound };
            }

            const imageElement = await getImageElement(firstUrl);

            context.drawImage(imageElement, 0, 0, canvasSize, canvasSize);
        } else {
            cacheKey = imageUrls.slice(0, 3).join('');

            const cacheEntry = await getStorageItem('image', cacheKey);

            if (typeof cacheEntry === 'string') {
                return { imageUrl: cacheEntry, isRound };
            }

            const [firstImageElement, secondImageElement, thirdImageElement] = await Promise.all([
                getImageElement(imageUrls[0]),
                getImageElement(imageUrls[1]),
                getImageElement(imageUrls[2]),
            ]);

            context.drawImage(
                firstImageElement,
                firstImageElement.width * 0.2,
                0,
                firstImageElement.width * 0.6,
                firstImageElement.height,
                0,
                0,
                canvasSize * 0.6,
                canvasSize,
            );

            context.drawImage(
                secondImageElement,
                secondImageElement.width * 0.1,
                0,
                secondImageElement.width * 0.8,
                secondImageElement.height,
                canvasSize * 0.6,
                0,
                canvasSize * 0.4,
                canvasSize * 0.5,
            );

            context.drawImage(
                thirdImageElement,
                secondImageElement.width * 0.1,
                0,
                secondImageElement.width * 0.8,
                secondImageElement.height,
                canvasSize * 0.6,
                canvasSize * 0.5,
                canvasSize * 0.4,
                canvasSize * 0.5,
            );

            context.clearRect(canvasSize * 0.6 - dividerWidth / 2, 0, dividerWidth, canvasSize);
            context.clearRect(
                canvasSize * 0.6,
                canvasSize * 0.5 - dividerWidth / 2,
                canvasSize * 0.4,
                dividerWidth,
            );
        }

        const dataUrl = canvas.toDataURL('image/png');

        setStorageItem('image', dataUrl, null, cacheKey);

        return { isRound, imageUrl: dataUrl };
    } catch (error) {
        console.warn(error);
        return {};
    }
};
