import React from 'react';
import Head from 'next/head';
import PropTypes, { InferProps } from 'prop-types';
import htmlescape from 'htmlescape';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { getAppState } from '../../../redux-modules/app/selector';
import { getTextStringState } from '../../../redux-modules/text-strings/selector';
import { getEnvState } from '../../../redux-modules/env/selector';
import { isDEVELOPMENT, isPRODUCTION } from '../../../constants/environments';
import ROUTES from '../../../constants/routes';
import { getMetaDataDescription, getMetaDataTitle } from '../../../utils/getMetaData';
import {PRIMARY_COLOR, SECONDARY_COLOR} from "../../../constants/colors";

const propTypes = {
    chaynsInfo: PropTypes.shape({
        AppVersion: PropTypes.number,
        Color: PropTypes.string,
    }).isRequired,
    loadCcCodesApi: PropTypes.bool,
    globalData: PropTypes.shape({
        AppInfo: PropTypes.shape({
        }),
    }),
};

const PageHeader: React.FC<InferProps<typeof propTypes>> = ({
    loadCcCodesApi,
    chaynsInfo,
    globalData,
}) => {
    const app = useSelector(getAppState);
    const {
        colorMode,
        height
    } = app;

    const textStrings = useSelector(getTextStringState);

    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
    } = env;

    const router = useRouter();

    const isTappActionCalled = !!(router.query.tappAction && router.query.ccUrl);

    const loadCcCodes = !isMyChaynsApp || isTappActionCalled || loadCcCodesApi;

    const colorNumber = colorMode === 'dark' ? 1 : 0;
    const chaynsCssUrl = `https://api${!isPRODUCTION ? '-qa' : ''}.chayns.net/css/v4.2/60021-08989/?secondarycolor=${SECONDARY_COLOR.substring(1)}&colorMode=${colorNumber}&color=${PRIMARY_COLOR.substring(1)}`;

    const selectedTapp = ROUTES.tappRoutes[router.query?.dynamic?.[0]];

    const metaDataTitle = getMetaDataTitle(selectedTapp, textStrings);
    const metaDataDescription = getMetaDataDescription(selectedTapp, textStrings);

    return (
        <Head>
            <meta charSet="UTF-8"/>
            <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
            {!isMyChaynsApp && (
                <script
                    dangerouslySetInnerHTML={{
                        __html: `window.chaynsInfo = window.ChaynsInfo = ${htmlescape(chaynsInfo || '')};`
                            + `window.globalData =${htmlescape(globalData || '')};`
                            + 'window.chaynsInfo.getGlobalData = () => window.globalData;'
                            + 'window.ChaynsInfo.getGlobalData = () => window.globalData;',
                    }}
                />
            )}
            <script
                dangerouslySetInnerHTML={{
                    __html: `window.chaynsDeLanguage = ${htmlescape((chaynsInfo || {}).language || '')};`,
                }}
            />
            {height ? <style>
                {`:root {
                    --inner-height: ${height}px;
                }`}
            </style> : ''}
            {isMyChaynsApp && (app.allowStagingSwitch || isDEVELOPMENT) && (
                <script
                    dangerouslySetInnerHTML={{
                        __html: `window.allowStagingSwitch = ${htmlescape('1' || '')};`
                        + `window.environment = ${isPRODUCTION ? '"Production"' : '"Staging"'}`,
                    }}
                />
            )}
            <link rel="search" type="application/opensearchdescription+xml" title="chayns" href="/search.xml"/>
            {loadCcCodes && (
                <script src="https://api.chayns-static.space/js/chaynsCodes/v1.0/chayns-codes-api.min.js"/>
            )}

            <script dangerouslySetInnerHTML={{
                __html: `window.addEventListener('chaynsPush', (e) => window.chaynsLastPushReceived = e)`,
            }}/>
            <meta name="viewport" content="initial-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=contain"/>
            <link rel="stylesheet" href={chaynsCssUrl} id="chaynsApiCss"/>
            <link rel="stylesheet" href="https://chayns-res.tobit.com/API/v3.1/dialog/css/dialog.css"/>
            <link rel="icon" href="/tobit_team_icon.png" type="image/png"/>
            <meta property="og:image" content="/tobit_team_icon.png"/>

            <title>Tobit.Team</title>

            <meta property="og:url" content="https://tobit.team/"/>
            <meta property="og:type" content="website"/>
            <meta name="google-site-verification" content="cmtHHCSwDfQubzFwldWEaTm-JEFXXlTT9gRAp-uoZAQ"/>

            <meta property="og:title" content={metaDataTitle}/>
            <meta property="title" content={metaDataTitle}/>
            <meta name="description" content={metaDataDescription}/>
            <meta name="og:description" content={metaDataDescription}/>
        </Head>
    );
};

PageHeader.propTypes = propTypes;

PageHeader.defaultProps = {
    loadCcCodesApi: false,
    globalData: null,
};

export default PageHeader;
