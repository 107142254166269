import React, { memo } from 'react';
import PropTypes from 'prop-types';

type ScanIconTypes = {
    icon: string,
    onClick: (e: React.SyntheticEvent) => void,
    text: string,
};

const ScanIcon: React.FC<ScanIconTypes> = ({
    icon,
    onClick,
    text,
}) => (
    <>
        <button onClick={onClick} className="button scan-icon" type="button">
            <i
                className={`${icon}`}
                style={{ pointerEvents: 'all' }}
            />
            <span>{text}</span>
        </button>
        <style jsx>
            {`
                  .scan-icon {
                    background-color: var(--chayns-color--primary) !important;
                    padding: 7px 17px;
                    height: 42px;
                    border: 1px solid var(--chayns-color--primary);
                    border-top-left-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;
                    align-items: center;
                    display: flex;
                    justify-content: center;

                    i {
                      color: white !important;
                      font-size: 1.5em;
                      line-height: 0;
                    }

                    span {
                      margin-left: ${icon ? '5px' : '0'};
                    }
                  }

                `}
        </style>
    </>
);

ScanIcon.propTypes = {
    icon: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};

ScanIcon.defaultProps = {
    icon: null,
};

ScanIcon.displayName = 'ScanIcon';

export default memo(ScanIcon);
