import { setIsChaynsReady, setIsScannerShown } from '../redux-modules/app/actions';
import logger from '../constants/logger';
import { remove } from './url-parameter';

type Params = {
    tappAction?: string,
    tappaction?: string,
    ccUrl?: string,
    ccurl?: string,
    quizUrl?: string,
    quizurl?: string
}

const handleParameters = async (params: Params, dispatch: ({ payload: boolean }) => void): Promise<void> => {
    if (params?.tappAction || params?.tappaction) {
        const tappAction = params.tappAction || params.tappaction;
        remove('tappAction');
        remove('ccUrl');
        if (tappAction === 'scan_qr') {
            dispatch(setIsScannerShown(true));
        }

        if (tappAction === 'cc' || tappAction === 'quiz') {
            const ccUrlParameter = params.ccUrl || params.ccurl || params.quizUrl || params.quizurl;
            if (ccUrlParameter && typeof window.qrCodeScannerChaynsCallback === 'function') {
                window.qrCodeScannerChaynsCallback({ retVal: { qrCode: decodeURIComponent(ccUrlParameter) } });
            }
        }
    }

    try {
        await Promise.race([chayns.ready, new Promise((_, rej) => {
            setTimeout(rej, 3000);
        })]);
    } catch (error) {
        logger().warning({
            message: 'Chayns Ready not resolving after 3s',
            section: 'handleParameters',
            customText: location.href,
            data: {
                isApp: chayns.env.isApp,
                appVersion: chayns.env.appVersion,
                isChaynsnetRuntime: chayns.env.isChaynsnetRuntime,
                isChaynsWeb: chayns.env.isChaynsWeb,
            },
        }, error);
    }

    dispatch(setIsChaynsReady(true));
};

export default handleParameters;
