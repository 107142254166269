/* eslint-disable max-len */
import React, { useMemo, memo, useState, useCallback, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { ColorSchemeProvider, SearchInput } from '@chayns-components/core';
import { getAppState } from '../../redux-modules/app/selector';
import ManagedAccountsWrapper from './managed-accounts/ManagedAccountsWrapper';
import { getTextStringState } from '../../redux-modules/text-strings/selector';
import { setSearchString } from '../../redux-modules/search/actions';
import Search from '../tapp/tapp-content/sites-tapp/search/Search';
import { getEnvState } from '../../redux-modules/env/selector';
import {getSearchStringState} from "../../redux-modules/search/selector";
import TobitTeamByDavidLogo from "../util-components/TobitTeamByDavidLogo";
import {PRIMARY_COLOR, SECONDARY_COLOR} from "../../constants/colors";
import TAPPIDS from "../../constants/tapp-ids";
import {SITE_ID} from "../../constants/site";

type HeaderProps = {
    activeSelectionName: string,
};

const HeaderBar: React.FC<HeaderProps> = ({
    activeSelectionName,
}) => {
    const app = useSelector(getAppState);
    const {
        colorMode,
        activeTapp
    } = app;

    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
        appWithBlueHeader,
        appVersion,
        isAndroid,
        isIOS,
    } = env;

    const dispatch = useDispatch();

    const searchString = useSelector(getSearchStringState);
    const [isActive, setIsActive] = useState(searchString.trim() !== '');

    const textStrings = useSelector(getTextStringState);

    const headSearchClasses = useMemo(
        () => clsx('head-search', {
            dark: true,
            isMyChaynsApp,
            appWithBlueHeader,
            'use-dark-blue-header': (parseInt(appVersion) >= 7085 && isAndroid) || (parseInt(appVersion) >= 6867 && isIOS)
        }),
        [
            colorMode, isMyChaynsApp
        ],
    );

    const handleSearchChange = useCallback(
        ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
            dispatch(setSearchString(value));
        },
        [dispatch],
    );

    const handleActiveChange = useCallback(
        (value: boolean) => {
            setIsActive(value);
            if(!value) {
                dispatch(setSearchString(''));
            }
        },
        [],
    );

    return (
        <>
            <div className={headSearchClasses}>
                <AnimatePresence initial={false}>
                    {!isActive && (
                        <motion.div
                            animate={{ opacity: 1, width: 'auto' }}
                            className="head-search__logo"
                            exit={{ opacity: 0, width: 0 }}
                            initial={{ opacity: 0, width: 0 }}
                            key="headSearchLogo"
                            transition={{ duration: 0.3 }}
                        >
                            <div className="tobit-team-logo-wrapper">
                                <TobitTeamByDavidLogo margin={17} height={40}/>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
                <div className="head-search__search">
                    { (activeTapp === TAPPIDS.CHAYNS_SITES) && ((isMyChaynsApp && appWithBlueHeader) ? (
                        <ColorSchemeProvider
                            color={PRIMARY_COLOR}
                            colorMode={1}
                            secondaryColor={SECONDARY_COLOR}
                            siteId={SITE_ID}
                        >
                            <SearchInput
                                onActiveChange={handleActiveChange}
                                onChange={handleSearchChange}
                                placeholder={textStrings.txt_chayns_de_header_search_placeholder}
                                value={searchString}
                            />
                        </ColorSchemeProvider>
                    ) : <SearchInput
                        onActiveChange={handleActiveChange}
                        onChange={handleSearchChange}
                        placeholder={textStrings.txt_chayns_de_header_search_placeholder}
                        value={searchString}
                    />)}
                </div>
                <AnimatePresence initial={false}>
                    {isActive ? (
                        <div/>
                    ) : (
                        <ManagedAccountsWrapper/>
                    )}
                </AnimatePresence>
                <div className="hidden">
                    <Search hidden/>
                </div>
            </div>

            <style jsx global>
                {`
                  .hidden {
                    display: none;
                  }
                  .head-search__logo {
                    margin-top: 9px;
                    margin-left: 10px;
                    transform: scale(1.2);
                  }

          .head-search {
            align-items: center;
            display: flex;
            height: 62px;
            flex-shrink: 0;
            
            &.appWithBlueHeader {
              color: white;
              background-color: var(--chayns-color--primary);
              
              &:not(.dark) {
                svg {
                  fill: white;
                }

                p {
                  color: white
                }

                .fa-search:not(.input__icon-left), .fa-arrow-left {
                  color: white;
                }
                
                .beta-chayns-input .fa-search {
                  color: white;
                }
              }
              
              &.dark {
                background-color: #2A2A2A;
                &.use-dark-blue-header {
                  background-color: #12416F;
                }
                color: white
              }
            }
            
            .managed_accounts {
                margin-right: 22px;
            }


            &--sticky {
              position: fixed;
              width: 100%;
              z-index: 10;
            }

            &--intercom-app-dark, &--intercom-app-light {
              .head-search__logo__headline, .react-chayns-icon {
                color: #FFFFFF !important;
              }
            }

            &--side-padding {
              padding: 0 22px;
            }

            &__logo {
              display: flex;
              flex: 0 0 auto;
              overflow: hidden;

              &__headline {
                margin: 0 !important;
              }
            }

            &__search {
              align-items: center;
              flex: 1 1 auto;
              justify-content: flex-end;
              margin-right: 16px;
              overflow: hidden;
              margin-left: 10px;
            }
          }
                `}
            </style>
        </>
    );
};

HeaderBar.displayName = 'HeaderBar';

export default memo(HeaderBar);
