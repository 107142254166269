import React, { memo, useRef, useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { getEnvState } from '../../../../../../../redux-modules/env/selector';
import { getAppState } from '../../../../../../../redux-modules/app/selector';
import { getSearchStringState } from '../../../../../../../redux-modules/search/selector';
import { getUserState } from '../../../../../../../redux-modules/user/selector';
import logger from "../../../../../../../constants/logger";

type PageProps = {
    id: number,
    name: string,
    siteId: string,
    isKnown: boolean,
    tappId: number,
    openDialog: boolean,
    domain: string,
    tappName: string,
    highlights: Array<string>,
    title: string,
    description: string,
};

const Page: React.FC<PageProps> = ({
    id,
    name,
    siteId,
    isKnown,
    tappId,
    openDialog,
    domain,
    tappName,
    highlights,
    title,
    description,
}) => {
    const user = useSelector(getUserState);
    const app = useSelector(getAppState);
    const {
        davidDialogTitleColor,
    } = app;

    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
    } = env;

    const searchString = useSelector(getSearchStringState);

    const ref = useRef(null);

    const [imageLoaded, setImageLoaded] = useState(false);

    const squareImgWrapperClassnames = classnames('infocenter__square__img-wrapper', {
        is_mobile: isMyChaynsApp,
    });

    const squareImgClassnames = classnames('infocenter__square__img');

    const squareClassnames = classnames('infocenter__square', 'fadeInUp', 'page-wrapper');

    return (
        <>
            <div
                key={id}
                className={squareClassnames}
                ref={ref}
                onClick={() => {
                    if (typeof chayns === 'undefined') {
                        return;
                    }
                    if (searchString) {
                        logger().info({
                            message: 'Click on Page',
                            section: 'search stats',
                            customText: searchString,
                            data: { siteId, tappId, name, domain },
                        });
                    }
                    if (isMyChaynsApp) {
                        const chaynsCall = {
                            action: 142,
                            value: {
                                siteId,
                                color: null,
                                colorMode: null,
                                tappIdUrl: tappId ? `/tapp/index/${tappId}` : undefined,
                                title: name,
                                login: isKnown,
                                noPushCheck: !isKnown,
                                domain,
                            },
                        };
                        chayns.invokeCall(chaynsCall);
                    } else {
                        const url = new URL(`https://chayns.net/${siteId}/tapp/${tappId || ''}`);
                        if (openDialog) {
                            url.searchParams.append('chaynsdevisit', `${chaynsInfo.User.ID}`);
                        }
                        if (user.incognito) {
                            url.searchParams.set('incognitoMode', '1');
                        }
                        chayns.openUrlInBrowser(url.href, '_blank');
                    }
                }}
            >
                <div className="page-wrapper__header">
                    <div
                        className={squareImgWrapperClassnames}
                    >
                        <img
                            className={squareImgClassnames}
                            src={id ? `https://sub60.tobit.com/l/${id}?size=114` : ''}
                            style={{
                                opacity: imageLoaded ? '1' : '0',
                            }}
                            onLoad={() => {
                                setImageLoaded(true);
                            }}
                            onError={() => {
                                setImageLoaded(true);
                            }}
                            onContextMenu={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                return false;
                            }}
                            alt=""
                        />

                    </div>
                    <div className="page-wrapper__header__domain">{domain || name}</div>
                </div>

                <h2>{title || tappName}</h2>
                {
                    highlights && highlights.length > 0 && highlights[0] && (
                        <span className="highlighted_text" dangerouslySetInnerHTML={{ __html: highlights[0] }}/>
                    )
                }
                {
                    (!highlights || highlights.length === 0 || !highlights[0]) && description && (
                        <span>{description}</span>
                    )
                }
            </div>
            <style jsx global>
                {`
                    .page-wrapper {
                        span {
                            em {
                                font-style: normal;
                            }
                        }
                    }
                `}
            </style>
            <style jsx>
                {`
                
                .page-wrapper {
                    user-select: none;
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    overflow: visible;
                    -webkit-touch-callout: none;
                    padding: 10px;
                    opacity: 1;
                    animation-name: fadeIn;
                    animation-iteration-count: 1;
                    animation-timing-function: ease-in;
                    animation-duration: .2s;

                    em {
                        font-weight: 800;
                        font-style: normal;
                        color: var(--chayns-color--primary);
                    }

                    &__header {
                        display:flex;
                        &__domain {
                            margin-left: 5px;
                        }
                    }
                    
                }
                
                .highlighted_text {
                  em {
                        font-style: normal;
                    }
                }
                
                .infocenter__square {
                    background-color: ${davidDialogTitleColor || 'var(--chayns-color--000)'};

                    &__img-wrapper {
                        height: 20px;
                        width: 20px;
                        position: relative;
                        display: inline-block;
                        transition: all .3s;
                        background-color: rgba(var(--chayns-color-rgb--text), .1);

                        &:after {
                            content: '';
                            padding-top: 100%;
                            display: block;
                        }

                        &:hover {
                            transform: scale(1.15);
                        }

                        &.is_mobile:hover {
                            transform: none;
                        }
                    }

                    &__img {
                        position: absolute;
                        background-position: center;
                        background-size: cover;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        outline: 0.05px solid rgba(0, 0, 0, 0.1);
                        outline-offset: -1px;
                        opacity: 0.8;
                    }

                    &__subline {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: block;
                        width: 100%;
                        text-align: center;
                        padding-top: 5px;
                        height: 45px;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        line-height: 1.4;
                        width: 90%;

                        &.small-font {
                            font-size: 12px;
                            height: 50px;
                        }
                    }
                }

                a:hover {
                    opacity: 1 !important;
                }

                @keyframes fadeIn {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                    }
                }
            `}
            </style>
        </>
    );
};

Page.displayName = 'Page';

export default memo(Page);
