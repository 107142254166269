import { Thread } from '../../../../../../../types/thread';
import { getIsExactOneLocationGiven, getIsLocationIntercom, getMemberName, getMemberNames, getOtherMembers } from './member';

export const getThreadTitle = (thread: Thread, userId: number): string => {
    const { members, name } = thread;

    const otherMembers = getOtherMembers(members, userId);

    const memberNames = getMemberNames(otherMembers, {
        shouldGetFullNames: otherMembers.length < 3,
    });

    const isExactOneLocationGiven = getIsExactOneLocationGiven(otherMembers);

    let title = name || memberNames;

    if (isExactOneLocationGiven && typeof name === 'string' && name.trim() !== '') {
        const locationMember = otherMembers.find(({ locationId }) => typeof locationId === 'number');

        const locationMemberName = locationMember ? getMemberName(locationMember) : '';

        title = getIsLocationIntercom() ? memberNames : locationMemberName;
    }

    return title;
};
