const parseJwt = (token: string): string => {
    if (token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const atob = Buffer.from(base64, 'base64').toString('binary');
        const jsonPayload = decodeURIComponent(atob
            .split('')
            .map((c) => `%${(`00${c.charCodeAt(0)
                .toString(16)}`).slice(-2)}`)
            .join(''));
        return JSON.parse(jsonPayload)?.jti;
    }
    return null;
};

export default parseJwt;
