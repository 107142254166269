/* eslint-disable linebreak-style */
import React, { useCallback, useMemo, useState, useEffect, useRef, memo } from 'react';
import classNames from 'classnames';
import { List, InfiniteLoader, AutoSizer } from 'react-virtualized';
import throttle from 'lodash.throttle';
import SmallWaitCursor from '@chayns-components/SmallWaitCursor';
import { useDispatch, useSelector } from 'react-redux';

import PropTyes, { InferProps } from 'prop-types';
import NewsItem from './news-item/NewsItem';
import { MOBILE_VIEW_BREAKPOINT } from '../../../../../constants/size';
import addOnActivateListener from '../../../../../utils/addOnActivateListener';
import { getAppState } from '../../../../../redux-modules/app/selector';
import { getEnvState } from '../../../../../redux-modules/env/selector';
import { getTextStringState } from '../../../../../redux-modules/text-strings/selector';
import { getSitesState } from '../../../../../redux-modules/sites/selector';
import { handleLoadNews } from '../../../../../redux-modules/news/actions';
import { getNewsState } from '../../../../../redux-modules/news/selector';
import { getSearchStringState } from '../../../../../redux-modules/search/selector';

const propTypes = {
    backgroundColor: PropTyes.string.isRequired,
};

const News: React.FC<InferProps<typeof propTypes>> = ({
    backgroundColor,
}) => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        width,
    } = app;

    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
    } = env;

    const textStrings = useSelector(getTextStringState);

    const sites = useSelector(getSitesState);

    const news = useSelector(getNewsState);
    const {
        isLoadingNews,
    } = news;

    const searchString = useSelector(getSearchStringState);

    const values = useMemo(() => {
        if (searchString && width < MOBILE_VIEW_BREAKPOINT) {
            return news.searchValues || [];
        }
        return news?.values || [];
    }, [news.searchValues, news?.values, searchString, width]);

    const hasMore = useMemo(() => {
        if (searchString && width < MOBILE_VIEW_BREAKPOINT) {
            return news.searchHasMore;
        }
        return news.hasMore;
    }, [news.hasMore, news.searchHasMore, searchString, width]);

    const refreshIntervalRef = useRef(null);
    useEffect(() => {
        refreshIntervalRef.current = setInterval(() => {
            if (sites) {
                dispatch(handleLoadNews({
                    sites: sites.values,
                }));
            }
        }, 10 * 60 * 1000);

        return () => {
            clearInterval(refreshIntervalRef.current);
        };
    }, [dispatch, sites]);

    useEffect(() => {
        const cb = (isVisible: boolean) => {
            if (isVisible) {
                dispatch(handleLoadNews({
                    sites: sites.values,
                }));
            }
        };
        return addOnActivateListener(cb);
    }, [dispatch, sites?.values]);

    const [isFetching, setIsFetching] = useState(false);

    const isItemLoaded = useCallback((index) => !!values[index], [values]);

    const itemCount = (values?.length || 0) + (hasMore ? 1 : 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleLoad = useCallback(throttle(async ({
        stopIndex,
    }) => {
        if (!isFetching && hasMore) {
            setIsFetching(true);
            try {
                if (sites?.values?.length > 0 && !values[stopIndex]) {
                    const lastNews = values[values.length - 1];
                    await dispatch(handleLoadNews({
                        sites: sites.values,
                        date: new Date(lastNews.publishTime || lastNews.creationTime).toISOString(),
                    }));
                }
                setIsFetching(false);
            } catch (error) {
                setIsFetching(false);
            }
        }
    }, 200, { leading: false }), [isFetching, values, sites?.values, hasMore]);

    const listElement = useCallback(({ index, key, style }) => {
        const item = values[index];
        if (!item) {
            return (
                <div
                    style={{
                        ...style,
                        left: '50%',
                        width: 'unset',
                        overflow: 'hidden',
                    }}
                    key={key}
                >
                    <SmallWaitCursor show/>
                </div>
            );
        }

        return (
            <div
                style={style}
                key={`${item.id}_${item.locationId}`}
                className="cc__list"
            >
                <NewsItem
                    item={item}
                    sites={sites?.values}
                    isMyChaynsApp={isMyChaynsApp}
                />
            </div>
        );
    }, [isMyChaynsApp, sites?.values, values]);

    return (
        <>
            <div
                className={classNames('news-wrapper', {
                    'mobile-view': width < MOBILE_VIEW_BREAKPOINT,
                })}
                style={{
                    backgroundColor,
                    marginTop: width < MOBILE_VIEW_BREAKPOINT ? 8 : 0,
                }}
            >
                {width >= MOBILE_VIEW_BREAKPOINT && (
                    <h1
                        className="news-headline"
                    >
                        {textStrings?.['txt_chayns_de_news'] || 'Neuigkeiten'}
                    </h1>
                )}

                {values.length > 0 && !isLoadingNews && (
                    <InfiniteLoader
                        minimumBatchSize={20}
                        isRowLoaded={isItemLoaded}
                        loadMoreRows={handleLoad}
                        rowCount={itemCount}
                        threshold={5}
                    >
                        {({ onRowsRendered, registerChild }) => (
                            <AutoSizer>
                                {({ width: autoSizerWidth, height }) => (
                                    <List
                                        ref={registerChild}
                                        height={height - 43}
                                        width={autoSizerWidth}
                                        onRowsRendered={onRowsRendered}
                                        rowCount={itemCount}
                                        rowHeight={64}
                                        rowRenderer={listElement}
                                        overscanRowCount={10}
                                        style={{
                                            backgroundColor,
                                        }}
                                        className="scrollbar"
                                    />
                                )}
                            </AutoSizer>
                        )}
                    </InfiniteLoader>
                )}

                {isLoadingNews && (
                    <div
                        className="news-wait-cursor"
                    >
                        <SmallWaitCursor show/>
                    </div>
                )}

                {!isLoadingNews && values?.length === 0 && (
                    <p
                        className="chayns__color--text"
                        style={{
                            textAlign: 'center',
                            marginTop: '20px',
                        }}
                    >
                        {textStrings?.['txt_chayns_de_no_results'] || 'Keine Ergebnisse'}
                    </p>
                )}

            </div>
            <style jsx>
                {`
                    .news-wrapper {
                        box-shadow: 1px 2px 4px #0000001a;
                        overflow: hidden;
                        flex-grow: 1;

                        &.mobile-view {
                            width: 100%;
                            margin-right: 0;
                            box-shadow: none;
                            max-width: unset;
                        }

                        .news-headline {
                            margin: 10px 16px 5px;
                            font-size: 21px;
                        }

                        .news-wait-cursor {
                            justify-content: center;
                            display: flex;
                            margin-top: 20px;
                        }
                    }
                `}
            </style>
        </>
    );
};

News.displayName = 'News';

News.propTypes = propTypes;

export default memo(News);
