import { Location } from '../../types/location';
import { Page } from '../../types/Page';
import { Person } from '../../types/person';
import { RootState } from '../rootReducer';
import { SearchResults } from './actions';

export const getSearchStringState = (state: RootState): string => state.search.searchString;
export const getSearchSiteByIdState = (state: RootState, id: string) : Location => (state.search.sitesSearch?.values as Array<Location>)?.find((site) => site?.siteId === id);

export const getIsSearchingSitesState = (state: RootState): boolean => state.search.isSearchingSites;
export const getSearchSitesState = (state:RootState) : SearchResults<Location> => state.search.sitesSearch;

export const getIsSearchingPagesState = (state: RootState): boolean => state.search.isSearchingPages;
export const getSearchPagesState = (state:RootState) : SearchResults<Page> => state.search.pagesSearch;

export const getIsSearchingPersonsState = (state: RootState): boolean => state.search.isSearchingPersons;
export const getSearchPersonsState = (state:RootState) : SearchResults<Person> => state.search.personsSearch;
