import { SmartClientServer } from '../../types/smartClient';

const smartClientServerAvailable = (servers: Array<SmartClientServer>): boolean => {
    let enabled = false;
    if (servers?.length > 0) {
        servers.forEach((server) => {
            if (server.apiVersion > '1.0.186') {
                enabled = true;
            }
        });
    }
    return enabled;
};

export default smartClientServerAvailable;
