const listenerMapping = {};
let counter = 0;

export const addApiListener = (key, callback) => {
    const id = ++counter;
    if (!listenerMapping[key]) {
        listenerMapping[key] = {};
    }
    const shouldInitialize = Object.keys(listenerMapping[key]).length === 0;

    listenerMapping[key][counter] = callback;

    return { id, shouldInitialize };
};

export const removeApiListener = (key, id) => {
    if (listenerMapping[key]) {
        delete listenerMapping[key][id];
    }
    return Object.keys(listenerMapping[key]).length === 0;
};

export const dispatchApiEvent = (key, value) => {
    if (listenerMapping[key]) {
        Object.values(listenerMapping[key]).forEach((l) => l(value));
    }
};
