import { callbackHandler } from '../calls/json-native-calls/json-native-calls';

import TAPPIDS from '../constants/tapp-ids';
import VERSION from '../constants/version';

window.cnrt = {
    ConsoleLogger: {},
    TAPPIDS,
    version: VERSION,
};

window.nativeCallCallback = callbackHandler;
