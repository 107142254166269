const getCoords = async (): Promise<{ longitude: number, latitude: number }> => {
    const pos = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
    }) as {
        coords: {
            longitude: number,
            latitude: number,
        }
    };

    return {
        longitude: pos.coords.longitude,
        latitude: pos.coords.latitude,
    };
};

let callbackCount = 0;

const invokeAppCall = (call) => {
    call = JSON.stringify(call);
    if (window.webkit?.messageHandlers?.jsonCall) {
        window.webkit.messageHandlers.jsonCall.postMessage(call);
    } else if (window.chaynsApp?.jsonCall) {
        window.chaynsApp.jsonCall(call);
    } else if (window.chaynsElectron?.jsonCall) {
        window.chaynsElectron.jsonCall(call);
    } else {
        throw new Error('jsoncall interface not found');
    }
}

function appCall(action, value: unknown = {}, { callback, awaitResult = true } = {}) {
    if (!awaitResult) {
        invokeAppCall({ action, value });
        return;
    }
    return new Promise((resolve) => {
        const callbackName = `chaynsApiV5Callback_${callbackCount++}`;
        window[callbackName] = (v) => {
            if (callback) {
                callback(v?.retVal ?? v);
            } else {
                delete window[callbackName];
            }
            resolve(v?.retVal ?? v);
        };
        value.callback = `window.${callbackName}`;
        invokeAppCall({ action, value });
    });
}

export { getCoords, appCall };
