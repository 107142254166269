import React, { memo } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import classNames from 'classnames';

const propTypes = {
    onClick: PropTypes.func.isRequired,
    isDarkmode: PropTypes.bool.isRequired,
    isMyChaynsApp: PropTypes.bool.isRequired,
};

const ClearIcon: React.FC<InferProps<typeof propTypes>> = ({
    onClick,
    isDarkmode,
    isMyChaynsApp,
}) => (
    <>
        <button
            onClick={onClick}
            type="button"
            className={classNames('clear-icon', 'button', {
                'is-app': isMyChaynsApp,
            })}
        >
            <i
                className="react-chayns-icon fa fa-times input__icon-right"
                style={{ pointerEvents: 'all' }}
            />
        </button>
        <style jsx global>
            {`
                .button.clear-icon {
                    height: 42px;
                    padding: 7px 12px;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    box-shadow: none !important;
                    background-color: ${isDarkmode ? 'black! important' : 'white'};
                    border: 1px solid rgba(160,160,160,0.3);
                    border-radius: 0;
                    transition: background-color .2s ease;
                    min-width: 42px;
                    
                    &.background-blue {
                    min-width: 42px;
    border: 1px solid var(--chayns-color--primary);
    background-color: var(--chayns-color--primary) !important;
                    }

                    i {
                      color: ${isDarkmode ? 'white !important' : 'var(--chayns-color--primary) !important'};
                      line-height: 0;
                    }

                    &:hover {
                      background-color: ${isDarkmode ? 'black !important' : 'white !important'};
                    }

                    &.is-app {
                        border-left: none;
                    }
                  }
                `}
        </style>
    </>
);

ClearIcon.propTypes = propTypes;

ClearIcon.defaultProps = {};

ClearIcon.displayName = 'ClearIcon';

export default memo(ClearIcon);
