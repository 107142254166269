import { getKeyForTapp } from '../../../utils/chayns-storage';

type GetObjectForKeyArguments = {
    key: string;
    accessMode: number;
    callback: () => void;
    addJSONParam: object;
};

export default async function getObjectForKey(req: { value: GetObjectForKeyArguments } | GetObjectForKeyArguments, res: { answer: ({ object }) => void }): Promise<void> {
    const value = 'value' in req ? req.value : req;
    const { key, accessMode, callback, addJSONParam } = value;
    const tappId = window.chaynsInfo.getGlobalData().AppInfo.TappSelected.Id;

    const item = await getKeyForTapp(tappId, key, accessMode);
    if (!res.answer) {
        const params = {
            callback,
            addJSONParam: addJSONParam || {},
            retVal: item !== undefined ? { object: item } : {},
        };

        const frame = res[0];
        if (frame && typeof frame.postMessage === 'function') {
            frame.postMessage(`${res[1] || 'chayns.customTab.'}jsoncall:${JSON.stringify(params)}`, '*');
        }
        return;
    }

    res.answer({ object: item });
}
