import React, { useRef } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Distribute from './distribute/Distribute';
import { DISTRIBUTE } from '../../../constants/types';
import { setDialog } from '../../../redux-modules/dialog/actions';
import { getDialogState } from '../../../redux-modules/dialog/selector';
import { Mail } from '../../../types/smartClient';

const Dialog: React.FC = () => {
    const dispatch = useDispatch();
    const dialog = useSelector(getDialogState);

    const dialogRef = useRef(null);
    const closeRef = useRef(null);

    const renderDialog = (() => {
        if (!dialog) {
            return null;
        }

        switch (dialog.dialogType) {
            case DISTRIBUTE: {
                return (
                    <Distribute
                        mail={dialog.data as Mail}
                    />
                );
            }
            default:
                return null;
        }
    })();

    return (
        <>
            <div
                className={classnames('dialog_wrapper', 'dialog-root')}
                onClick={(e) => {
                    const startXPosition = closeRef.current.xPosition || e.clientX;
                    const startYPosition = closeRef.current.yPosition || e.clientY;
                    if (!dialogRef.current || dialogRef.current.contains(e.target) || Math.abs(e.clientX - startXPosition) > 10 || Math.abs(e.clientY - startYPosition) > 10) {
                        return;
                    }
                    closeRef.current = {};


                    dispatch(setDialog({
                        data: null,
                        dialogType: null,
                    }));
                }}
                onMouseDown={(e) => {
                    closeRef.current = {
                        xPosition: e.clientX,
                        yPosition: e.clientY,
                    };
                }}
            >
                <div
                    className={classnames('api-dialog', 'color__dialogBackgroundColor', 'color__baseTextColor', {
                        'api-dialog--big': (dialog && (dialog.type === DISTRIBUTE)),
                    })}
                    ref={dialogRef}
                >
                    {renderDialog}
                </div>
            </div>
            <style jsx>
                {`
                  .dialog_wrapper {
                    opacity: 1;
                    height: 100%;

                    .api-dialog {
                        .api-dialog__content {
                            max-height: 100%;   
                        }

                        &.api-dialog--big {
                            width: 100%;
                        }
                    }
                  }
                `}
            </style>
        </>
    );
};

Dialog.displayName = 'Dialog';

export default Dialog;
