import React from 'react';
import ApiV5Tapp from '../../ApiV5Tapp/ApiV5Tapp';
import TAPPIDS from '../../../../../constants/tapp-ids';
import { WalletHotCardsSystem } from '../../../../../constants/defaults';

const WalletHotCards = () => {
    return (
        <>
            <div
                className="infocenter-wallet-hotcards"
            >
                <ApiV5Tapp
                    customData={{
                        onlyHotCards: true,
                        customItemMaxWidth: '80%',
                    }}
                    type="client-module"
                    tappId={TAPPIDS.WALLET}
                    system={WalletHotCardsSystem}
                />
            </div>
            <style jsx>
                {`
                  .infocenter-wallet-hotcards {
                    margin-top: 13px;
                    margin-bottom: 20px;
                    height: 100%;
                  }
                `}
            </style>
        </>
    );
};

export default WalletHotCards;
