export default function showFloatingButton(req, res) {
    const callback = () => typeof res.answer === 'function' && res.answer();
    const value = (() => {
        const id = parseInt(req.srcIframe?.[0]?.id.match(/\d+/g)?.[0], 10) || null;
        if (req.value) {
            return {
                ...req.value,
                id,
                callback,
            };
        }
        return {
            ...req,
            id,
            callback,
        };
    })();
    window.handleChaynsCalls('floatingButton', value);
}
