import React, { useEffect, useState, useRef, memo } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { getWaitCursorState } from '../../redux-modules/components/selector';

const WaitCursor: React.FC = () => {
    const waitCursor = useSelector(getWaitCursorState);

    const { enabled, text, timeout = 5000, progress, progressText } = waitCursor;

    const textTimeout = useRef(null);

    const [showText, setShowText] = useState(false);

    useEffect(() => {
        clearTimeout(textTimeout.current);

        if (enabled && !progress) {
            textTimeout.current = setTimeout(() => {
                setShowText(true);
            }, timeout);
        }

        return () => {
            clearTimeout(textTimeout.current);

            setShowText(false);
        };
    }, [enabled, progress, timeout]);

    const waitCursorClasses = classNames('cw-wait-cursor', {
        'show-text': showText && !progress,
        'show-progress': progress,
    });

    const wcText = typeof text === 'string' ? text : 'Es tut uns leid, dass Du warten musst. Das dauert gerade länger als es eigentlich sollte...';

    return (
        <TransitionGroup component={null}>
            {enabled ? (
                <CSSTransition
                    classNames="cw-wait-cursor-animation"
                    key="cw-wait-cursor-animation"
                    appear
                    timeout={100}
                >
                    <div className={waitCursorClasses}>
                        <div className="cw-wait-cursor-wrapper">
                            <div className="cw-wc-image"/>
                            {showText ? (
                                <div className="cw-wc-text">
                                    {wcText}
                                </div>
                            ) : false}

                            {progress && (
                                <div
                                    className="cw-wait-cursor-progress-container"
                                >
                                    <div
                                        className="cw-wait-cursor-progress-wrapper"
                                    >
                                        <div
                                            className="cw-wait-cursor-progress"
                                            style={{
                                                transform: `translateX(${-100 + progress}%)`,
                                            }}
                                        />
                                    </div>

                                    {progressText && (
                                        <div
                                            className="cw-wait-cursor-progress-text"
                                        >
                                            {progressText}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>

                </CSSTransition>
            ) : false}
        </TransitionGroup>
    );
};

WaitCursor.displayName = 'WaitCursor';

export default memo(WaitCursor);
