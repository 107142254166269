/* eslint-disable consistent-return */
import { getSavedIntercomChats as getSavedIntercomChatsCall } from '../../json-native-calls/calls';

export default function getSavedIntercomChats(req: { value?: { itemId?: number }}, res: { answer: ({ status, data }: { status: number, data: unknown }) => void, event: (type: number, value: string) => void }): void {
    if (!req.value || !req.value.itemId) {
        return res.event(2, 'Field itemId missing.');
    }

    getSavedIntercomChatsCall(req.value.itemId).then((retVal) => {
        res.answer({
            status: retVal.status.code,
            data: retVal.data,
        });
    });
}
