import { Person } from '../../types/person';

import logFetch from '../../utils/logFetch';
import logger from "../../constants/logger";

const loadSearchPersons = async (searchString: string, take: number, skip: number, activePage: number, signal?: AbortSignal): Promise<{ status: number, result?: { count: number, list: Array<Person> } }> => {
    try {
        const logOptions = {
            fileName: __filename,
            section: '0',
            message: 'GET search person',
        };

        const requestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${typeof chaynsInfo !== 'undefined' ? window.chaynsInfo.User.TobitAccessToken : chayns.env.user.tobitAccessToken}`,
            },
            signal,
        };

        const response = await logFetch(`https://relations.chayns.net/relations/v2/person?query=${encodeURIComponent(searchString?.trim())}&skip=${skip}&take=${take}`, requestInit, logOptions);

        logger().info({
            message: 'Load persons',
            section: 'search persons',
            customText: searchString,
            data: {
                take,
                skip,
                activePage,
                searchString,
            },
        });

        if (response.status === 200) {
            const result = await response.json();

            return {
                status: response.status,
                result,
            };
        }
        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: 'Failed to load search persons',
            section: 'api/search/person',
        }, {
            message: ex?.message,
        });
        return {
            status: 500,
        };
    }
};

export { loadSearchPersons };
