import { setCookie } from '../../../utils/helper';
import { DISALLOWED_COOKIE_PREFIXES } from './constants';

const setTappCookie = (req: { value: { name: string, value: string, expireDate: string }}): void => {
    const { value } = req;

    if (DISALLOWED_COOKIE_PREFIXES.some((prefix: string) => value?.name.startsWith(prefix))) return;

    const expDate = value?.expireDate ? new Date(value.expireDate) : null;

    if (value.value === null) {
        setCookie(value.name, '', new Date(0));
    } else {
        setCookie(value.name, value.value, expDate);
    }
};

export default setTappCookie;
