import React, { memo } from 'react';
import { InferProps } from 'prop-types';
import ApiV5Tapp from "../ApiV5Tapp/ApiV5Tapp";
import TAPPIDS from "../../../../constants/tapp-ids";
import { useSelector } from "react-redux";
import { getActiveDavidSiteId, getAppState } from "../../../../redux-modules/app/selector";
import { CalendarSystem } from "../../../../constants/defaults";

const propTypes = {
};

const CalendarStore: React.FC<InferProps<typeof propTypes>> = ({

}) => {
    const activeDavidSiteId = useSelector(getActiveDavidSiteId);

    const { loadCalendarStore } = useSelector(getAppState);

    if(!loadCalendarStore) return null;
    return (
        <ApiV5Tapp customData={{serverId: activeDavidSiteId, storeView: true}} type="client-module" tappId={TAPPIDS.CALENDAR} system={CalendarSystem} />
    );
};

CalendarStore.propTypes = propTypes;
CalendarStore.displayName = 'CalendarTapp';

export default memo(CalendarStore);
