import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import Icon from '@chayns-components/Icon';
import { useSelector } from 'react-redux';
import { getAppState } from '../../../../../../redux-modules/app/selector';

const propTypes = {
    siteColor: PropTypes.string,
    pageIcon: PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string,
        base64: PropTypes.string,
    }),
    usedPageIcon: PropTypes.string.isRequired,
};

const PageIcon: React.FunctionComponent<InferProps<typeof propTypes>> = ({
    siteColor,
    pageIcon,
    usedPageIcon,
}) => {
    const app = useSelector(getAppState);
    const {
        colorMode,
        sitesBackgroundColor,
    } = app;

    const backgroundColor = (() => {
        if (sitesBackgroundColor) {
            return sitesBackgroundColor;
        }
        if (colorMode === 'dark') {
            return '#121212';
        }
        return '#f7f7f7';
    })();

    return (
        <>
            <div
                className="page_icon"
                style={{
                    backgroundColor: !pageIcon?.base64 && !pageIcon?.url ? siteColor : backgroundColor,
                    border: `2px solid ${backgroundColor}`,
                }}
            >
                {!pageIcon?.base64 && !pageIcon?.url && (
                    <Icon
                        icon={usedPageIcon}
                        style={{
                            color: 'white',
                        }}
                    />
                )}

                {(pageIcon?.base64 || pageIcon?.url) && (
                    <img
                        src={usedPageIcon}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        alt=""
                    />
                )}
            </div>
            <style jsx>
                {`
                .page_icon {
                    position: absolute;
                    bottom: -5px;
                    right: -5px;
                    border: 2px solid white;
                    width: 24px;
                    height: 24px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 3px;
                    :global(.react-chayns-icon) {
                                font-size: 12px;
                    }
                }  
            `}
            </style>
        </>
    );
};

PageIcon.propTypes = propTypes;

PageIcon.defaultProps = {
    siteColor: null,
    pageIcon: null,
};

export default PageIcon;
