export default function closeDialog(req) {
    const dialogRoot = document.querySelector('#dialog-root');
    const newDialogRoot = document.querySelector('#dialog-closezone');
    if (newDialogRoot) {
        newDialogRoot.click();
    }
    if (req.value && req.value.setDialogHidden) {
        dialogRoot.style.opacity = '0';
        dialogRoot.style.pointerEvents = 'none';
    } else if (dialogRoot) {
        dialogRoot.style.opacity = '1';
        dialogRoot.style.pointerEvents = 'auto';
    }
}
