import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSelectedItem } from '../../../../../../redux-modules/app/actions';
import { getAppState } from '../../../../../../redux-modules/app/selector';
import { setDeletedIntercomThreads } from '../../../../../../redux-modules/intercom/actions';
import ApiV5Tapp from '../../../ApiV5Tapp/ApiV5Tapp';
import TAPPIDS from '../../../../../../constants/tapp-ids';
import { IntercomThreadViewSystem } from '../../../../../../constants/defaults';
import appCall from "../../../../../../utils/appCall";
import {MAIL} from "../../../../../../constants/types";

const SelectedIntercom: React.FC = () => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        selectedItem,
    } = app;

    return (
        <>
            <div
                className="infocenter-intercom-tapp"
            >
                <ApiV5Tapp
                    customData={{
                        threadId: selectedItem.id,
                        goBackToInfocenter: () => {
                            appCall(292, {
                                show: true,
                            }, {
                                awaitResult: false,
                            });
                            dispatch(setSelectedItem(null));
                        },
                        onThreadDeleted: () => {
                            dispatch(setSelectedItem(null));
                            dispatch(setDeletedIntercomThreads({
                                threadIds: [selectedItem.id],
                            }));
                        },
                    }}
                    type="client-module"
                    tappId={TAPPIDS.INTERCOM}
                    system={IntercomThreadViewSystem}
                />
            </div>
            <style jsx global>
                {`
                      .infocenter-intercom-tapp {
                        height: 100%;
                        padding: 0 !important;
                        
                        > div {
                          height: 100%;
                          > div:not(.module-css) {
                            height: 100%;
                          }
                        }
                      }
                    `}
            </style>
        </>
    );
};
SelectedIntercom.displayName = 'SelectedIntercom';

export default SelectedIntercom;
