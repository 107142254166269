import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QRScanner from '../../bottom-bar/qr-scanner/QRScanner';
import { setIsScannerShown } from '../../../redux-modules/app/actions';
import { getAppState } from '../../../redux-modules/app/selector';

const QrScannerMiddle = ({
    scannerCallback,
}) => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        colorMode,
        isClient,
        isScannerShown,
    } = app;
    const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);

    if(!isScannerShown) return <div/>;
    return (
        <div>
            <QRScanner
                show={isScannerShown}
                isDarkMode={isDarkMode}
                setShowScanner={() => {
                    dispatch(setIsScannerShown(false));
                }}
                callback={scannerCallback}
                isClient={isClient}
                center
            />
        </div>
    );
};

export default QrScannerMiddle;
