import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { getAppState } from '../../../../../../../redux-modules/app/selector';

const PagePlaceholder = () => {
    const app = useSelector(getAppState);
    const {
        davidDialogTitleColor,
    } = app;

    return (
        <>
            <div className="chayns__background-color--002 page_placeholder"/>
            <style jsx>
                {`
                    .page_placeholder {
                        height: 143px;
                        background-color: ${davidDialogTitleColor || 'rgba(var(--chayns-color-rgb--002), 1)'};
                    }
                `}
            </style>
        </>
    );
};

PagePlaceholder.displayName = 'Page';

export default memo(PagePlaceholder);
