import { decodeTobitAccessToken } from '../../../utils/convert';

export default function getTobitAccessToken(isGuardedAccount = false) {
    const cookieData = document.cookie.split(';');
    const cookieName = isGuardedAccount ? 'chayns_guarded_at' : 'chayns_at_378';
    const token = cookieData.find((d) => d.indexOf(`${cookieName}=`) > -1);
    if (token) {
        const tokenData = token.replace(`${cookieName}=`, '');
        const data = decodeTobitAccessToken(tokenData);
        if (data) {
            return tokenData.trim();
        }
    }
    return '';
}
