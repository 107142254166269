import React, { useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';

import TAPPIDS from '../../../../constants/tapp-ids';
import {setActiveSmartClientTapp, setActiveTapp} from '../../../../redux-modules/app/actions';
import SmartClientTapp from '../smartclient-tapp/SmartClientTapp';
import {getAppState} from "../../../../redux-modules/app/selector";

const DavidAppTapp: React.FC = () => {
    const dispatch = useDispatch();

    const { activeTapp } = useSelector(getAppState);

    useEffect(() => {
        window.davidAppTapp = true;
    }, []);

    useEffect(() => {
        window.addEventListener('smart-client-push', () => {
            dispatch(setActiveTapp(TAPPIDS.SMART_CLIENT));
            dispatch(setActiveSmartClientTapp('inbox'));
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div
                className={classNames({
                    'not-active': activeTapp !== TAPPIDS.SMART_CLIENT,
                })}
            >
                <SmartClientTapp/>
            </div>

            <style jsx>
                {`
                    .not-active {
                        display: none;
                        opacity: 0;
                    }
            `}
            </style>
        </>
    );
};

export default DavidAppTapp;
