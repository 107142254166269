/* eslint-disable max-len */
import React, { memo } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useSelector } from 'react-redux';

import { INTERCOM, MAIL } from '../../../../../../../constants/types';
import { getAppState } from '../../../../../../../redux-modules/app/selector';

const propTypes = {
    type: PropTypes.string,
};

const TypeIcon: React.FunctionComponent<InferProps<typeof propTypes>> = ({
    type,
}) => {
    const app = useSelector(getAppState);
    const {
        colorMode,
    } = app;

    const isDarkMode = colorMode === 'dark';

    return (
        <>
            {type === INTERCOM && (
                <svg
                    className="type-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="65"
                    height="65"
                    viewBox="0 0 65 65"
                >
                    <defs>
                        <clipPath id="clip-intercom">
                            <rect width="65" height="65"/>
                        </clipPath>
                    </defs>
                    <g id="intercom" clipPath="url(#clip-intercom)">
                        <g transform="translate(-11 -27)">
                            <rect
                                id="Kontur"
                                width="65"
                                height="65"
                                rx="32.5"
                                transform="translate(11 27)"
                                fill={isDarkMode ? '#1E1E1E' : '#fff'}
                            />
                            <rect width="55" height="55" rx="27.5" transform="translate(16 32)" fill="#00b906"/>
                            <g transform="translate(22.472 47.157)">
                                <path d="M275.795,327.4a7.672,7.672,0,0,0-7.636-7.1h-12.5A7.673,7.673,0,0,0,248,327.962v1.285a7.658,7.658,0,0,0,7.542,7.653v3.916a.883.883,0,0,0,.86.9.848.848,0,0,0,.553-.179l6.1-3.5,1.975-1.132h3.244a7.647,7.647,0,0,0,7.542-7.653V327.97c0-.2-.008-.392-.017-.57m-3.592,1.847a4.058,4.058,0,0,1-3.933,4.044H264.1l-.817.468-.332.2-3.839,2.2v-2.869h-3.558a4.047,4.047,0,0,1-3.933-4.044v-1.285a4.06,4.06,0,0,1,4.044-4.052h12.5a4.077,4.077,0,0,1,4.044,4.052Z" transform="translate(-248 -320.3)" fill="#fff"/>
                                <path d="M451.4,406.3h-5.984c.017.2.017.383.017.562v1.285a8.719,8.719,0,0,1-.179,1.762H451.4a4.049,4.049,0,0,1,4.044,4.052v1.285a4.053,4.053,0,0,1-3.933,4.044h-3.558v2.869l-4.18-2.4-.817-.468h-4.171a4.048,4.048,0,0,1-3.507-2.239h-1.549l-1.686.971-.238.136a7.665,7.665,0,0,0,6.972,4.75h3.261l8.07,4.639a.876.876,0,0,0,1.413-.715v-3.916a7.663,7.663,0,0,0,7.543-7.653v-1.286A7.677,7.677,0,0,0,451.4,406.3" transform="translate(-416.153 -398.979)" fill="#fff"/>
                            </g>
                        </g>
                    </g>
                </svg>

            )}

            {type === MAIL && (
                <svg
                    className="type-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="65"
                    height="65"
                    viewBox="0 0 65 65"
                >
                    <defs>
                        <clipPath id="clip-mail">
                            <rect width="65" height="65"/>
                        </clipPath>
                    </defs>
                    <g id="mail" clipPath="url(#clip-mail)">
                        <g transform="translate(-11 -27)">
                            <rect
                                id="Kontur"
                                width="65"
                                height="65"
                                rx="32.5"
                                transform="translate(11 27)"
                                fill={isDarkMode ? '#1E1E1E' : '#fff'}
                            />
                            <rect width="55" height="55" rx="27.5" transform="translate(16 32)" fill="var(--chayns-color--primary)"/>
                            <path d="M30.524,71.705a.366.366,0,0,1,.589.286V84.418A2.918,2.918,0,0,1,28.2,87.335H2.917A2.918,2.918,0,0,1,0,84.418V72a.363.363,0,0,1,.589-.286c1.361,1.057,3.166,2.4,9.365,6.9,1.282.936,3.446,2.9,5.6,2.893,2.169.018,4.375-1.993,5.609-2.893C27.364,74.112,29.163,72.763,30.524,71.705ZM15.557,79.557c1.41.024,3.44-1.774,4.46-2.516,8.064-5.852,8.678-6.363,10.537-7.821a1.454,1.454,0,0,0,.559-1.148V66.917A2.918,2.918,0,0,0,28.2,64H2.917A2.918,2.918,0,0,0,0,66.917v1.155A1.463,1.463,0,0,0,.559,69.22c1.86,1.452,2.473,1.969,10.537,7.821C12.117,77.782,14.147,79.581,15.557,79.557Z" transform="translate(28.152 -15.25)" fill="#fff"/>
                        </g>
                    </g>
                </svg>

            )}

            <style jsx>
                {`
                    .type-icon {
                        position: absolute;
                        right: 5px;
                        bottom: 0;
                        width: 18px;
                        height: 18px;
                    }
                `}
            </style>
        </>
    );
};

TypeIcon.propTypes = propTypes;

TypeIcon.defaultProps = {
    type: null,
};

export default memo(TypeIcon);
