import classnames from 'classnames';
import React, { useMemo } from 'react';
import Icon from '@chayns-components/Icon';

import { Message, Member } from '../../../../../../../types/thread';

import { getMyMember } from '../utils/member';

type MessageStatusTypes = {
    members: Array<Member>,
    message: Message,
    shouldAlignLeft: boolean,
    userId: number,
};

const MessageStatus: React.FC<MessageStatusTypes> = ({ members, message, shouldAlignLeft, userId }) => {
    const { author, images, status } = message;

    const myMember = getMyMember(members, userId);

    const isImageGiven = images?.length > 0;

    const isMessageFromMe = author.id === myMember?.id;
    const isMessageRead = members.every((member) => member.readTime && new Date(member.readTime) > new Date(message.creationTime));

    const statusIcons = useMemo(() => {
        const icons = [];

        if (isMessageFromMe) {
            if (status === 0) {
                icons.push(<Icon className="message-status__icon" icon="far fa-clock" key="clock"/>);
            } else if (status !== 2) {
                if (isMessageRead) {
                    icons.push(
                        <Icon
                            className="message-status__icon message-status__icon--blue"
                            icon="ts-check"
                            key="first-blue-check"
                        />,
                        <Icon
                            className="message-status__icon message-status__icon--blue message-status__icon--overlap"
                            icon="ts-check"
                            key="second-blue-check"
                        />,
                    );
                } else {
                    icons.push(<Icon className="message-status__icon" icon="ts-check" key="single-check"/>);
                }
            }
        }

        if (isImageGiven) {
            icons.push(<Icon className="message-status__icon" icon="fas fa-camera" key="camera"/>);
        }

        if (icons) {
            return icons;
        }

        return null;
    }, [isImageGiven, isMessageFromMe, isMessageRead, status]);

    const messageStatusClasses = useMemo(
        () => classnames('message-status', {
            'message-status--hidden': !isMessageFromMe && !isImageGiven,
            'message-status--left-align': shouldAlignLeft,
        }),
        [isImageGiven, isMessageFromMe, shouldAlignLeft],
    );

    return useMemo(() => (
        <>
            <div className={messageStatusClasses}>{statusIcons}</div>
            <style jsx global>
                {`
                    .message-status {
                        align-items: center;
                        display: inline-flex;
                        height: 18px;
                        justify-content: flex-end;
                        margin-left: 4px;
                        text-align: center;

                        &--left-align {
                            justify-content: flex-start;
                            margin-left: 0;
                            margin-right: 4px;
                        }

                        &--hidden {
                            display: none;
                            margin-right: 0 !important;
                        }

                        &__icon {
                            &--blue {
                                color: #2080ff !important;
                            }

                            &:not(&--blue) {
                                color: var(--chayns-color--text) !important;
                            }

                            &--overlap {
                                margin-left: -10px !important;
                            }

                            &:not(:first-child) {
                                margin-left: 6px;
                            }

                            &.ts-check {
                                transform: translateY(2px);
                            }

                            &.fa-clock {
                                font-size: 13px;
                            }

                            &.fa-camera {
                                margin-right: 4px;
                            }
                        }
                    }
                `}
            </style>
        </>
    ), [messageStatusClasses, statusIcons]);
};

MessageStatus.displayName = 'MessageStatus';

export default MessageStatus;
