import { DialogHandler } from 'chayns-api';

let dialogId = 0;

export const dialogEventTarget = global.window ? new EventTarget() : null;

export default class DialogHelper {
    #dialogs = [];

    openDialog = (config, callback) => {
        const currentDialogId = dialogId++;

        const eventTarget = new EventTarget();

        const resolve = (buttonType, data) => {
            callback(buttonType, data);
            dialogEventTarget.dispatchEvent(new CustomEvent('remove', { detail: currentDialogId }));
        };

        const detail = {
            config,
            resolve,
            dialogId: currentDialogId,
            eventTarget,
        };

        dialogEventTarget.dispatchEvent(new CustomEvent('change', { detail }));

        return currentDialogId;
    };

    setCurrentDialogs = (dialogs) => {
        this.#dialogs = dialogs;
    };

    closeDialog = (dialogIdFromArg) => {
        const dialog = this.#dialogs.find((x) => x.dialogId === dialogIdFromArg);
        if (dialog) {
            dialog.resolve({ buttonType: -1 });
        }
    };

    dispatchEventToDialogClient = (dialogIdFromArg, data) => {
        const dialog = this.#dialogs.find((x) => x.dialogId === dialogIdFromArg);
        if (dialog) {
            dialog.eventTarget.dispatchEvent(new CustomEvent('host-dialog-event', { detail: data }));
        }
    };

    addDialogClientEventListener = (dialogIdFromArg, callback) => {
        const dialog = this.#dialogs.find((x) => x.dialogId === dialogIdFromArg);
        if (dialog) {
            dialog.eventTarget.addEventListener('client-dialog-event', (ev) => {
                callback(ev.detail);
            });
        }
    };

    createDialog = (config) => new DialogHandler(config, this.openDialog, this.closeDialog, this.dispatchEventToDialogClient, this.addDialogClientEventListener);
}
