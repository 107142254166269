const addDesignSettingsChangeListener = (req: unknown, res: { answer: ({ colorMode: number }) => void}): void => {
    const handleMediaChange = (event: MediaQueryListEvent) => res?.answer?.({
        colorMode: event.matches ? 1 : 0,
    });

    try {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', handleMediaChange);
    } catch (error) {
        window.matchMedia('(prefers-color-scheme: dark)').addListener(handleMediaChange);
    }
};

export default addDesignSettingsChangeListener;
