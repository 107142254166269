import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getSearchStringState } from '../../../../../../../redux-modules/search/selector';
import logger from "../../../../../../../constants/logger";

type PersonType = {
    firstName: string,
    lastName: string,
    personId: string,
    userId: number,
    isMyChaynsApp: boolean,
    index: number,
};

const Person: React.FC<PersonType> = ({
    firstName,
    lastName,
    personId,
    userId,
    isMyChaynsApp,
    index,
}) => {
    const searchString = useSelector(getSearchStringState);

    const squareImgWrapperClassnames = classnames('infocenter__square__img-wrapper', {
        is_mobile: isMyChaynsApp,
    });

    const showUser = () => {
        if (searchString) {
            logger().info({
                message: 'Click on Person',
                section: 'search stats',
                customText: searchString,
                data: {
                    personId,
                    firstName,
                    lastName,
                    index,
                },
            });
        }

        chayns.getUser({ personId })
            .then(({
                UserID,
                FirstName,
                LastName,
            }) => {
                chayns.invokeCall({
                    action: 184,
                    value: {
                        dialogContent: {
                            callType: 191,
                            url: `https://tapp.chayns-static.space/userdialogcard/v1.2/index.html?siteId=${chayns.env.site.id}`,
                            tappIframeName: window.name,
                            buttons: [],
                            seamless: true,
                            input: {
                                token: chayns.env.user.tobitAccessToken,
                                target: personId,
                                personInfo: {
                                    firstName: FirstName,
                                    lastName: LastName,
                                    receiverId: UserID,
                                },
                                userPersonId: chayns.env.user.personId,
                                userName: chayns.env.user.name,
                                senderId: chayns.env.user.id
                            },
                        },
                    },
                });
            });
    };

    return (
        <>
            <div
                key={personId}
                className="infocenter__square"
                onClick={showUser}
            >
                <div
                    className={squareImgWrapperClassnames}
                >
                    <img
                        className="infocenter__square__img"
                        src={`https://sub60.tobit.com/u/${userId}?size=114)`}
                        alt=""
                    />
                </div>
                <div
                    className={classnames('infocenter__square__subline', {
                        'small-font': isMyChaynsApp,
                    })}
                >
                    <span>{`${firstName} ${lastName}`}</span>
                </div>
            </div>
            <style jsx>
                {`
                  .infocenter__square {
                    user-select: none;
                    margin-bottom: 1vh;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    overflow: visible;
                    animation-name: fadeIn;
                    animation-iteration-count: 1;
                    animation-timing-function: ease-in;
                    animation-duration: .2s;

                    &__img-wrapper {
                      height: 60px;
                      width: 60px;
                      position: relative;
                      display: inline-block;
                      transition: all .3s;

                      &:hover {
                        transform: scale(1.15);
                      }

                      &:after {
                        content: '';
                        padding-top: 100%;
                        display: block;
                      }
                    }

                    &__img {
                      cursor: pointer;
                      position: absolute;
                      background-position: center;
                      background-size: cover;
                      top: 0;
                      right: 0;
                      left: 0;
                      bottom: 0;
                      width: 100%;
                      border-radius: 50%;
                    }

                    &__subline {
                      cursor: pointer;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: block;
                      width: 100%;
                      text-align: center;
                      padding-top: 5px;
                      height: 45px;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      line-height: 1.4;
                      width: 90%;

                      &.small-font {
                        font-size: 12px;
                        height: 50px;
                      }
                    }
                  }

                  @keyframes fadeIn {
                    from {
                      opacity: 0;
                    }
                    to {
                      opacity: 1;
                    }
                  }
                `}
            </style>
        </>
    );
};

Person.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    personId: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    isMyChaynsApp: PropTypes.bool.isRequired,
    index: PropTypes.number,
};

Person.defaultProps = {
    firstName: null,
    lastName: null,
    index: 0,
};

Person.displayName = 'Person';

export default Person;
