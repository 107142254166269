import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Input from '@chayns-components/Input';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { loadSearchPages, loadSearchSites } from '../../../../../api/search/sites';
import { linkCrawler } from '../../../../../api/link-crawler';
import { loadSearchPersons } from '../../../../../api/search/persons';
import { appCall, getCoords } from '../../../../../utils/geoLocationHelper';
import ScanIcon from './scan-icon/ScanIcon';
import calcItemWidth from '../../../../../utils/calcItemWidth';
import { MESSAGES, NEWS } from '../../../../../constants/types';
import { MOBILE_VIEW_BREAKPOINT } from '../../../../../constants/size';
import {
    setDavidDialogColor,
    setGeoCoordinates,
    setGeoPositionAvailable,
    setIsScannerShown,
    setIsSearchingMessages,
} from '../../../../../redux-modules/app/actions';
import { getAppState } from '../../../../../redux-modules/app/selector';
import { getEnvState } from '../../../../../redux-modules/env/selector';
import { getTextStringState } from '../../../../../redux-modules/text-strings/selector';
import { getDialogState } from '../../../../../redux-modules/dialog/selector';
import { handleLoadSearchMails } from '../../../../../redux-modules/smart-client/actions';
import { getSearchStringState } from '../../../../../redux-modules/search/selector';
import {
    setClearSearch,
    setIsSearchingPages,
    setIsSearchingPersons,
    setIsSearchingSites,
    setSearchPages,
    setSearchPersons,
    setSearchSites,
    setSearchString,
} from '../../../../../redux-modules/search/actions';
import { URL_REGEX } from '../../../../../utils/regex';
import { checkVoucherCode } from '../../../../../api/voucher';
import ClearIcon from './clear-icon/ClearIcon';

const Search: React.FC = ({ hidden }) => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        width,
        colorMode,
        davidDialogTitleColor,
        hideOtherContent,
        selectedContentType,
        selectedItem,
        geoPositionAvailable,
        geoCoordinates,
    } = app;

    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
        isDavidClient,
    } = env;

    const textStrings = useSelector(getTextStringState);

    const dialog = useSelector(getDialogState);

    const searchString = useSelector(getSearchStringState);

    const previousSiteQuery = useRef(null);

    const timeout = useRef(null);
    const scannerRef = useRef(null);

    const crawlUrlTimeout = useRef(null);
    const [isUrlValid, setIsUrlValid] = useState(false);

    const [clientRender, setClientRender] = useState(null);

    const inputRef = useRef(null);

    const voucherRegex = /(?:_c´|\?c=)([a-zA-Z\d]{4}-?[a-zA-Z\d]{4}-?[a-zA-Z\d]{4})/;

    useEffect(() => {
        setClientRender(true);
    }, []);

    const onChange = useCallback((value) => {
        dispatch(setSearchString(value));
    }, [dispatch]);

    const elementWidth = useMemo(() => calcItemWidth(isMyChaynsApp, width, hideOtherContent), [isMyChaynsApp, width, hideOtherContent]);
    const elementsPerLine = Math.round(100 / parseFloat(elementWidth));

    const tempSearchStringRef = useRef("");

    const tempSearchTimeout = useRef(null);

    const handleKeyDown = useCallback((evt) => {
        if (evt.target.tagName.toUpperCase() === 'INPUT' || evt.target.tagName.toUpperCase() === 'TEXTAREA' || evt.key.length > 1 || selectedItem?.type || dialog?.dialogType) {
            return;
        }
        if (hidden) {
            tempSearchStringRef.current += evt.key;
            clearTimeout(tempSearchTimeout.current);
            tempSearchTimeout.current = setTimeout(() => {
                document.querySelector('.head-search__search .fa.fa-search')?.click();
                onChange(tempSearchStringRef.current);
                tempSearchStringRef.current = "";
            }, 500);
            return;
        }
        if (inputRef.current?.ref) {
            inputRef.current?.ref?.focus();
        }
    }, [selectedItem, dialog]);

    const loadSites = () => {
        dispatch(setIsSearchingPersons(true));
        dispatch(setIsSearchingSites(true));
        dispatch(setIsSearchingPages(true));
        const loadData = async () => {
            previousSiteQuery.current = searchString;

            if (!searchString) {
                dispatch(setClearSearch());
                return;
            }

            if (voucherRegex.test(searchString) && typeof window.qrCodeScannerChaynsCallback === 'function') {
                window.qrCodeScannerChaynsCallback({
                    retVal: {
                        qrCode: `/voucher?c=${/(?:_c´|\?c=)([a-zA-Z\d]{4}-?[a-zA-Z\d]{4}-?[a-zA-Z\d]{4})/.exec(searchString)[1]}`,
                        interactionType: 'input',
                    },
                });
                onChange('');
                return;
            }

            if (/^[a-zA-Z\d]{4}-?[a-zA-Z\d]{4}-?[a-zA-Z\d]{4}$/.test(searchString)) {
                const regEx = /^[a-zA-Z\d]{4}-?[a-zA-Z\d]{4}-?[a-zA-Z\d]{4}$/;
                const regExResult = regEx.exec(searchString)[0];
                chayns.showWaitCursor(undefined, undefined, 'check-voucher');
                const { status } = await checkVoucherCode(regExResult);

                chayns.hideWaitCursor();
                if (status === 200 && typeof window.qrCodeScannerChaynsCallback === 'function') {
                    window.qrCodeScannerChaynsCallback({
                        retVal: {
                            qrCode: `/voucher?c=${regExResult}`,
                            interactionType: 'input',
                        },
                    });
                    onChange('');
                    return;
                }
            }

            let latitude = 52.06692;
            let longitude = 7.01652;
            let geoPivot = 300000;

            const isComplexSearch = searchString.indexOf('UND') > -1 && searchString.indexOf(':') > -1;

            try {
                if (geoPositionAvailable === true || geoCoordinates || isComplexSearch) {
                    if (geoCoordinates && geoCoordinates.longitude && geoCoordinates.latitude && geoCoordinates.geoPivot) {
                        latitude = geoCoordinates.latitude;
                        longitude = geoCoordinates.longitude;
                        geoPivot = geoCoordinates.geoPivot;
                    } else {
                        const geoPosition = chayns.env.isApp ? await chayns.getGeoLocation() : await getCoords();
                        if (geoPosition && geoPosition.longitude && geoPosition.latitude) {
                            latitude = geoPosition.latitude;
                            longitude = geoPosition.longitude;
                            geoPivot = 30;
                        }
                    }
                }
            } catch (e) {
                // ignore
            }

            // laoding sites
            loadSearchSites(searchString, elementsPerLine * 2, 0, {
                longitude,
                latitude,
                geoPivot,
            }, 1).then((value) => {
                if (value.status === 200) {
                    dispatch(setSearchSites({
                        value: value.result,
                    }));
                } else {
                    dispatch(setSearchSites({
                        value: {
                            values: [],
                        },
                    }));
                }
            }).catch(() => {
                dispatch(setSearchSites({
                    value: {
                        values: [],
                    },
                }));
            });

            // loading pages
            loadSearchPages(searchString, elementsPerLine * 2, 0, {
                longitude,
                latitude,
                geoPivot,
            }, 1).then((value) => {
                if (value.status === 200) {
                    dispatch(setSearchPages({
                        value: value.result,
                    }));
                } else {
                    dispatch(setSearchPages({
                        value: {
                            values: [],
                        },
                    }));
                }
            }).catch(() => {
                dispatch(setSearchPages({
                    value: {
                        values: [],
                    },
                }));
            });

            // loading persons
            if (searchString?.trim().length > 2) {
                loadSearchPersons(searchString.trim(), elementsPerLine * 2, 0, 1).then((value) => {
                    if (value.status !== 200) {
                        dispatch(setSearchPersons({
                            value: {
                                list: [],
                            },
                        }));
                        return;
                    }
                    dispatch(setSearchPersons({
                        value: value.result,
                    }));
                });
            } else {
                dispatch(setSearchPersons({
                    value: {
                        list: [],
                    },
                }));
            }

            try {
                if (geoPositionAvailable === undefined && chayns.env.isApp) {
                    appCall(268).then(({ enabled, activated}) => {
                        if (enabled && activated) {
                            const geoPosition = chayns.getGeoLocation();
                            geoPosition.then((data) => {
                                if (data && data.longitude && data.latitude) {
                                    dispatch(setGeoCoordinates({
                                        latitude: data.latitude,
                                        longitude: data.longitude,
                                        geoPivot: 30,
                                    }));
                                    dispatch(setGeoPositionAvailable(true));
                                }
                            }).catch(() => {
                                dispatch(setGeoPositionAvailable(false));
                            });
                        }
                    });
                }
            } catch (e) {
                dispatch(setGeoPositionAvailable(false));
            }
        };

        timeout.current = setTimeout(() => {
            loadData();
        }, 500);
    };

    const loadMessages = () => {
        const loadMails = () => {
            if (searchString?.trim().length === 0) {
                dispatch(setIsSearchingMessages(false));
                return;
            }

            dispatch(handleLoadSearchMails({
                searchString,
            }));
        };

        if (searchString?.trim().length > 0) {
            dispatch(setIsSearchingMessages(true));
        }

        timeout.current = setTimeout(() => {
            loadMails();
        }, 500);
    };

    const handleCcUrlInput = async () => {
        if (typeof window.qrCodeScannerChaynsCallback === 'function' && searchString.match(/chayns\.cc\/.+/)) {
            try {
                const splitQr = searchString.split('/');
                const codeFromQr = splitQr[splitQr.length - 1];
                if (codeFromQr) {
                    const requestCode = await fetch(`https://webapi.tobit.com/ChaynsCodeAdministration/v1.0/Chaynscode/${codeFromQr.toLowerCase()}`, {
                        headers: {
                            'Content-Type': 'application/json; charset=utf-8',
                            Authorization: `Bearer ${typeof chaynsInfo !== 'undefined' ? window.chaynsInfo.User.TobitAccessToken : chayns.env.user.tobitAccessToken}`,
                        },
                    });
                    if (requestCode.status === 200) {
                        window.qrCodeScannerChaynsCallback({ retVal: { qrCode: searchString, interactionType: 'input' } });
                        onChange('');
                    }
                }
            } catch (error) {
                // ignore
            }
        }
    };

    const shortened = searchString.slice(0, 35);

    const splitted = shortened.trim().split(' ').map((x) => x.trim());

    const hintText = shortened === searchString ? (splitted.length > 3 ? `${splitted.slice(0, 4).join(' ')}...` : shortened) : (`${shortened}...`);
    useEffect(() => {
        if (selectedContentType === MESSAGES && width < MOBILE_VIEW_BREAKPOINT) {
            loadMessages();
        } else if (selectedContentType === NEWS && width < MOBILE_VIEW_BREAKPOINT) {
            //
        } else {
            loadSites();
        }

        scannerRef.current = setTimeout(() => {
            handleCcUrlInput();
        }, 500);

        return () => {
            clearTimeout(timeout.current);
            clearTimeout(scannerRef.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    const inputStyles = useMemo(() => {
        const styles = {
            flex: '1',
        } as React.CSSProperties;
        if (!hideOtherContent && !isMyChaynsApp && !searchString) {
            styles.borderRight = 'none';
            styles.borderTopRightRadius = '0px';
            styles.borderBottomRightRadius = '0px';
        }
        if (isDavidClient) {
            styles.backgroundColor = davidDialogTitleColor;
        }
        if (clientRender) {
            styles.maxHeight = 'unset';
        }
        return styles;
    }, [davidDialogTitleColor, hideOtherContent, isDavidClient, isMyChaynsApp, searchString, clientRender]);

    useEffect(() => {
        if (isDavidClient) {
            window.changeDavidDialogTitleColor = (color: string) => {
                const newColor = /^[a-fA-F0-9]{3,8}$/.test(color) ? `#${color}` : color;
                dispatch(setDavidDialogColor(newColor));
            };
        }
    }, [dispatch, isDavidClient]);

    useEffect(() => {
        setIsUrlValid(false);
        clearTimeout(crawlUrlTimeout.current);
        crawlUrlTimeout.current = setTimeout(async () => {
            if (URL_REGEX.test(searchString) && !searchString.includes('chayns.cc')) {
                const status = await linkCrawler(searchString);
                setIsUrlValid(status < 300);
            }
        }, 500);
    }, [searchString]);

    const openNewTab = () => {
        const addHttps = !/^https?:\/\//.test(searchString) ? `https://${searchString}` : searchString;
        chayns.openUrlInBrowser(addHttps, '_blank');
    };

    return (
        <>
            <div
                className="search-wrapper-div"
                style={{ width: '89%' }}
            >
                <Input
                    value={searchString}
                    onChange={onChange}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    design={Input.BORDER_DESIGN}
                    id="search"
                    style={inputStyles}
                    iconLeft="fal fa-search"
                    placeholder={textStrings?.['txt_chayns_de_find'] || 'Finden'}
                    className={classNames('cc-scanner', {
                        'is-david-client': isDavidClient,
                    })}
                    ref={inputRef}
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    right={<></>}
                    onEnter={() => {
                        if (isUrlValid) {
                            openNewTab();
                        }
                    }}
                />
                {searchString && (
                    <ClearIcon
                        onClick={() => onChange('')}
                        isDarkmode={colorMode === 'dark'}
                        isMyChaynsApp={isMyChaynsApp}
                    />
                )}
                {!hideOtherContent && !isMyChaynsApp && (
                    <ScanIcon
                        text={textStrings?.['txt_chayns_de_scan'] || 'Scan'}
                        icon="ts-calling-code"
                        onClick={(e: React.SyntheticEvent) => {
                            e.preventDefault();
                            e.stopPropagation();
                            dispatch(setIsScannerShown(true));
                        }}
                    />
                )}

            </div>
            {textStrings.txt_chayns_de_springboard_input_description_v4 ? <div className="search-footer-wrapper">
                <div onClick={() => {
                    window.open(textStrings.txt_chayns_de_springboard_input_description_v4_url);
                }}
                >
                    <a className="long">
                        {textStrings.txt_chayns_de_springboard_input_description_v4}
                    </a>
                    <a className="short">
                        {textStrings.txt_chayns_de_springboard_input_description_v4_short}
                    </a>
                </div>
            </div> : null}
            {shortened ? (
                <div className="search_result_hint">
                    Suchergebnisse für „
                    {hintText}
                    “
                </div>
            ) : null}
            <style jsx global>
                {`
                  .search_result_hint {
                    padding-left: ${width > MOBILE_VIEW_BREAKPOINT ? 43 : 15}px;
                    padding-right: ${width > MOBILE_VIEW_BREAKPOINT ? 43 : 15}px;
                    margin-bottom: ${width > MOBILE_VIEW_BREAKPOINT ? 0:10}px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 21px;
                    max-width: 1000px;
                    margin: 0 auto;
                    width: 100%;
                    margin-top: 20px;
                  }
                  .search-wrapper-div {
                    display: flex;
                    margin: 0 auto;
                    margin-bottom: 10px!important;
                    max-width: 600px;
                    justify-content: center;
                    align-items: center;
                    flex: 1;
                    .input--border-design {
                      border-radius: 0;
                    }

                    .cc-scanner.is-david-client {
                      border-color: rgba(255, 255, 255, 0.2);

                      .input__icon-left {
                        color:  rgba(255, 255, 255, 1);
                      }

                      .input__input-wrapper label .ellipsis {
                        color: rgba(255, 255, 255, 0.8);
                      }
                    }
                  }
                  
                  .search-footer-wrapper {
                    display: flex;
                    justify-content: center;
                    margin-top: ${width > MOBILE_VIEW_BREAKPOINT ? 15 : 10}px;
                    position: relative;
                    margin-bottom: ${width > MOBILE_VIEW_BREAKPOINT ? 0 : 25}px;
                  }

                  @media (orientation: landscape) {
                    .search-footer-wrapper {
                      .short {
                        display: none;
                      }
                    }
                  }

                  @media (orientation: portrait) {
                    .search-footer-wrapper {
                      .long {
                        display: none;
                      }
                    }
                  }

                `}
            </style>
        </>
    );
};

Search.displayName = 'Search';

Search.propTypes = {};

export default Search;
