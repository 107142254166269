import executeChaynsCall from '../../calls/json-chayns-call/execute-chayns-call';
import logger from "../../constants/logger";

const chaynsNamespace = /^(chayns.\w*).(\w*)@?(\w*)?:({?.*}?)/;

export default function postMessage(method: string, params: string, [srcIframe, namespace = 'chayns.customTab']: Array<string>): void {
    const iframe = srcIframe || document.querySelector(`#TappIframe_${window.activeTapp > 0 ? window.activeTapp : window.activeTapp * -1}`);
    if (!iframe) {
        return;
    }

    if (!params) {
        // eslint-disable-next-line no-param-reassign
        params = '';
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const srcWindow = iframe.contentWindow || iframe;

    if (typeof srcWindow.postMessage === 'function') {
        try {
            srcWindow.postMessage(`${namespace}.${method}:${params.toString()}`, '*');
        } catch (e) {
            logger().error({
                message: e.message,
                fileName: 'custom-tapp-communication.js',
                section: 'custom-tapp-communication.postMessage',
                ex: {
                    message: e.message,
                    stackTrace: e.stack,
                },
            });
        }
    }
}

export const init = (): void => {
    const onWindowMessage = (event) => {
        const [, namespace, method, srcIFrame, params] = chaynsNamespace.exec(event ? event.data : '') || [];

        if (method) {
            if (method.toLowerCase() === 'jsoncall') {
                executeChaynsCall(params, [
                    srcIFrame ? document.querySelector(`[name="${srcIFrame}"]`) : null,
                    namespace,
                ]);
            }
        }
    };

    window.addEventListener('message', onWindowMessage);
};
