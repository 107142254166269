import { setCookie } from './helper';

// eslint-disable-next-line no-promise-executor-return
export const timer = (ms: number): Promise<unknown> => new Promise((res) => setTimeout(res, ms));

const resizeInterval = (async (amount: number, callback: (width: number, height: number) => void): Promise<void> => {
    for (let i = 0; i < amount; i += 1) {
        callback(window.innerWidth, window.innerHeight);

        if (i === amount - 1) {
            const date = new Date();
            date.setMonth(date.getMonth() + 1);
            setCookie('chayns_width', window.innerWidth, date);
            setCookie('chayns_height', window.innerHeight, date);
        }
        // eslint-disable-next-line no-await-in-loop
        await timer(50);
    }
});

export default resizeInterval;
