import React, {useMemo, useState} from 'react';
import Icon from '@chayns-components/Icon';
import classNames from 'classnames';

import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styles from './left-bar-icon.module.scss';
import TobitTeamLogo from '../../util-components/TobitTeamLogo';
import PropTypes from "prop-types";

const LeftBarIcon = ({
    icon,
    handleClick,
    barActive,
    darkMode,
    expanded,
    initialLoad,
    showTooltips,
    dragging,
}) => {
    const { icon: iconName, badge, tappId, name } = icon;


    return (
        <div
            className={classNames(styles.iconWrapper, { [styles.barActive]: barActive, [styles.dark]: darkMode, [styles.expanded]: expanded, initialLoad, [styles.showTooltips]: showTooltips, [styles.dragging]: dragging })}
            onClick={() => {
                handleClick(tappId);
            }}
        >
            <div className={styles.backgroundLeftBar}/>
            <div
                className={classNames(styles.iconTextWrapper)}
            >
                {name}
            </div>
            {badge ? <div className={classNames(styles.badge, { [styles.smallBadge]: badge < 10 })}>{badge > 99 ? '99+' : badge}</div> : false}
            <div style={{ marginRight: 5, width: 42, display: 'flex', alignItems: 'center', justifyContent: 'center', flexShrink: 0 }}>
                {' '}
                {(iconName || '').startsWith('https://') ? <img src={iconName} alt="" className={styles.chaynsIdIcon}/> : iconName === 'tobit-team-icon-animated' ? (
                    <div className={classNames(styles.svgContainer, styles.chaynsIcon, { [styles.dark]: true })}>
                        <TobitTeamLogo/>
                    </div>
                ) : (
                    <Icon
                        icon={(iconName || '').startsWith('ts-') ? iconName : `fa-regular ${iconName}`}
                        className={styles.iconAbsolute}
                    />
                )}
            </div>
            <TransitionGroup component={null}>
                {
                    (expanded) ? (
                        <CSSTransition
                            classNames="left-bar-icon-description left-bar-transition"
                            key={`left-bar-smart-client-icons-animation-${name}`}
                            appear
                            timeout={300}
                        >
                            <div>
                                <div className={styles.leftBarIconLabel}>{name}</div>
                            </div>
                        </CSSTransition>
                    ) : false
                }
            </TransitionGroup>
        </div>
    );
};

LeftBarIcon.propTypes = {
    icon: PropTypes.shape({
        icon: PropTypes.string,
        badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        tappId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
    }).isRequired,
    handleClick: PropTypes.func.isRequired,
    barActive: PropTypes.bool,
    darkMode: PropTypes.bool,
    initialLoad: PropTypes.bool,
    expanded: PropTypes.bool,
    showTooltips: PropTypes.bool,
    dragging: PropTypes.bool,
};

LeftBarIcon.defaultProps = {
    barActive: false,
    darkMode: false,
    initialLoad: false,
    expanded: false,
    showTooltips: false,
    dragging: false,
};

export default LeftBarIcon;
