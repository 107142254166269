import { addJsonCallListener, removeJsonCallListener } from './utils/jsonCallListeners';

export default function setAccessTokenChangeListener(data) {
    if (data) {
        const {
            value,
            srcIframe,
            enabled,
            callback,
        } = data;

        if (value?.enabled || enabled) {
            addJsonCallListener(66, value?.callback || callback, srcIframe?.[0] || null);
        } else {
            removeJsonCallListener(66, srcIframe?.[0] || null);
        }
    }
}
