import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVideoType } from '../../utils/helper';
import { setVideoWrapper } from '../../redux-modules/components/actions';
import { getVideoWrapperState } from '../../redux-modules/components/selector';

const VideoWrapper: React.FC = () => {
    const dispatch = useDispatch();
    const videoWrapper = useSelector(getVideoWrapperState);
    const { url } = videoWrapper;

    if (!url) {
        return null;
    }

    return (
        <div
            className="video-wrapper"
            onClick={() => {
                dispatch(setVideoWrapper({}));
            }}
        >
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
                controls
                autoPlay
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <source
                    src={url}
                    type={getVideoType(url)}
                />
            </video>
        </div>
    );
};

export default memo(VideoWrapper);
