import errorHandler from '../call-error-handler';
import executeCall from '../json-native-calls';
import getDefer from '../../../utils/defer';

export default function getSavedIntercomChats(itemId: number): Promise<{ status: { code: number}, data: unknown }> {
    try {
        const defer = getDefer();

        executeCall({
            action: 8,
            data: {
                itemId,
            },
            callback: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                func: defer.resolve,
                executeOnlyOnce: true,
            },
        });

        return defer.promise as Promise<{ status: { code: number}, data: unknown }>;
    } catch (e) {
        return errorHandler(e);
    }
}
