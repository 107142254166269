import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { GetStaticPropsResult, NextPageContext } from 'next';
import '../constants/logger';

import ErrorBoundary from '../components/error-boundary/ErrorBoundary';
import App from '../components/App';
import getInitialPropsServer from '../utils/getInitialPropsServer';
import PageHeader from '../components/next-page/page-header/PageHeader';

type DynamicTypes = {
    chaynsInfo,
    globalData,
    hasLoadingError: boolean,
    loadCcCodesApi: boolean,
};

const Index: React.FC<DynamicTypes> = ({
    chaynsInfo,
    globalData,
    hasLoadingError,
    loadCcCodesApi,
    ...props
}) => (
    <ErrorBoundary
        section="globalDynamic"
    >
        <PageHeader
            loadCcCodesApi={loadCcCodesApi}
            chaynsInfo={chaynsInfo}
            globalData={globalData}
        />
        {!hasLoadingError
            ? (
                <DndProvider backend={HTML5Backend}>
                    <App
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                    />
                </DndProvider>
            ) : (
                <div/>
            )}
    </ErrorBoundary>
);

// eslint-disable-next-line @typescript-eslint/ban-types
type Props = {

};

export const getServerSideProps = (context: NextPageContext): Promise<GetStaticPropsResult<Props>> => getInitialPropsServer(context);

export default Index;
