import { USER_AGENT } from '../../constants/defaults';

import { ApiResult } from '../../types/api';
import logger from '../../constants/logger';

export interface CallingCode {
    code: string
}
const loadUser = async (token: string) : Promise<ApiResult<CallingCode>> => {
    try {
        const url = 'https://webapi.tobit.com/AccountService/v1.0/Chayns/User';

        const requestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'User-Agent': USER_AGENT,
                Authorization: `bearer ${token}`,
            },
        };

        const response = await fetch(url, requestInit);

        if (response.status === 200 || response.status === 201) {
            const result = await response.json();

            return {
                status: response.status,
                result,
            };
        }
        logger().warning({
            message: 'Failed to load user data',
            data: {
                status: response.status,
            },
        });
        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: 'Failed to user data',
            section: 'api/user/index.js',
        }, ex);
        return {
            status: 500,
        };
    }
};

const handleLoadUserData = async (token: string) => {
    const startTS = Date.now();
    const { status, result } = await loadUser(token);

    return {
        status,
        result,
        type: 'userData',
        duration: Date.now() - startTS,
    };
};

export { loadUser, handleLoadUserData };
