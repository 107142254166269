/* eslint-disable @typescript-eslint/no-empty-function */

type CustomDocument = Document & {msHidden?: boolean; webkitHidden?: boolean}

const addOnActivateListener = (cb: (hidden: boolean) => void): () => void => {
    let hidden: string;
    let visibilityChange: string;

    if (typeof document.hidden !== 'undefined') {
        hidden = 'hidden';
        visibilityChange = 'visibilitychange';
    } else if (typeof (document as CustomDocument).msHidden !== 'undefined') {
        hidden = 'msHidden';
        visibilityChange = 'msvisibilitychange';
    } else if (typeof (document as CustomDocument).webkitHidden !== 'undefined') {
        hidden = 'webkitHidden';
        visibilityChange = 'webkitvisibilitychange';
    }

    if (hidden && visibilityChange) {
        const documentCb = () => cb(!document[hidden]);
        document.addEventListener(visibilityChange, documentCb, false);
        return () => document.removeEventListener(visibilityChange, documentCb);
    }
    return () => {};
};

export default addOnActivateListener;
