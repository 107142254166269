import TAPPIDS from '../../constants/tapp-ids';
import { QUOTE_COMMENT } from '../../constants/types';
import getType from './getType';

const openSmartClient = (id: string, type: string, davidSiteId: string, isMyChaynsApp: boolean, answerCommentId?: string): void => {
    const url = (() => {
        const tempUrl = `/david/${davidSiteId}/folders/inbox?id=${id}`;
        if (type) {
            if (type !== QUOTE_COMMENT) {
                return `${tempUrl}&type=${getType(type)}`;
            }
            return `${tempUrl}&answerCommentId=${answerCommentId}`;
        }
        return tempUrl;
    })();

    if (!isMyChaynsApp) {
        if (typeof window !== 'undefined' && window.chayns) {
            if (typeof window.handlePush === 'function') {
                window.history.pushState(null, '', url);
                chayns.selectTapp({
                    id: TAPPIDS.SMART_CLIENT,
                });
                if (typeof window.scAnswerQuoteComment === 'function' && type === QUOTE_COMMENT) {
                    window.scAnswerQuoteComment(id, answerCommentId);
                } else {
                    window.handlePush({
                        retVal: {
                            mailPush: {
                                mail: {
                                    mailId: id,
                                    type,
                                },
                                siteId: davidSiteId,
                            },
                        },
                    });

                    setTimeout(() => {
                        chayns.env.parameters = {
                            id,
                        };
                        chayns.env.parameters.type = getType(type);

                        if (type === QUOTE_COMMENT) {
                            chayns.env.parameters.answerCommentId = answerCommentId;
                        }
                        if(typeof window.handleChaynsDeStateChange === 'function') {
                            window.handleChaynsDeStateChange(id);
                        }
                    }, 100)
                }
            } else {
                window.history.pushState(null, '', url);
                chayns.env.parameters = {
                    id,
                };
                chayns.env.parameters.type = getType(type);

                if (type === QUOTE_COMMENT) {
                    chayns.env.parameters.answerCommentId = answerCommentId;
                }

                chayns.selectTapp({
                    id: TAPPIDS.SMART_CLIENT,
                });
            }
        }
    } else if (typeof window !== 'undefined' && window.chayns) {
        chayns.invokeCall(JSON.stringify({
            action: 271,
            value: {
                siteId: davidSiteId,
                folderId: 'inbox',
                mailId: id,
                type: getType(type),
            },
        }));
    }
};

export {
    openSmartClient,
};
