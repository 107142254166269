import React, { useRef, useCallback, useEffect } from 'react';
import Input from '@chayns-components/Input';
import { useDispatch, useSelector } from 'react-redux';

import {
    setIsSearchingMessages,
    setMessageSearchString,
    setSelectedItem
} from '../../../../../../redux-modules/app/actions';
import { getTextStringState } from '../../../../../../redux-modules/text-strings/selector';
import { clearSearchMails, handleLoadSearchMails } from '../../../../../../redux-modules/smart-client/actions';
import { getAppState } from '../../../../../../redux-modules/app/selector';
import {MOBILE_VIEW_BREAKPOINT} from "../../../../../../constants/size";
import {getEnvState} from "../../../../../../redux-modules/env/selector";

const MessageSearch: React.FC = ({ infocenter }) => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        messageSearchString,
        colorMode,width
    } = app;

    const { isMyChaynsApp } = useSelector(getEnvState);

    const textStrings = useSelector(getTextStringState);

    const timeout = useRef(null);

    useEffect(() => {
        const loadMails = () => {
            if (!messageSearchString) {
                dispatch(setIsSearchingMessages(false));
                return;
            }

            if (messageSearchString.trim().length > 0) {
                dispatch(handleLoadSearchMails({
                    searchString: messageSearchString,
                }));
            } else {
                dispatch(setIsSearchingMessages(false));
            }
        };

        timeout.current = setTimeout(() => {
            loadMails();
        }, 500);
        return () => {
            clearTimeout(timeout.current);
        };
    }, [dispatch, messageSearchString]);

    const onChange = useCallback((value) => {
        dispatch(clearSearchMails());
        dispatch(setSelectedItem(null));
        dispatch(setMessageSearchString(value));
        if (value) {
            dispatch(setIsSearchingMessages(true));
        }
    }, [dispatch]);

    useEffect(() => {
        const searchIcon = document.querySelector('.input-group__icon');
        const handleIconClick = () => {
            const input: HTMLInputElement = document.querySelector('.message-input input');
            if (input) {
                input.focus();
            }
        };

        if (searchIcon) {
            searchIcon.addEventListener('click', handleIconClick);
        }

        return () => {
            if (searchIcon) {
                searchIcon.removeEventListener('click', handleIconClick);
            }
        };
    }, []);

    const color = colorMode !== 'dark' && !isMyChaynsApp ?  'black' : 'white';

    return (
        <>
            <div
                style={{
                    margin: infocenter ? '21px 16px 13px' : '10px 16px 5px'
                }}
            >
                <Input
                    value={messageSearchString}
                    onChange={onChange}
                    icon="fal fa-search"
                    placeholder={textStrings?.['txt_chayns_de_find'] || 'Finden'}
                    className="message-input"
                />
            </div>
            <style jsx global>
                {`
                    .message-input {
                        border-bottom: none;
                        box-sizing: border-box;
                      
                      input {
                        color: ${color} !important;
                      }

                        .input-group__icon {
                            opacity: 1 !important; 
                            font-size: 14px;
                          color: ${color} !important;
                        }

                        ~ label {
                            font-size: 21px !important;
                            opacity: 1 !important;
                            color: ${color} !important;
                            top: unset !important;
                        }
                    }
                `}
            </style>
        </>
    );
};

MessageSearch.displayName = 'MessageSearch';

export default MessageSearch;
