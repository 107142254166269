import { getTappsState } from '../../../redux-modules/tapps/selector';
import { store } from '../../../redux-modules/store';
import {SITE_ID} from "../../../constants/site";

const urlRegex = /https:\/\/chayns\.net\/(?<siteId>\d{5}-\d{5})(?:\/tapp\/(?<tappId>\d+))?(?<appendix>\/.*)?/;

const getUrl = (url) => {
    const match = url.match(urlRegex);
    if (!match) {
        return url;
    }
    const { siteId, appendix } = match.groups;
    let { tappId } = match.groups;
    if (siteId !== SITE_ID) {
        return url;
    }
    const tapps = getTappsState(store.getState());
    if (!tappId) {
        return `https://tobit.team${appendix || ''}`;
    }
    tappId = parseInt(tappId, 10);
    if (!tapps?.some((t) => !t.isHidden && t.tappId === tappId)) {
        return url;
    }
    return `https://${location.host}/tapp/${tappId}${appendix || ''}`;
};

export async function redirectToSite(req, res) {
    if (req.value?.url) {
        location.href = getUrl(req.value.url);
    }
}
