import RESULT_STATUS from '../../constants/native-calls-status';
import logger from "../../constants/logger";

const errorHandler = (e, section = 'NativeCallErrorHandler.', fileName = 'call-error-handler.js') => {
    logger().error({
        message: 'JSONNativeCall error handler.',
        section,
        fileName,
        ex: {
            message: e.message,
            stackTrace: e.stack,
        },
    });

    return Promise.resolve({
        parameter: {},
        data: {},
        status: {
            code: RESULT_STATUS.ERROR,
            description: e.message,
        },
    });
};

export default errorHandler;
