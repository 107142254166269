import React from 'react';
import { useSelector } from 'react-redux';
import ApiV5Tapp from '../tapp/tapp-content/ApiV5Tapp/ApiV5Tapp';
import TAPPIDS from '../../constants/tapp-ids';
import { IntercomWebsocketView } from '../../constants/defaults';
import { getAppState } from '../../redux-modules/app/selector';

export const intercomWsClientObj = {};

const IntercomWebsocketComponent = () => {
    const { loadIntercomWebsocket } = useSelector(getAppState);

    if (!loadIntercomWebsocket) return null;
    return (
        <ApiV5Tapp
            customData={{
                setClient: (v) => {
                    intercomWsClientObj.client = v;
                    window.dispatchEvent(new CustomEvent('loaded-intercom-websocket'));
                },
            }}
            type="client-module"
            tappId={TAPPIDS.INTERCOM}
            system={IntercomWebsocketView}
        />
    );
};

export default IntercomWebsocketComponent;
