export default function selectTab(req) {
    if (typeof window === 'undefined') {
        throw new Error('selectTapp called on server');
    }

    const tappId = req.id || req.value.id;
    const params = req.params || req.value?.params;

    const event = new CustomEvent('set-active-tapp', {
        detail: {
            tappId,
            params,
        },
    });
    window.dispatchEvent(event);
}
