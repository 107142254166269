import React, { useEffect } from 'react';
import ApiV5Tapp from "../../../ApiV5Tapp/ApiV5Tapp";
import TAPPIDS from "../../../../../../constants/tapp-ids";
import { CalendarSystem } from "../../../../../../constants/defaults";
import { useDispatch, useSelector } from "react-redux";
import { getActiveDavidSiteId, getAppState } from "../../../../../../redux-modules/app/selector";
import { loadCalendarStore } from "../../../../../../redux-modules/app/actions";

const SelectedCalendarEvent = () => {

    const dispatch = useDispatch();

    const {selectedItem} = useSelector(getAppState);
    const activeDavidSiteId = useSelector(getActiveDavidSiteId);

    useEffect(() => {
        dispatch(loadCalendarStore(true));
    }, []);

    return (
        <ApiV5Tapp customData={{selectedItem, serverId: activeDavidSiteId, selectedEventContent: true}} type="client-module" tappId={TAPPIDS.CALENDAR} system={CalendarSystem} />
    )
}

export default SelectedCalendarEvent;
