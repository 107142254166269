import format from 'date-fns/format';
import isYesterday from 'date-fns/isYesterday';
import isToday from 'date-fns/isToday';
import parseISO from 'date-fns/parseISO';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import isSameYear from 'date-fns/isSameYear';
import * as Locales from 'date-fns/locale';

import { TextStrings } from '../redux-modules/text-strings/actions';
import getLanguage from './getLanguage';

export const createItemDate = (date: string, textStrings: TextStrings): string => {
    try {
        const dateISO = parseISO(date);
        let convertedDate;
        const lang = getLanguage();

        if (isToday(dateISO)) {
            const minuteDifference = differenceInMinutes(new Date(), dateISO);
            if (minuteDifference > 0 && minuteDifference < 60) {
                convertedDate = `${textStrings?.['txt_chayns_de_ago'] || 'vor'} ${minuteDifference} Min.`;
            } else if (minuteDifference > 0 && minuteDifference < 60 * 24) {
                convertedDate = `${format(dateISO, 'HH:mm', { locale: Locales[lang] })} ${textStrings?.['txt_chayns_de_clock'] || 'Uhr'}`;
            }
        } else if (isYesterday(dateISO)) {
            convertedDate = textStrings?.['txt_chayns_de_yesterday'] || 'Gestern';
        } else if (isSameYear(dateISO, new Date())) {
            convertedDate = format(dateISO, 'dd. MMM.', { locale: Locales[lang] });
        } else {
            convertedDate = format(dateISO, 'dd. MMM. uuuu', { locale: Locales[lang] });
        }
        return convertedDate;
    } catch (error) {
        return '';
    }
};
