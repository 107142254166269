import React, { useMemo, memo, CSSProperties } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Tapp } from '../../../types/chayns';
import TAPPIDS from "../../../constants/tapp-ids";

type TappBtnTypes = {
    active: boolean,
    tapp: Partial<Tapp>,
    handleTappClick: () => void,
    badge: number | string,
    style: CSSProperties,
    activeColor: string,
    isDarkMode: boolean,
    iconColor: string,
    isSmartClientActive: boolean,
    isSmartClientTapp?: boolean,
};

const TappBtn: React.FC<TappBtnTypes> = ({
    active,
    tapp,
    handleTappClick,
    badge,
    style,
    activeColor,
    isDarkMode,
    iconColor,
    isSmartClientActive,
    isSmartClientTapp,
}) => {
    const badgeWidth = useMemo(() => {
        if (badge > 99) {
            return '25px';
        }
        if (typeof badge === 'string') {
            return '30px';
        }
        return '20px';
    }, [badge]);

    const badgeText = useMemo(() => {
        if (isSmartClientActive) {
            return null;
        }
        if (badge > 99) {
            return '99+';
        }
        return badge;
    }, [badge, isSmartClientActive]);

    const icon = tapp.id === TAPPIDS.CHAYNS_SITES ? 'ts-chayns': tapp.icon;
    const name = tapp.id === TAPPIDS.CHAYNS_SITES ? 'chayns® Sites': tapp.name;

    return (
        <>
            <div
                key={tapp.tappId}
                className={classNames('tapp-btn-wrapper', {
                    dark_mode: isDarkMode,
                    smart_client_active: isSmartClientActive,
                    is_smart_client_tapp: isSmartClientTapp,
                })}
                onClick={handleTappClick}
                style={{
                    ...style,
                    color: active ? activeColor : iconColor,
                }}
            >
                <i
                    className={`far ${icon} tapp-btn-wrapper__icon`}
                />

                {(badge > 0 || typeof badge === 'string') && (
                    <div
                        className={classNames('tapp-btn-wrapper__badge', {
                            beta_tag: typeof badge === 'string',
                        })}
                        style={{
                            minWidth: badgeWidth,
                        }}
                    >
                        {badgeText}
                    </div>
                )}

                <div
                    className={classNames('tapp-btn-wrapper__name', {
                        visible: !isSmartClientActive,
                    })}
                >
                    {name}
                </div>
            </div>
            <style jsx>
                {`
                    .tapp-btn-wrapper {
                        position: relative;
                        text-align: center;
                        max-width: 95px;
                        cursor: pointer;
                        padding-top: 5px;
                        height: 100%;
                        flex-direction: column;

                        &:hover {
                            background-color: rgba(var(--chayns-color-rgb--primary), 0.2);
                        }

                        &.dark_mode {
                            &:hover {
                                background-color: rgba(255, 255, 255, .1) !important;
                            }
                        }

                        &.smart_client_active {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding-top: 0;

                            .tapp-btn-wrapper__icon {
                                margin-top: 0;
                                font-size: 18px;
                                padding-top: 5px;
                            }

                            .tapp-btn-wrapper__badge {
                                min-width: 8px !important;
                                height: 8px;
                                line-height: 1.4;
                                left: 19px;
                                font-size: 8px;
                                top: 2px;
                            }
                        }

                        &.is_smart_client_tapp {
                            .tapp-btn-wrapper__icon {
                                margin-top: 3px;
                                font-size: 22px;
                            }
                        }

                        &__icon {
                            margin-top: 10px;
                            position: relative;
                            font-size: 30px;
                            transition: all .3s;
                        }

                        &__name {
                            text-overflow: ellipsis;
                            word-break: break-word;
                            white-space: nowrap;
                            overflow: hidden;
                            font-size: 13px;
                            margin: auto;
                            width: 100%;
                            margin-top: 8px;

                            &.visible {
                                margin: auto;
                                margin-top: -7px;
                            }
                        }

                        &__badge {
                            width: fit-content;
                            min-width: 20px;
                            text-align: center;
                            justify-content: center;
                            font-size: 80%;
                            height: 20px;
                            background-color: #E71E28;
                            position: absolute;
                            top: 5px;
                            color: white;
                            border-radius: 20px;
                            line-height: 1.7;
                            margin: auto;
                            left: 40px;
                            right: 0px;
                            transition: all .3s;
                            
                            &.beta_tag {
                              padding: 0 3px;
                            }
                        }

                        &__btn {
                            margin-right: 5px;
                        }
                    }
                `}
            </style>
        </>
    );
};

TappBtn.propTypes = {
    active: PropTypes.bool.isRequired,
    handleTappClick: PropTypes.func.isRequired,
    badge: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    style: PropTypes.shape({
        width: PropTypes.string,
    }),
    activeColor: PropTypes.string.isRequired,
    isDarkMode: PropTypes.bool.isRequired,
    iconColor: PropTypes.string.isRequired,
    isSmartClientActive: PropTypes.bool,
    isSmartClientTapp: PropTypes.bool,
};

TappBtn.defaultProps = {
    style: null,
    isSmartClientActive: false,
    isSmartClientTapp: false,
    badge: null,
};

TappBtn.displayName = 'TappBtn';

export default memo(TappBtn);
