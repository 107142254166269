/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentType, memo, ElementType, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

type TabsTypes <P = unknown> = {
    TabBodyComponent: ComponentType<P> | ElementType,
    count: number,
    bodyProps: unknown,
  } & P

const Tabs: React.FC<TabsTypes> = ({
    count,
    TabBodyComponent,
    bodyProps,
}) => {
    const elementRef = useRef(null);
    const [currentIndex] = useState(0);

    const tempArray = useMemo(() => new Array(count).fill(0), [count]);

    return (
        <div
            style={{
                overflow: 'hidden',
                width: '100%',
            }}
        >
            <div
                ref={elementRef}
                style={{
                    display: 'flex',
                    width: '100%',
                    overflow: 'visible',
                }}
            >
                {tempArray.map((_, index) => (
                    <div
                        style={{
                            width: '100%',
                            margin: '0 2px',
                        }}
                        key={index}
                    >
                        <TabBodyComponent
                            {...bodyProps}
                            isSelected={currentIndex === index}
                            index={index}
                            noMargin
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

Tabs.displayName = 'Tabs';

Tabs.propTypes = {
    count: PropTypes.number.isRequired,
};

Tabs.defaultProps = {

};

export default memo(Tabs);
