import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import { getTextStringState } from '../../redux-modules/text-strings/selector';
import { TEXT_STRING_FALLBACKS } from '../../constants/textstrings';

const useGetTextString = () => {
    const textStrings = useSelector(getTextStringState);

    const getTextString = useCallback((name: string): string | undefined => textStrings?.[name] || TEXT_STRING_FALLBACKS[name], [textStrings]);

    const getChaynsDeTextString = useCallback((name: string): string | undefined => getTextString(`txt_chayns_de_${name}`), [getTextString]);

    const getTobitTeamTextString = useCallback((name: string): string | undefined => getTextString(`txt_tobit_team_runtime_${name}`), [getTextString]);

    return {
        getTextString,
        getChaynsDeTextString,
        getTobitTeamTextString,
    };
};

export default useGetTextString;
