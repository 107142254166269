import React, { memo } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { getEnvState } from '../../../../../../../redux-modules/env/selector';
import { getAppState } from '../../../../../../../redux-modules/app/selector';

type PersonPlaceholderProps = {
    isHidden?: boolean,
};

const PersonPlaceholder: React.FC<PersonPlaceholderProps> = ({
    isHidden,
}) => {
    const app = useSelector(getAppState);
    const {
        davidDialogTitleColor,
    } = app;

    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
    } = env;

    const squareImgWrapperClassnames = classnames('infocenter__square__img-wrapper');

    const squareImgClassnames = classnames('infocenter__square__img', 'chayns__background-color--002');

    const squareClassnames = classnames('infocenter__square', 'fadeInUp');

    return (
        <>
            <div
                className={squareClassnames}
                style={{
                    opacity: isHidden ? '0' : undefined,
                }}
            >
                <div
                    className={squareImgWrapperClassnames}
                >
                    <img
                        className={squareImgClassnames}
                        alt=""
                    />
                </div>
                <div
                    className={classnames('infocenter__square__subline', {
                        'small-font': isMyChaynsApp,
                    })}
                >
                    <p
                        className="subline-paragraph"
                    />
                </div>
            </div>
            <style jsx>
                {`
                .infocenter__square {
                        user-select: none;
                        margin-bottom: 1vh;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        overflow: visible;

                        &__img-wrapper {
                            height: 60px;
                            width: 60px;
                            position: relative;
                            display: inline-block;
                            transition: all .3s;
                            background-color: ${davidDialogTitleColor || 'rgba(var(--chayns-color-rgb--002), 1)'};
                            border-radius: 50%;

                            &:after {
                                content: '';
                                padding-top: 100%;
                                display: block;
                            }
                        }

                        &__img {
                            position: absolute;
                            background-position: center;
                            background-size: cover;
                            background-color: rgba(var(--chayns-color-rgb--text), .1);
                            top: 0;
                            right: 0;
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            border-radius: 50%;
                        }

                        &__subline {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: block;
                            width: 100%;
                            text-align: center;
                            padding-top: 5px;
                            height: 45px;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            line-height: 1.4;
                            width: 90%;

                            &.small-font {
                                font-size: 12px;
                                height: 50px;
                            }

                            .subline-paragraph {
                                width: 100%;
                                height: 15px;
                                background-color: ${davidDialogTitleColor || 'rgba(var(--chayns-color-rgb--002), 1)'};
                            }
                        }
                    }
            `}
            </style>
        </>
    );
};

PersonPlaceholder.defaultProps = {
    isHidden: false,
};

PersonPlaceholder.displayName = 'PersonPlaceholder';

export default memo(PersonPlaceholder);
