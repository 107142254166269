import { useCallback, useEffect } from 'react';

import { setBoundings } from '../../redux-modules/app/actions';
import { Action } from '../../types/action';
import { setCookie } from '../helper';

import resizeInterval from '../resizeInterval';

const useResizer = (dispatch: (value: Action) => void) : void => {
    const dispatchResize = useCallback((w, h) => {
        dispatch(setBoundings({
            width: w,
            height: h,
        }));
    }, [dispatch]);

    const handleResize = useCallback(({ target }) => {
        resizeInterval(5, dispatchResize);
        dispatchResize(target.innerWidth, target.innerHeight);
    }, [dispatchResize]);

    const handleOrientationChange = useCallback(() => {
        const afterOrientationChange = () => {
            resizeInterval(5, dispatchResize);
            dispatchResize(window.innerWidth, window.innerHeight);
            window.removeEventListener('resize', afterOrientationChange);
        };
        resizeInterval(5, dispatchResize);
        window.addEventListener('resize', afterOrientationChange);
    }, [dispatchResize]);

    useEffect(() => {
        const date = new Date();
        date.setMonth(date.getMonth() + 1);
        setCookie('chayns_width', window.innerWidth, date);
        setCookie('chayns_height', window.innerHeight, date);
        dispatchResize(window.innerWidth, window.innerHeight);

        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleOrientationChange);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, [dispatchResize, handleOrientationChange, handleResize]);
};

export default useResizer;
