import TAPPIDS from '../constants/tapp-ids';
import { TextStrings } from '../redux-modules/text-strings/actions';
import { Badge } from '../types/badge';
import { Tapp } from '../types/tapp';
import { isValidTapp } from "./tapp/invalidTapps";

const setInitialTapps = (loadedTapps: Array<Partial<Tapp>>, badges: Array<Badge>, isSmartClientAvailable: boolean, textStrings: TextStrings, isCalendarAvailable: boolean) : Array<Tapp> => {
    const tapps = [];

    loadedTapps.forEach((tapp) => {
        if (tapp.isHidden || !isValidTapp(tapp)) return;
        let tappToAdd = tapp;
        const tappId = tapp.id;

        if (tappId === TAPPIDS.CALENDAR && !isCalendarAvailable) {
            tappToAdd = null;
        } else if (tappId === TAPPIDS.SMART_CLIENT && !isSmartClientAvailable) {
            tappToAdd = null;
        }

        if (tappToAdd) {
            tappToAdd.tappId = tappId;
            tappToAdd.id = tappId;
            tappToAdd.badge = parseInt(badges?.find((tapp: Badge) => tapp.tappId === tappId)?.text?.value, 10) || 0;
            tappToAdd.name = tappToAdd.showNames?.de || '';
            tappToAdd.icon = tappToAdd.icon?.value || '';
            tapps.push(tappToAdd);
        }
    });

    loadedTapps.forEach((tapp) => {
        if (tapp.isHidden && isValidTapp(tapp)) {
            tapps.push({
                ...tapp,
                badge: parseInt(badges?.find((t: Badge) => t.tappId === tapp.id)?.text?.value, 10) || 0,
                name: tapp.showNames?.de || '',
                icon: tapp.icon?.value || null,
                tappId: tapp.id,
            });
        }
    });

    return tapps;
};

export default setInitialTapps;
