import React, { memo, useCallback, useEffect, useRef, useMemo } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import ErrorBoundary from '../../../error-boundary/ErrorBoundary';
import { MOBILE_VIEW_BREAKPOINT } from '../../../../constants/size';
import OverlayElement from './overlay-element/OverlayElement';
import SelectView from './select-view/SelectView';
import { getAppBottomBarHeight, getAppState } from '../../../../redux-modules/app/selector';
import { getEnvState } from '../../../../redux-modules/env/selector';
import { setIsChaynsIconInHeader } from '../../../../redux-modules/app/actions';
import { getDraggedSiteState, getIgnoreFocusedState } from '../../../../redux-modules/sites/selector';
import { setIgnoreFocused } from '../../../../redux-modules/sites/actions';
import WalletHotCards from "./wallet-hotcards/WalletHotCards";
import { getSearchStringState } from "../../../../redux-modules/search/selector";
import { getTextStringState } from "../../../../redux-modules/text-strings/selector";
import Search from "./search/Search";

const SitesTapp: React.FC = () => {
    const dispatch = useDispatch();
    const textStrings = useSelector(getTextStringState);

    const app = useSelector(getAppState);
    const {
        activeTapp,
        colorMode,
        width,
        sitesBackgroundColor,
        hideOtherContent,
        isChaynsIconInHeader,
        selectedItem,
    } = app;

    const env = useSelector(getEnvState);
    const {
        isMobileBrowser,
        isMyChaynsApp,
    } = env;

    const searchString = useSelector(getSearchStringState);

    const draggedSite = useSelector(getDraggedSiteState);

    const ignoreFocused = useSelector(getIgnoreFocusedState);

    const handleSetIgnoredFocus = (value) => {
        dispatch(setIgnoreFocused(value));
    };

    const sortableRef = useRef(null);

    useEffect(() => {
        sortableRef.current = document.querySelector('.sortable-drag');
        if (draggedSite && sortableRef.current) {
            if (ignoreFocused) {
                sortableRef.current.style.opacity = 0;
            } else {
                sortableRef.current.style.opacity = 0.8;
            }
        }
    }, [ignoreFocused, draggedSite]);

    const appBottomBarHeight = useSelector(getAppBottomBarHeight);

    const onTouchMove = useCallback((evt) => {
        if (draggedSite) {
            const xCords = [window.innerWidth / 2 - 40, window.innerWidth / 2 + 40];
            const xPos = evt.targetTouches?.[0]?.clientX || evt.clientX;
            const yPos = evt.targetTouches?.[0]?.clientY || evt.clientY;
            const dif = isMyChaynsApp ? (120 + appBottomBarHeight) : 180;
            if (yPos > (window.innerHeight - dif) && (xPos > xCords[0] && xPos < xCords[1])) {
                handleSetIgnoredFocus(true);
            } else {
                handleSetIgnoredFocus(false);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [draggedSite, isMyChaynsApp, appBottomBarHeight]);

    useEffect(() => {
        window.addEventListener('mousemove', onTouchMove);
        return () => {
            window.removeEventListener('mousemove', onTouchMove);
        };
    }, [onTouchMove]);

    useEffect(() => {
        if (isChaynsIconInHeader) {
            dispatch(setIsChaynsIconInHeader(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTapp, dispatch]);

    const backgroundColor = useMemo(() => {
        if (sitesBackgroundColor) {
            return sitesBackgroundColor;
        }
        if (!isMyChaynsApp && !isMobileBrowser && width >= MOBILE_VIEW_BREAKPOINT) {
            if (colorMode === 'dark') {
                return 'rgb(30, 30, 30)';
            }
            return 'white';
        }
        return '';
    }, [colorMode, isMobileBrowser, isMyChaynsApp, sitesBackgroundColor, width]);

    const wrapperClassnames = classnames('chayns__color--text', {
        sites_mobile: isMyChaynsApp,
        sites_wrapper: !isMyChaynsApp,
        mobile_browser: isMobileBrowser,
        desktop_view: width >= MOBILE_VIEW_BREAKPOINT && !hideOtherContent && !isMyChaynsApp,
        disable_scroll: !!selectedItem && width >= MOBILE_VIEW_BREAKPOINT,
        'hide-other-content': hideOtherContent,
    });

    const showHotCards = isMyChaynsApp && !searchString;

    return (
        <>
            <div
                className={wrapperClassnames}
                onTouchMove={onTouchMove}
                style={{
                    backgroundColor,
                }}
            >
                <OverlayElement/>
                { showHotCards ? <WalletHotCards/> : null }

                {!isMyChaynsApp && width > MOBILE_VIEW_BREAKPOINT ? <div className="top-wrapper">
                    <Search/>
                </div> : null}
                {(textStrings.txt_chayns_de_springboard_input_description_v4 !== ' ' ? <div className="search-footer-wrapper2">
                    <div onClick={() => {
                        window.open(textStrings.txt_chayns_de_springboard_input_description_v4_url);
                    }}
                    >
                        <a className="long">
                            {textStrings.txt_chayns_de_springboard_input_description_v4}
                        </a>
                        <a className="short">
                            {textStrings.txt_chayns_de_springboard_input_description_v4_short}
                        </a>
                    </div>
                </div> : null)}

                <ErrorBoundary
                    section="selectView"
                >
                    <SelectView/>
                </ErrorBoundary>

            </div>
            <style jsx global>
                {`

                  @media (orientation: landscape) {
                    .search-footer-wrapper2 {
                      .short {
                        display: none;
                      }
                    }
                  }

                  @media (orientation: portrait) {
                    .search-footer-wrapper2 {
.long {
  display: none;
}
                    }
                  }
                  .search-footer-wrapper2 {
                    display: flex;
                    justify-content: center;
                    position: relative;
                    margin-bottom: ${width > MOBILE_VIEW_BREAKPOINT ? 0 : 25}px;
                    margin-top: -12px;
                  }
                  .top-wrapper {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    margin-top: 2.5rem;
                  }

                  .sortable-drag {
                    transition: opacity .1s ease-in-out;
                  }

                  .sites_wrapper {
                    width: 100%;
                    height: 100%;
                    scrollbar-width: none;

                    &::-webkit-scrollbar-track {
                      display: none;
                    }

                    &::-webkit-scrollbar-thumb {
                      display: none;
                    }

                    &::-webkit-scrollbar {
                      width: 0px;
                      display: none;
                    }

                    &.desktop_view {
                        overflow-y: scroll;
                        position: relative;
                        flex: 1;
                        box-shadow: 1px 2px 4px #0000001a;

                      .top-wrapper {
                            margin-top: 67px;
                        }
                    }

                    &.disable_scroll {
                      overflow-y: hidden !important;
                    }
                  }

                  .sites_mobile {
                    padding-top: 15px;
                    width: 100%;
                  }

                  .desktop_view {
                    overflow-y: scroll !important;
                    max-height: 100%;
                  }

                  .hide-other-content {
                        overflow-y: scroll !important;
                        max-height: 100vh;
                  }

                  .center_wait_cursor {
                    text-align: center;
                    padding-top: 50px;
                  }

                  .error_text {
                    text-align: center;
                    color: white;
                  }
                `}
            </style>
        </>
    );
};

SitesTapp.displayName = 'SitesTapp';

export default memo(SitesTapp);
