import React from 'react';
import PropTypes from 'prop-types';
import {CUSTOM_HOVER_COLOR_STYLE} from "../../../../constants/colors";

type TappItemTypes = {
    name: string,
    icon: string,
    onClick: (evt: React.MouseEvent<HTMLElement>) => void,
};

const TappItem: React.FC<TappItemTypes> = ({
    name,
    icon,
    onClick,
}) => (
    <>
        <div className="tapp-item" onClick={onClick}>
            <div className="tapp-item__icon__wrapper">
                <i
                    className={`far ${icon} tapp-item__icon__wrapper__icon`}
                />
            </div>
            <div className="tapp-item__name">{name}</div>
        </div>
        <style jsx>
            {`
                .tapp-item {
                    position: relative;
                    display: flex;
                    padding: 8px;
                    cursor: pointer;
                    height: 30px;

                    &:hover {
                        background-color: ${CUSTOM_HOVER_COLOR_STYLE};
                    }

                    &__icon__wrapper {
                        font-size: 24px;
                        color: grey;
                        align-self: center;
                        text-align: center;
                        width: 27px;
                    }

                    &__name {
                        padding-left: 10px;
                        font-size: 15px;
                        align-self: center;
                    }
                }
            `}
        </style>
    </>
);

TappItem.displayName = 'TappItem';

TappItem.propTypes = {
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default TappItem;
