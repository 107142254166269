import logFetch from '../../../utils/logFetch';
import logger from "../../../constants/logger";

const reportSite = async (siteId: string, reason: string) : Promise<{ status: number, result?: {[key: string]: string}}> => {
    try {
        const logOptions = {
            fileName: __filename,
            section: '0',
            message: 'POST report site',
        };

        const requestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${typeof chaynsInfo !== 'undefined' ? chaynsInfo.User.TobitAccessToken : chayns.env.user.tobitAccessToken}`,
            },
            body: JSON.stringify({
                siteId,
                reason,
            }),
        };

        const response = await logFetch('https://webapi.tobit.com/dataagg/v1.0/Abuse/Site', requestInit, logOptions);

        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: 'Failed to report site',
            section: 'api/sites/post/reportSite.js',
        }, ex);
        return {
            status: 500,
        };
    }
};

export { reportSite };
