import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getTextStringState } from '../../redux-modules/text-strings/selector';
import logger from "../../constants/logger";

const useTwoFactorPushConfirm = ({ isChaynsReady, isMyChaynsApp }) => {
    const textStrings = useSelector(getTextStringState);

    const dialogOpenRef = useRef(1);

    const handlePush = useCallback(async (p) => {
        logger().info({
            message: 'push',
            data: p,
        });
        if (!p || !p.push) return;
        if (p.actionId !== -1 || (p.push.payload?.tappId !== '393112' && p.push.payload?.tappId !== 393112)) return; // android tappId = string, ios tappId = int
        if (!p.push.customPayload || !p.push.customPayload.sessionId || !p.push.customPayload.code) return;
        if (p.push.customPayload.code === dialogOpenRef.current) return;
        dialogOpenRef.current = p.push.customPayload.code;
        const locationData = await (await fetch(`https://chaynssvc.tobit.com/v0.5/${p.push.customPayload.locationId}/locationsettings/raw?fields=locationName`)).json();
        const buttonResult = await chayns.dialog.confirm('', textStrings.txt_chayns_de_two_factor_confirm_dialog.replace('%%site%%', locationData.locationName));
        if (buttonResult !== 1) return;
        fetch('https://auth.tobit.com/v2/2fa/remote/confirm', {
            method: 'POST',
            body: JSON.stringify({
                sessionId: p.push.customPayload.sessionId,
                confirmationCode: p.push.customPayload.code,
            }),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                authorization: `Bearer ${chayns.env.user.tobitAccessToken}`,
            },
        });
        dialogOpenRef.current = 1;
    }, []);

    useEffect(() => {
        if (isChaynsReady && textStrings && isMyChaynsApp) {
            chayns.event.addPushListener(handlePush);
        }
        return () => {
            if (isChaynsReady) chayns.event.removePushListener(handlePush);
        };
    }, [isChaynsReady, textStrings, isMyChaynsApp]);
};

export default useTwoFactorPushConfirm;
