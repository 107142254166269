export const LIB_NAMES = ['chayns-de', 'tobit-team-runtime'];

const FALLBACKS = {};

const CHAYNS_DE_FALLBACKS = {
    login: 'Anmelden',
};

const TOBIT_TEAM_RUNTIME_FALLBACKS = {
    login_info: 'Unified Information. Unleashed Performance. <strong>Team/David</strong><span class="login-info__text__registered-trademark"><strong>®</strong></span>',
    app_link: 'Tobit.Team für ##PLATFORM##',
    more_team_david: 'Mehr über Team/David',
};

export const TEXT_STRING_FALLBACKS = {
    ...FALLBACKS,
    ...Object.keys(CHAYNS_DE_FALLBACKS).reduce((acc, key) => ({ ...acc, [`txt_chayns_de_${key}`]: CHAYNS_DE_FALLBACKS[key] }), {}),
    ...Object.keys(TOBIT_TEAM_RUNTIME_FALLBACKS).reduce((acc, key) => ({ ...acc, [`txt_tobit_team_runtime_${key}`]: TOBIT_TEAM_RUNTIME_FALLBACKS[key] }), {}),
};
