import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getEnvState } from '../../redux-modules/env/selector';
import { getAppState } from '../../redux-modules/app/selector';

const useBottomBarHeight = () => {
    const app = useSelector(getAppState);
    const {
        isChaynsReady,
    } = app;
    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
    } = env;

    useEffect(() => {
        if (isMyChaynsApp && isChaynsReady) {
            window.hotCardsHeightCallback = (data) => {
                document.body.style.setProperty('--bottom-bar-height', `${data?.retVal?.height ?? 0}px`);
                if (!document.body.classList.contains('bottom-bar-height')) document.documentElement.classList.add('bottom-bar-height');
            };
            chayns.invokeCall({
                action: 289,
                value: {
                    callback: 'window.hotCardsHeightCallback',
                    action: 0,
                },
            });
        }
    }, [isChaynsReady]);
};

export default useBottomBarHeight;
