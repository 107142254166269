import { addApiListener, dispatchApiEvent, removeApiListener } from './apiListenerHelper';
import appCall from './appCall';

export function addToolbarChangeListener(callback) {
    const { shouldInitialize, id } = addApiListener('toolbarChange', callback);

    if (shouldInitialize) {
        appCall(293, {}, {
            callback: ({ isVisible, toolbarHeight }) => {
                dispatchApiEvent('toolbarChange', {
                    isVisible,
                    toolbarHeight,
                });
            },
        });
    }
    return id;
}

export const removeToolbarChangeListener = (callback) => {
    removeApiListener('toolbarChange', callback);
};
