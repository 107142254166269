import logFetch from '../../utils/logFetch';
import logger from "../../constants/logger";

const checkVoucherCode = async (code: string): Promise<{ status: number }> => {
    try {
        const logOptions = {
            fileName: __filename,
            section: '0',
            message: 'GET check voucher code',
        };

        const requestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${(typeof chaynsInfo !== 'undefined' && chaynsInfo.User?.TobitAccessToken) || (typeof chayns !== 'undefined' && chayns.env.user.tobitAccessToken)}`,
            },
        };

        const response = await logFetch(`https://webapi.tobit.com/PaymentProcessors/opm/coupon?couponNumber=${code}`, requestInit, logOptions);

        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: 'Failed to load checkVoucherCode',
            section: 'api/voucher/index.ts',
        }, {
            message: ex?.message,
        });
        return {
            status: 500,
        };
    }
};

export { checkVoucherCode };
