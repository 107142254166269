import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import LeftBar from './LeftBar';
import { getActiveDavidSiteId, getActiveSmartClientTapp, getAppState } from '../../redux-modules/app/selector';
import { getTappsState } from '../../redux-modules/tapps/selector';
import TAPPIDS from '../../constants/tapp-ids';
import { setActiveSmartClientTapp, setActiveTapp } from '../../redux-modules/app/actions';
import { INBOX } from '../../constants/types';

const LeftBarWrapper = ({ handleLoadTapp }) => {
    const app = useSelector(getAppState);
    const {
        colorMode,
        activeTapp,
        expandedMenu,
    } = app;

    const isFirst = useRef(true);
    const tapps = useSelector(getTappsState);
    const activeSmartClientTapp = useSelector(getActiveSmartClientTapp);
    const activeDavidSiteId = useSelector(getActiveDavidSiteId);

    const dispatch = useDispatch();

    const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);

    const tappIcons = useMemo(() => {
        const icons = [];
        tapps.filter((x) => !x.isHidden).forEach((tapp) => {
            if (tapp.id === TAPPIDS.SMART_CLIENT) {
                tapp.children.forEach((t2) => {
                    if (t2.identifier === INBOX) {
                        icons.push({
                            icon: t2.icon?.value,
                            tappId: INBOX,
                            badge: tapps.find((x) => x.tappId === TAPPIDS.SMART_CLIENT)?.badge,
                            name: t2.showNames?.de,
                            smartClientTapp: true,
                        });
                    } else {
                        icons.push({
                            icon: t2.icon?.value,
                            tappId: t2.identifier,
                            badge: t2.badge,
                            name: t2.showNames?.de,
                            smartClientTapp: true,
                        });
                    }
                });
            } else if (tapp.tappId === TAPPIDS.INFO_CENTER) {
                icons.push({
                    icon: 'tobit-team-icon-animated',
                    tappId: TAPPIDS.INFO_CENTER,
                    badge: '',
                    name: 'InfoCenter',
                });
            } else if (tapp.tappId === TAPPIDS.CHAYNS_SITES) {
                icons.push({
                    icon: 'ts-chayns',
                    tappId: TAPPIDS.CHAYNS_SITES,
                    badge: '',
                    name: 'chayns® Sites',
                });
            } else {
                icons.push({
                    icon: tapp.icon,
                    tappId: tapp.tappId,
                    badge: tapp.badge,
                    name: tapp.name,
                });
            }
        });
        const sidekickIndex = icons.findIndex(icon => icon.tappId === TAPPIDS.SIDEKICK);
        if (sidekickIndex >= 0) {
            icons.splice(1, 0, icons.splice(sidekickIndex, 1)[0]);
        }
        return icons;
    }, [tapps]);

    useEffect(() => {
        if (isFirst.current) {
            isFirst.current = false;
        } else if (activeTapp === TAPPIDS.SMART_CLIENT && typeof window.handleSetSelection === 'function') {
            window.handleSetSelection({
                folderId: activeSmartClientTapp,
                davidSiteId: activeDavidSiteId,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSmartClientTapp, activeTapp, activeDavidSiteId]);

    return (
        <div style={{ marginTop: 10 }}>
            <LeftBar
                handleClick={handleLoadTapp}
                darkMode={isDarkMode}
                icons={tappIcons}
                activeTapp={activeTapp}
                handleSmartClientButtonClick={(tappId) => {
                    dispatch(setActiveTapp(TAPPIDS.SMART_CLIENT));
                    dispatch(setActiveSmartClientTapp(tappId));
                }}
                activeSmartClientTapp={activeSmartClientTapp}
                expandedMenu={expandedMenu}
            />
        </div>
    );
};

LeftBarWrapper.propTypes = {
    handleLoadTapp: PropTypes.func.isRequired,
};

export default LeftBarWrapper;
