import { getWindowMetrics, WindowMetrics } from '../../../utils/helper';

export default function getWindowMetricsCall(req: { callback: () => void, addJSONParam:unknown, value: { callback: () => void, addJSONParam: unknown }}, res: { answer: (windowMetrics: WindowMetrics) => void }): void {
    const windowMetrics = getWindowMetrics();
    if (res?.answer) {
        res.answer(windowMetrics);
    } else {
        const params = {
            callback: req.callback || req.value.callback,
            addJSONParam: req.addJSONParam || (req.value && req.value.addJSONParam) || {},
            retVal: windowMetrics || {},
        };

        const frame = res[0];
        if (frame && typeof frame.postMessage === 'function') {
            frame.postMessage(`${res[1] || 'chayns.customTab.'}jsoncall:${JSON.stringify(params)}`, '*');
        }
    }
}
