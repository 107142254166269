import { SITES_URL } from '../../../constants/defaults';

import logFetch from '../../../utils/logFetch';
import logger from '../../../constants/logger';

const sortSites = async (body: { id: number, moveBefore?: number, moveAfter?: number }): Promise<{ status: number }> => {
    try {
        const logOptions = {
            fileName: __filename,
            section: '0',
            message: 'PATCH sort sites',
        };

        const requestInit = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${typeof chaynsInfo !== 'undefined' ? window.chaynsInfo.User.TobitAccessToken : chayns.env.user.tobitAccessToken}`,
            },
            body: JSON.stringify(body),
        };

        const response = await logFetch(`${SITES_URL}Items/Move`, requestInit, logOptions);

        return {
            status: response?.status ?? 204,
        };
    } catch (ex) {
        logger().warning({
            message: 'Failed to sort sites',
            section: 'api/sites/patch/sortSites.js',
        }, {
            message: ex?.message,
        });
        return {
            status: 500,
        };
    }
};

export { sortSites };
