import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import Bubble from '@chayns-components/Bubble';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import ManagedAccountsContent from './managed-accounts-content/ManagedAccountsContent';
import ErrorBoundary from '../../error-boundary/ErrorBoundary';
import { getEnvState } from '../../../redux-modules/env/selector';
import appCall from '../../../utils/appCall';
import { getAppState } from '../../../redux-modules/app/selector';
import TAPPIDS from "../../../constants/tapp-ids";

const propTypes = {
    user: PropTypes.shape({
        personId: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        userId: PropTypes.number,
        token: PropTypes.string,
    }),
    mainUser: PropTypes.shape({
        personId: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        userId: PropTypes.number,
        token: PropTypes.string,
    }).isRequired,
    onUserSwitch: PropTypes.func.isRequired,
};

const ManagedAccounts: React.FC<InferProps<typeof propTypes>> = ({
    user,
    onUserSwitch,
    mainUser,
}) => {
    const [showMenu, setShowMenu] = useState(false);
    const [managedAccounts, setManagedAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);

    const app = useSelector(getAppState);
    const {
        colorMode,
    } = app;
    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
        appWithBlueHeader,
    } = env;

    const managedAccountsRef = useRef(null);
    const bubbleRef = useRef(null);

    useEffect(() => {
        if (bubbleRef.current) {
            if (showMenu) {
                bubbleRef.current.show();
            } else {
                bubbleRef.current.hide();
            }
        }
    }, [showMenu]);

    const checkParentNodes = useCallback((clickTarget, count = 10) => {
        if (!clickTarget) {
            return false;
        }
        if (clickTarget.classList.contains('managed_accounts')) {
            return true;
        }
        if (count > 0 && clickTarget !== document.body) {
            return checkParentNodes(clickTarget.parentNode, count - 1);
        }
        return false;
    }, []);

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (showMenu && !checkParentNodes(e.target)) {
                setShowMenu(false);
            }
        };

        const handleBlur = () => {
            if (showMenu) {
                setShowMenu(false);
            }
        };

        window.addEventListener('click', handleClick);
        window.addEventListener('blur', handleBlur);

        return () => {
            window.removeEventListener('click', handleClick);
            window.removeEventListener('blur', handleBlur);
        };
    }, [checkParentNodes, showMenu]);

    const handleLoadManagedAccounts = useCallback(async () => {
        try {
            if (isMyChaynsApp) {
                const { guardedAccounts } = await appCall(285);
                setManagedAccounts(guardedAccounts?.map((x) => ({ firstname: x.firstname || x.firstName, lastname: x.lastname || x.lastName, child: x.personId })) || []);
                setIsLoading(false);
                return;
            }

            const response = await fetch(`https://webapi.tobit.com/accountservice/v1.0/chayns/guardian?parent=${mainUser.personId}&withNames=true`, {
                method: 'GET',
                headers: {
                    Authorization: `bearer ${mainUser.token}`,
                },
            });

            if (response.status === 200) {
                const result = await response.json();
                setManagedAccounts(result);
            }
        } catch (error) {
            // ignore
        }
        setIsLoading(false);
        setHasLoaded(true);
    }, [isMyChaynsApp, mainUser.personId, mainUser.token]);

    useEffect(() => {
        window.addEventListener('renew-token', handleLoadManagedAccounts);

        return () => {
            window.removeEventListener('renew-token', handleLoadManagedAccounts);
        };
    }, [handleLoadManagedAccounts]);

    useEffect(() => {
        if (!hasLoaded && !isLoading && mainUser.token) {
            setIsLoading(true);
            handleLoadManagedAccounts();
        }
    }, [handleLoadManagedAccounts, hasLoaded, isLoading, mainUser.token, showMenu]);

    useEffect(() => {
        const handleUpdateNav = () => {
            setIsLoading(true);
            handleLoadManagedAccounts();
        };
        window.addEventListener('update-navigation', handleUpdateNav);

        return () => {
            window.removeEventListener('update-navigation', handleUpdateNav);
        };
    }, [handleLoadManagedAccounts]);

    const hideMenu = () => {
        setShowMenu(false);
    };

    if (!user) {
        return null;
    }

    const {
        firstname,
        userId,
    } = user;

    return (
        <>
            <ErrorBoundary
                section="managedAccount"
            >
                <div className="click_overlay"/>
                <div
                    className={clsx('managed_accounts', { appWithBlueHeader, dark: colorMode === 'dark'})}
                    onClick={() => {
                        if (managedAccounts.length === 0) {
                            chayns.selectTapp({ id: TAPPIDS.CHAYNS_ID });
                        } else {
                            setShowMenu(!showMenu);
                        }
                    }}
                    ref={managedAccountsRef}
                >
                    <p className="user-name chayns__color--text">
                        {firstname || ''}
                    </p>
                    {(
                        <img
                            className="user-image no-margin"
                            src={`https://sub60.tobit.com/u/${userId}?size=200`}
                            alt=""
                        />
                    )}

                    <Bubble
                        coordinates={{ x: NaN, y: 35 }}
                        parent={managedAccountsRef.current}
                        key="bubble"
                        ref={bubbleRef}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        position={Bubble.position.BOTTOM_LEFT}
                    >
                        <ManagedAccountsContent
                            user={user}
                            managedAccounts={managedAccounts}
                            isLoading={isLoading}
                            onUserSwitch={onUserSwitch}
                            mainUser={mainUser}
                            setShowMenu={hideMenu}
                        />
                    </Bubble>
                </div>
            </ErrorBoundary>

            <style jsx>
                {`
                  .click_overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 100;
                    display: ${showMenu ? 'block' : 'none'};
                  }
                  .managed_accounts {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    position: relative;
                    
                    &.dark {
                      :global(.cc__bubble__overlay) {
                        background-color: #2A2A2A;
                        
                        :global(.logout-wrapper) {
                          background-color: #4B4B4B;
                        }
                      }
                    }

                    :global(.cc__bubble) {
                      right: -3px;
                      width: max-content;
                      cursor: auto;
                      max-width: 70vw;
                      transform: none;
                      z-index: 101;

                      :global(.cc__bubble__overlay) {
                        border: none;
                      }
                    }
                  }

                  .user-name {
                    margin: 0 10px 0 0;
                    color: white;
                  }

                  .user-image {
                    border-radius: 50%;
                    width: 35px;
                    height: 35px;
                    border: 0.05px solid rgba(0, 0, 0, 0.1);
                  }
                `}
            </style>
        </>
    );
};

ManagedAccounts.displayName = 'ManagedAccounts';

ManagedAccounts.propTypes = propTypes;

ManagedAccounts.defaultProps = {
    user: null,
};

export default ManagedAccounts;
