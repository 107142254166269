import { getCookie } from '../../../utils/helper';
import { DISALLOWED_COOKIE_PREFIXES } from './constants';

const getTappCookie = (req: { value: { name: string }}, res: { answer: (cookie: string) => void}): void => {
    const { value } = req;

    if (DISALLOWED_COOKIE_PREFIXES.some((prefix: string) => value?.name.startsWith(prefix))) return;

    res.answer(getCookie(value?.name));
};

export default getTappCookie;
