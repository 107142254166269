import React from 'react';
import Messages from '../tapp/tapp-content/sites-tapp/messages/Messages';

import OverlayElement from '../tapp/tapp-content/sites-tapp/overlay-element/OverlayElement';

const Infocenter = () => (
    <>
        <Messages infocenter/>
        <OverlayElement infocenter/>
    </>
);

export default Infocenter;
