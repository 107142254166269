export const language = {
    value: 'en',
};

const getLanguage = () => {
    if (typeof window === 'undefined') {
        return language.value;
    }
    return window.chaynsDeLanguage;
};

export default getLanguage;
