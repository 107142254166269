import React, { useMemo, memo, useEffect, useRef } from 'react';
import Accordion from '@chayns-components/Accordion';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import PageSelection from '../page-selection/PageSelection';
import PagePlaceholder from '../page-selection/page-placeholder/PagePlaceholder';
import Page from '../page-selection/page/Page';
import { loadSearchPages } from '../../../../../../api/search/sites';
import { getCoords } from '../../../../../../utils/geoLocationHelper';
import { getSitesState } from '../../../../../../redux-modules/sites/selector';
import { getEnvState } from '../../../../../../redux-modules/env/selector';
import { getIsSearchingPagesState, getSearchPagesState, getSearchStringState } from '../../../../../../redux-modules/search/selector';
import { setIsSearchingPages, setPagesActivePage, setPagesPageResult } from '../../../../../../redux-modules/search/actions';
import { getAppState } from '../../../../../../redux-modules/app/selector';
import { Page as PageProps } from '../../../../../../types/Page';

type PageProps = {
    elementWidth: string,
};

const PageWrapper: React.FC<PageProps> = ({
    elementWidth,
}) => {
    const dispatch = useDispatch();
    const app = useSelector(getAppState);
    const {
        geoPositionAvailable,
        geoCoordinates,
    } = app;

    const allSites = useSelector(getSitesState);
    const env = useSelector(getEnvState);
    const {
        isMyChaynsApp,
    } = env;

    const searchString = useSelector(getSearchStringState);
    const isSearchingPages = useSelector(getIsSearchingPagesState);

    const pagesSearch = useSelector(getSearchPagesState);
    const {
        values,
        activePage,
        total,
    } = pagesSearch;

    const abortControllerRef = useRef(null);

    const elementsPerPage = Math.round(100 / parseFloat(elementWidth)) * 5;

    const pagesPlaceholder = useMemo(() => {
        const tempPlaceholder = [];
        for (let i = 0; i < elementsPerPage; i += 1) {
            tempPlaceholder.push(<PagePlaceholder key={i}/>);
        }
        return tempPlaceholder;
    }, [elementsPerPage]);

    const shownValues: Array<PageProps | { type: string, key: number }> = useMemo(() => {
        const startIndex = (activePage - 1) * elementsPerPage;
        const endIndex = startIndex + elementsPerPage;
        const sliceValues: Array<PageProps | { type: string, key: number }> = values?.slice(startIndex, endIndex) || [];
        const shownAmount = total >= elementsPerPage ? elementsPerPage : total;
        if (sliceValues.length < shownAmount && total - endIndex > 0) {
            const missingAmount = shownAmount - sliceValues.length;
            for (let i = 0; i < missingAmount; i += 1) {
                sliceValues.push({
                    type: 'placeholder',
                    key: i,
                });
            }
        }
        return sliceValues;
    }, [activePage, elementsPerPage, total, values]);

    const loadPages = async () => {
        dispatch(setIsSearchingPages(true));
        let latitude = 52.06692;
        let longitude = 7.01652;
        let geoPivot = 300000;

        const isComplexSearch = searchString.indexOf('UND') > -1 && searchString.indexOf(':') > -1;

        try {
            if (geoPositionAvailable === true || geoCoordinates || isComplexSearch) {
                if (geoCoordinates && geoCoordinates.longitude && geoCoordinates.latitude && geoCoordinates.geoPivot) {
                    latitude = geoCoordinates.latitude;
                    longitude = geoCoordinates.longitude;
                    geoPivot = geoCoordinates.geoPivot;
                } else {
                    const geoPosition = chayns.env.isApp ? await chayns.getGeoLocation() : await getCoords();
                    if (geoPosition && geoPosition.longitude && geoPosition.latitude) {
                        latitude = geoPosition.latitude;
                        longitude = geoPosition.longitude;
                        geoPivot = 30;
                    }
                }
            }
        } catch (e) {
            // ignore
        }

        abortControllerRef.current = new AbortController();
        const startIndex = (activePage - 1) * elementsPerPage;
        loadSearchPages(searchString, elementsPerPage, startIndex, {
            longitude,
            latitude,
            geoPivot,
        }, activePage, abortControllerRef.current.signal).then((value) => {
            if (value.status === 200 && value.result) {
                dispatch(setPagesPageResult({
                    value: {
                        values: value.result.values,
                        startIndex,
                    },
                }));
            } else {
                dispatch(setIsSearchingPages(false));
            }
        }).catch(() => {
            dispatch(setIsSearchingPages(false));
        });
    };

    useEffect(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        if (!values) {
            return;
        }

        if (activePage > 1 && (activePage - 1) * elementsPerPage > total) {
            dispatch(setPagesActivePage(activePage - 1));
            return;
        }

        const startIndex = (activePage - 1) * elementsPerPage;
        const endIndex = startIndex + elementsPerPage;
        let areValuesLoaded = true;
        for (let index = startIndex; index < endIndex && index < total; index += 1) {
            const element = values[index];
            if (!element) {
                areValuesLoaded = false;
            }
        }
        if (!areValuesLoaded) {
            loadPages();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePage, elementsPerPage]);

    if (!total && !isSearchingPages) {
        return null;
    }

    const width = elementWidth === '50%' ? 'calc(50% - 20px)' : elementWidth;

    return (
        <>
            <Accordion
                head="Pages"
                defaultOpened
            >
                <div
                    className="page_wrapper"
                    style={{
                        gridTemplateColumns: `repeat(auto-fill, minmax(${width}, 1fr))`,
                    }}
                >
                    {(shownValues.length === 0 || isSearchingPages) && pagesPlaceholder}
                    {shownValues.length > 0 && !isSearchingPages && shownValues.map((page, index) => {
                        if (page.type === 'placeholder' || page.type === 'empty') {
                            return <PagePlaceholder key={page.key}/>;
                        }
                        const isKnown = !!allSites?.values?.find((currentSite) => currentSite.siteId === page.siteId);
                        const openDialog = !isMyChaynsApp && allSites?.values?.findIndex((s) => s.isHidden && s.siteId === page.siteId) > -1;
                        return (
                            <Page
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${page.siteId}-${page.tappId}_${index}`}
                                id={page.siteId}
                                name={page.siteName}
                                siteId={page.siteId}
                                tappId={page.tappId}
                                openDialog={openDialog}
                                domain={page.domain}
                                tappName={page.tappName}
                                highlights={page.highlights}
                                title={page?.metadata?.title}
                                description={page?.metadata?.description}
                                isKnown={isKnown}
                            />
                        );
                    })}
                </div>

                {total && (
                    <PageSelection
                        activePage={activePage}
                        totalPages={Math.ceil(total / elementsPerPage)}
                        onPageClick={(pageIndex) => {
                            dispatch(setPagesActivePage(pageIndex));
                        }}
                    />
                )}
            </Accordion>
            <style jsx>
                {`
                    .page_wrapper {
                        margin: auto;
                        flex-wrap: wrap;
                        display: grid;
                        margin: auto;
                        margin: 15px 0 30px 0;
                        gap: 20px;
                    }
                `}
            </style>
        </>
    );
};

PageWrapper.propTypes = {
    elementWidth: PropTypes.string.isRequired,
};

PageWrapper.displayName = 'PageWrapper';

export default memo(PageWrapper);
