import { mapToApiTapp } from '../../../utils/chayns-info';
import logger from "../../../constants/logger";

const convertTapps = (data) => {
    const apiTapps = [];
    data.AppInfo.Tapps?.forEach((t) => {
        apiTapps.push(mapToApiTapp(null, t));
    });

    if (apiTapps.length === 0 && window.chaynsInfo?.Tapps?.length > 0) {
        window.chaynsInfo.Tapps.forEach((t) => {
            apiTapps.push({ Id: t.id, customUrl: t.customUrl });
        });
    }

    return apiTapps;
};

let i = 0;

export default async function getGlobalData(req, res) {
    if (window.chaynsInfo && !res.answer) {
        const data = window.chaynsInfo.getGlobalData();

        if (!data.converted && data.AppInfo) {
            data.AppInfo.Tapps = convertTapps(data);
            data.converted = true;
        }

        const params = {
            callback: req.callback || req.value.callback,
            addJSONParam: req.addJSONParam || (req.value && req.value.addJSONParam) || {},
            retVal: data || {},
        };

        const frame = res[0];
        if (frame && typeof frame.postMessage === 'function') {
            frame.postMessage(`${res[1] || 'chayns.customTab.'}jsoncall:${JSON.stringify(params)}`, '*');
        }
    } else if (window.chaynsInfo) {
        const data = window.chaynsInfo.getGlobalData();

        if (!data.converted) {
            data.AppInfo.Tapps = convertTapps(data);
            data.converted = true;
        }
        res.answer(data);
    } else if (chayns.env.isApp) {
        window[`getGlobalData_${++i}`] = (value) => {
            res.answer(value?.retVal);
        };

        chayns.invokeCall({
            action: 18,
            value: { callback: `window.getGlobalData_${i}` },
        });
    } else {
        logger().info({
            message: 'Could not handle get global data call.',
            section: 'getGlobalData',
        });
    }
}
