import React from 'react';

import SelectedNewsHeader from './selected-news-header/SelectedNewsHeader';
import SelectedNewsContent from './selected-news-content/SelectedNewsContent';

const SelectedNews: React.FC = () => (
    <>
        <div
            className="selected-news"
        >
            <SelectedNewsHeader/>
            <SelectedNewsContent/>
        </div>
        <style jsx>
            {`
                .selected-news {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                }
            `}
        </style>
    </>
);

export default SelectedNews;
