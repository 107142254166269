export default function defer(): { promise: Promise<unknown> } {
    const result = {} as {
        promise: Promise<unknown>,
        resolve: (value: unknown) => void,
        reject: (value: unknown) => void,
    };

    result.promise = new Promise((resolve, reject) => {
        result.resolve = resolve;
        result.reject = reject;
    });

    return result;
}
