import Button from '@chayns-components/Button';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getAppState } from '../../redux-modules/app/selector';

const ReloadView : FC = () => {
    const { colorMode } = useSelector(getAppState);

    return (
        <>
            <div className="content__wrapper" id="wrapper">
                <div className="content">
                    <div className="text">Die Ansicht konnte nicht geladen werden. Bitte versuche es erneut.</div>
                    <Button
                        onClick={() => {
                            if (typeof location !== 'undefined') {
                                location.reload();
                            }
                        }}
                        style={{
                            marginTop: '20px',
                        }}
                    >
                        Neu laden
                    </Button>
                </div>
            </div>

            <style jsx global>
                {`

                    .content__wrapper {
                        display: block;
                        position: fixed;
                    }

                    .text {
                        font-size: 20px;
                        color: ${colorMode === 'dark' ? '#CCCCCC' : '#555555'};
                        font-weight: 300;
                        width: 80vw;
                        max-width: 500px;
                    }

                    span.text {
                        font-size: 15px;
                    }

                    .content { text-align: center; }

                    .content__wrapper {
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                `}
            </style>
        </>
    );
};

export default ReloadView;
